import {html} from "lit-html";
import {APP_STRINGS} from "../../js/core/AppStrings";

export const TemplateSearchComponent = el =>html`
    <div class="search-controls ${el.loading?'loading':''}">
        <div>
            <div class="input-wrapper">
                <input id="${searchInputID()}" placeholder="${APP_STRINGS.getWPMLString('GamesSearchView-InputPlaceholderText-Search')}" type="text" ?disabled=${el.loading} @keyup=${el._onInputKeyUp} @change=${el._onInputChange}>
                <img src="${CDN_ASSETS_URL}/cancel.svg" class="reset-input-button" @click=${el._onResetButtonClick}>
                ${el.searchResult !=null && el.searchResult.length==0 ? html`
                <div class="no-results">
                    <h5>${APP_STRINGS.getWPMLString('GamesSearchView-NoSerchResults-Title')}</h5>
                    <p>${APP_STRINGS.getWPMLString('GamesSearchView-NoSerchResults-Text')}</p>
                </div>
                `:''}
            </div>
        </div>
        
        <button class="search-close-button"><img src="${CDN_ASSETS_URL}/close.svg" @click=${el._onCloseButtonClick}></button>
    </div>
    
        <div class="content-wrap">
    
    ${el.showTemplates && el.recentSearches.length>0 ? html`
        <div class="search-templates">
            <h4>${APP_STRINGS.getWPMLString('GamesSearchView-RecentSearchTermsSection-Title')}</h4>
            <div>
                ${el.recentSearches.map(item=>html`
                <div class="search-preset" @click=${el._onSearchPresetClick}>${item}</div>
                `)}
            </div>
        </div>
    `:''}
    ${el.loading ? html`<preloader-element class="primary"></preloader-element>`:''}
    
    ${el.searchResult !=null && el.searchResult.length>0 ? renderGames(el,el.searchResult) : ''}
    
        </div>
    
`;

function renderGames(el,games){
    return html`
<div class="search-results">
    <section class="games-section grid-view">
        <div class="game-items-container">
            ${games.map(game=>html`
            <a href="${game.gameLink}" @click=${el._onGameLinkClick}>
                <div class="game-item">                            
                    <div class="game-image">
                        <img src="${game.mobileThumbnailUrl}">
                    </div>
                    <div class="game-title">${game.shortTitle}</div>
                </div>
            </a>
            `)}
        </div>
    </section>
</div>
    `;
}

function searchInputID(){
    let domain = window.location.host.split('.')
    return "search-input-"+domain[1]
}
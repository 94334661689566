import {html} from 'lit-html';
import {Sidebar} from "@/js/ui/Sidebar";
import {unsafeHTML} from "lit-html/directives/unsafe-html";

export const TemplateSidebar = (el) => html`
    <ul class="collapsed-items">
      ${getCollapsedItems(el)}
    </ul>
    <ul class="expanded-items">
      ${getExpandedItems(el)}
    </ul>
`;

export const TemplateGameSidebar= el=>html`
    ${el.mode==Sidebar.MODE.LOGGED_IN ? html`
        <ul class="horizontal-menu">
            <li class="logo-item" @click=${el.onSidebarItemClick} data-action="home"><img src="${CDN_ASSETS_URL}/icon.svg"></li>
            <li class="info-button"  @click=${el.onSidebarItemClick} data-action="openGameInfoSlideOver"><img src="${CDN_ASSETS_URL}/info-2.svg"></li>
            <li class="button-primary" @click=${el.onSidebarItemClick} data-action="bank"><img src="${CDN_ASSETS_URL}/wallet.svg"></li>
            <li class="close-btn" @click=${el.onSidebarItemClick} data-action="home"><img src="${CDN_ASSETS_URL}/close.svg"></li>
        </ul>
    ` :''}
    <ul class="collapsed-items">
      ${getCollapsedItemsForGameSidebar(el)}
    </ul>
    <ul class="expanded-items">
      ${getExpandedItemsForGameSidebar(el)}
    </ul>
`;


function getCollapsedItems(el){
    let items;
    switch (el.mode) {
        case Sidebar.MODE.LOGGED_IN:
                items=[
                    COLLAPSED_ITEMS.logo(el),
                    COLLAPSED_ITEMS.expand(el),
                    COLLAPSED_ITEMS.user(el),
                    COLLAPSED_ITEMS.deposit(el),
                    COLLAPSED_ITEMS.menu(el),
                    //COLLAPSED_ITEMS.shop(el),
                    COLLAPSED_ITEMS.promotions(el),
                    COLLAPSED_ITEMS.vip(el),
                    COLLAPSED_ITEMS.search(el),
                    //COLLAPSED_ITEMS.races(el),
                    //COLLAPSED_ITEMS.notifications(el),
                    //COLLAPSED_ITEMS.empty(el)
                ];
                items=items.concat(getCollapsedGameCategoryLinks(el))
                items.push(COLLAPSED_ITEMS.logout(el))
                items.push(COLLAPSED_ITEMS.help(el))

            break;
        case Sidebar.MODE.LOGGED_OUT:
            items=[
                COLLAPSED_ITEMS.logo(el),
                COLLAPSED_ITEMS.expand(el),
                COLLAPSED_ITEMS.signUp(el),
                COLLAPSED_ITEMS.login(el),
                COLLAPSED_ITEMS.promotions(el),
                COLLAPSED_ITEMS.vip(el),
                COLLAPSED_ITEMS.search(el),
                //COLLAPSED_ITEMS.races(el),
                //COLLAPSED_ITEMS.notifications(el),
                //COLLAPSED_ITEMS.empty(el)
            ];
            items=items.concat(getCollapsedGameCategoryLinks(el))
            items.push(COLLAPSED_ITEMS.help(el))
            break;
    }
    return items;
}

function getExpandedItems(el){
    let items;

    switch (el.mode) {
        case Sidebar.MODE.LOGGED_IN:
            items=[
               EXPANDED_ITEMS.headingWithMenuItem(el),
               EXPANDED_ITEMS.products(el),
               EXPANDED_ITEMS.dashboard(el),
               EXPANDED_ITEMS.deposit(el),
               EXPANDED_ITEMS.menu(el),
               EXPANDED_ITEMS.promotions(el),
               EXPANDED_ITEMS.vip(el),
               EXPANDED_ITEMS.search(el),
                //EXPANDED_ITEMS.races(el),
               //EXPANDED_ITEMS.notifications(el),
               //EXPANDED_ITEMS.shop(el),

            ];
            items=items.concat(getExpandedGameCategoryLinks(el))
            items.push(EXPANDED_ITEMS.logout(el))
            items.push(EXPANDED_ITEMS.help(el))
            break;
        case Sidebar.MODE.LOGGED_OUT:
            items=[
                EXPANDED_ITEMS.heading(el),
                EXPANDED_ITEMS.products(el),
                EXPANDED_ITEMS.signUp(el),
                EXPANDED_ITEMS.login(el),
                EXPANDED_ITEMS.promotions(el),
                EXPANDED_ITEMS.vip(el),
                EXPANDED_ITEMS.search(el),
                //EXPANDED_ITEMS.races(el),
                //EXPANDED_ITEMS.notifications(el),
            ];
            items=items.concat(getExpandedGameCategoryLinks(el))
            //hide logout white logged out mode
            // items.push(EXPANDED_ITEMS.logout(el))
            items.push(EXPANDED_ITEMS.help(el))
            break;
    }
    return items;
}

function getCollapsedItemsForGameSidebar(el){
    let items;
    switch (el.mode) {
        case Sidebar.MODE.LOGGED_IN:
            items=[
                COLLAPSED_ITEMS.logo(el),
                COLLAPSED_ITEMS.expand(el),
                COLLAPSED_ITEMS.user(el),
                COLLAPSED_ITEMS.deposit(el),
                COLLAPSED_ITEMS.menu(el),
                //COLLAPSED_ITEMS.shop(el),
                COLLAPSED_ITEMS.promotions(el),
                COLLAPSED_ITEMS.vip(el),
                COLLAPSED_ITEMS.search(el),
                //COLLAPSED_ITEMS.races(el),
                //COLLAPSED_ITEMS.notifications(el),
                //COLLAPSED_ITEMS.empty(el)

            ];
            items=items.concat(getCollapsedGameCategoryLinks(el))
            items.push(COLLAPSED_ITEMS.logout(el))
            items.push(COLLAPSED_ITEMS.help(el))
            break;
        case Sidebar.MODE.LOGGED_OUT:
            items=[
                COLLAPSED_ITEMS.logo(el),
                COLLAPSED_ITEMS.expand(el),
                COLLAPSED_ITEMS.signUp(el),
                COLLAPSED_ITEMS.login(el),
                COLLAPSED_ITEMS.promotions(el),
                COLLAPSED_ITEMS.vip(el),
                COLLAPSED_ITEMS.search(el),

            ];
            items=items.concat(getCollapsedGameCategoryLinks(el))
            items.push(COLLAPSED_ITEMS.help(el))
            break;
    }
    return items;
}

function getExpandedItemsForGameSidebar(el){
    let items;
    switch (el.mode) {
        case Sidebar.MODE.LOGGED_IN:
            items=[
                EXPANDED_ITEMS.headingWithMenuItem(el),
                EXPANDED_ITEMS.gameInfo(el),
                EXPANDED_ITEMS.dashboard(el),
                EXPANDED_ITEMS.deposit(el),
                EXPANDED_ITEMS.menu(el),
                EXPANDED_ITEMS.promotions(el),
                EXPANDED_ITEMS.vip(el),
                EXPANDED_ITEMS.search(el),
            ];
            items=items.concat(getExpandedGameCategoryLinks(el))
            items.push(EXPANDED_ITEMS.logout(el))
            items.push(EXPANDED_ITEMS.help(el))


            break;
        case Sidebar.MODE.LOGGED_OUT:
            items=[
                EXPANDED_ITEMS.heading(el),
                EXPANDED_ITEMS.signUp(el),
                EXPANDED_ITEMS.login(el),
                EXPANDED_ITEMS.gameInfoContent(el),
                EXPANDED_ITEMS.promotions(el),
                EXPANDED_ITEMS.vip(el),
                EXPANDED_ITEMS.search(el),
            ];
            items=items.concat(getExpandedGameCategoryLinks(el))
            items.push(EXPANDED_ITEMS.logout(el))
            items.push(EXPANDED_ITEMS.help(el))
            break;
    }
    return items;
}



const COLLAPSED_ITEMS={
    logo:           el=>{return html`<li @click=${el.onSidebarItemClick} data-action="home"><img src="${CDN_ASSETS_URL}/icon.svg"></li>`},
    menu:           el=>{return html`<li @click=${el.onSidebarItemClick} data-action="menu"><img src="${CDN_ASSETS_URL}/user.svg"></li>`},
    expand:         el=>{return html`<li @click=${el.onSidebarItemClick} data-action="expand"><img src="${CDN_ASSETS_URL}/expand.svg"></li>`},
    signUp:         el=>{return html`<li @click=${el.onSidebarItemClick} data-action="signUp" class="button-primary"><img src="${CDN_ASSETS_URL}/sign-up.svg"></li>`},
    login:          el=>{return html`<li @click=${el.onSidebarItemClick} data-action="login"><img src="${CDN_ASSETS_URL}/login.svg"></li>`;},
    user:           el=>{return html`<li @click=${el.onSidebarItemClick} data-action="user"><img src="${CDN_ASSETS_URL}/profile.svg"></div></li>`},
    deposit:        el=>{return html`<li @click=${el.onSidebarItemClick} data-action="bank" class="button-primary"><img src="${CDN_ASSETS_URL}/wallet.svg"></div></li>`},
    shop:           el=>{return html`<li @click=${el.onSidebarItemClick} data-action="shop"><img src="${CDN_ASSETS_URL}/shop.svg"></div></li>`},
    search:         el=>{return html`<li @click=${el.onSidebarItemClick} data-action="search"><img src="${CDN_ASSETS_URL}/search.svg"></li>`},
    promotions:     el=>{return html`<li @click=${el.onSidebarItemClick} data-action="promotions"><img src="${CDN_ASSETS_URL}/promotion.svg"></li>`},
    vip:            el=>{return html`<li class="mb" @click=${el.onSidebarItemClick} data-action="vip"><img src="${CDN_ASSETS_URL}/vip.svg"></li>`},
    races:          el=>{return html`<li @click=${el.onSidebarItemClick} data-action="races"><img src="${CDN_ASSETS_URL}/races.svg"></li>`},
    notifications:  el=>{return html`<li @click=${el.onSidebarItemClick} data-action="notifications"><img src="${CDN_ASSETS_URL}/notifications.svg"><div class="notification-number">5</div></li>`},
    logout:         el=>{return html`<li class="mt" @click=${el.onSidebarItemClick} data-action="logout"><img src="${CDN_ASSETS_URL}/log-out.svg"></li>`},
    help:           el=>html`<li @click=${el.onSidebarItemClick} data-action="help"><img src="${CDN_ASSETS_URL}/info.svg"></li>`,
    empty:          el=>{return html`<li></li>`}
};

const EXPANDED_ITEMS={
    heading:el=>html`<li class="heading-item"><img class="logo logo-expanded" @click=${el.onSidebarItemClick} data-action="home" src="${window.logoImgLink}"><img @click=${el.onSidebarItemClick} data-action="collapse" src="${CDN_ASSETS_URL}/collapse.svg"></li>`,
    headingWithMenuItem:el=>html`<li class="heading-item"><img class="logo" @click=${el.onSidebarItemClick} data-action="home" src="${window.logoImgLink}"><img @click=${el.onSidebarItemClick} data-action="collapse" src="${CDN_ASSETS_URL}/collapse.svg"></li>`,
    products:getProductsItem,
    dashboard:getSidebarDashboard,
    signUp:             el=>html`<li @click=${el.onSidebarItemClick} data-action="signUp" class="button-primary"><img src="${CDN_ASSETS_URL}/sign-up.svg"><span>${APP_STRINGS.getWPMLString('SidebarMenu-SidebarItem-SignUp')}${el.additionalData && el.additionalData.addNowToSignUp?` ${APP_STRINGS.getWPMLString('SidebarMenu-SidebarItem-SignUp-Suffix')}`:''}</span></li>`,
    login:              el=>html`<li @click=${el.onSidebarItemClick} data-action="login" class="button-secondary"><img src="${CDN_ASSETS_URL}/login.svg"><span>${APP_STRINGS.getWPMLString('SidebarMenu-SidebarItem-Login')}</span></li>`,
    deposit:            el=>html`<li @click=${el.onSidebarItemClick} data-action="bank" class="button-primary"><img src="${CDN_ASSETS_URL}/wallet.svg"><span>${APP_STRINGS.getWPMLString('SidebarMenu-SidebarItem-Deposit')}${el.additionalData && el.additionalData.addNowToDeposits?` ${APP_STRINGS.getWPMLString('SidebarMenu-SidebarItem-Deposit-Suffix')}`:''}</span></li>`,
    search:             el=>html`<li @click=${el.onSidebarItemClick} data-action="search"><img src="${CDN_ASSETS_URL}/search.svg"><span>${APP_STRINGS.getWPMLString('SidebarMenu-SidebarItem-GameSearch')}</span></li>`,
    promotions:         el=>html`<li @click=${el.onSidebarItemClick} data-action="promotions"><img src="${CDN_ASSETS_URL}/promotion.svg"><span>${APP_STRINGS.getWPMLString('SidebarMenu-SidebarItem-Promotions')}</span></li>`,
    vip:                el=>html`<li class="mb" @click=${el.onSidebarItemClick} data-action="vip"><img src="${CDN_ASSETS_URL}/vip.svg"><span>${APP_STRINGS.getWPMLString('SidebarMenu-SidebarItem-VIPProgram')}</span></li>`,
    races:              el=>html`<li @click=${el.onSidebarItemClick} data-action="races"><img src="${CDN_ASSETS_URL}/races.svg"><span>${APP_STRINGS.getWPMLString('SidebarMenu-SidebarItem-Races')}</span></li>`,
    notifications:      el=>html`<li @click=${el.onSidebarItemClick} data-action="notifications"><div class="notification-number">5</div><img src="${CDN_ASSETS_URL}/notifications.svg"><span>${APP_STRINGS.getWPMLString('SidebarMenu-SidebarItem-Notifications')}</span></li>`,
    shop:               el=>html`<li @click=${el.onSidebarItemClick} data-action="shop"><img src="${CDN_ASSETS_URL}/shop.svg"><span>${APP_STRINGS.getWPMLString('SidebarMenu-SidebarItem-Shop')}</span></li>`,
    logout:             el=>html`<li class="mt" @click=${el.onSidebarItemClick} data-action="logout"><img src="${CDN_ASSETS_URL}/log-out.svg"><span>${APP_STRINGS.getWPMLString('SidebarMenu-SidebarItem-Logout')}</span></li>`,
    help:               el=>html`<li @click=${el.onSidebarItemClick} data-action="help"><img src="${CDN_ASSETS_URL}/info.svg"><span>${APP_STRINGS.getWPMLString('SidebarMenu-SidebarItem-Help')}</span></li>`,
    menu:               el=>html`<li @click=${el.onSidebarItemClick} data-action="menu"><img src="${CDN_ASSETS_URL}/user.svg"><span>${APP_STRINGS.getWPMLString('SidebarMenu-SidebarItem-Account')}</span></li>`,
    gameInfoContent:    el=>html`<li class="game-info-content">${el.gameInfo.gameInfoHTML ? unsafeHTML(el.gameInfo.gameInfoHTML) : ''}</li>`,
    gameInfo:getGameInfoItem,
    empty:              el=>{return html`<li></li>`}
};

function getExpandedGameCategoryLinks(el) {
    let items=[];
    el.gameCategoryLinkItems.forEach(item=>{
        if(el.mode==Sidebar.MODE.LOGGED_OUT && item.requiresLoggedIn){
            //skip this item
            return;
        }
        items.push(html`<a data-action="link" href="${item.url}"><img src="${getLinkItemIcon(item)}"><span>${item.title}</span></a>`)
    })
    return items;
}


function getCollapsedGameCategoryLinks(el) {
    let items=[];
    el.gameCategoryLinkItems.forEach(item=>{
        if(el.mode==Sidebar.MODE.LOGGED_OUT && item.requiresLoggedIn){
            //skip this item
            return;
        }
        items.push(html`<a data-action="link" href="${item.url}"><img src="${getLinkItemIcon(item)}"></a>`);

    })
    return items;
}

function getLinkItemIcon(item){
    let icon;

    if((typeof item.icon) != 'undefined' && item.icon != ''){
        return item.icon;
    }

    switch (item.type) {
        case "game-category-link_slots":
            icon="pokies.svg";
            break;
        case "game-category-link_livecasino":
            icon="live-casino.svg";
            break;
        case "game-category-link_blackjack":
            icon="blackjack.svg";
            break;
        case "game-category-link_roulette":
            icon="roulette.svg";
            break;
        case "game-category-link_speciality-games":
            icon="speciality.svg";
            break;
        case "game-category-link_table-games":
            icon="table-games.svg";
            break;
        case "game-category-link_video-poker":
            icon="video-poker.svg";
            break;
        case "game-category-link_all-casino-games":
            icon="casino.svg";
            break;
        case "game-category-link_favourite":
            icon="favourite-filled.svg";
            break;
        case "game-category-link_new-games":
            icon="icon-new.svg";
            break;
        case "game-category-link_halloween-games":
            icon="ghost.png";
            break;
        case "game-category-link_christmas-games":
            icon="christmas-games.png";
            break;
        default:
            icon="right-2.svg";
    }
    return CDN_ASSETS_URL + '/' + icon;
}



function getProductsItem(el){
    return html``;
    //tentatively hide by Adrian
    return html`
        <li class="product-nav">
              <nav>
                  <button class="active">Casino</button>
                  <button>Live</button>
                  <button>Lottery</button>
              </nav>
          </li>
    `;
}

function getSidebarDashboard(el){
    return html`
        <li class="sidebar-dashboard">
            <div class="item" @click=${el.onSidebarItemClick} data-action="user">
                <div class="profile-info">
                    <div class="avatar">
                        ${(el.userData.VIPImage) ? html`<img src="${el.userData.VIPImage}"/>` : ``}
                    </div>
                    <p class="Paragraph-Tiny"><img class="loyalty-icon" src="${CDN_ASSETS_URL}/loyalty.svg"><span>${el.userData ? el.userData.VIPLevelName : ''}</span></p>
                </div>
                <div></div>
                <span class="Paragraph-Small-Bold">${APP_STRINGS.getWPMLString('SidebarMenu-UserDashboard-ProfileSection-Title')}</span>
            </div>
            <div class="item" @click=${el.onSidebarItemClick} data-action="bank">
                <div class="wallet-info">
                    <span class="Paragraph-Tiny">${APP_STRINGS.getWPMLString('SidebarMenu-UserDashboard-BankSection-FieldLabel-TotalBalance')}</span>
                    <div class="current-balance-box"><span class="Paragraph-Tiny-Bold current-balance-display">${el.userData ? el.userData.CurrentBalanceDisaply : ''}</span></div>
                    <div class="toggle-deposit-amount">
                        <img src="${CDN_ASSETS_URL}/open.svg">
                    </div>
                </div>
                <div></div>
                <span class="Paragraph-Small-Bold">${APP_STRINGS.getWPMLString('SidebarMenu-UserDashboard-BankSection-Title')}</span>
            </div>
            <!--div class="item">
                <div class="shop-info">
                    <span class="Paragraph-Tiny-Bold">1,000,000</span>
                    <span class="Paragraph-Tiny">Points</span>
                </div>
                <div class="ui-progress-bar"></div>
                <span class="Paragraph-Small-Bold">SHOP</span>
            </div-->

          </li>
    `;
}

function getGameInfoItem(el){
    return html`
    <li class="game-info">
        <h4 class="game-title">${el.gameInfo.gameTitle}</h4>
        <img class="favourite-btn" @click=${el.onSidebarItemClick} data-action="toggleFavouriteGame" src="${el.gameInfo && el.gameInfo.isFavourite==1 ? `${CDN_ASSETS_URL}/favourite-filled.svg` : `${CDN_ASSETS_URL}/favourite.svg`}">
        <button id="game-info-btn" @click=${el.onSidebarItemClick} data-action="openGameInfoSlideOver">${APP_STRINGS.getWPMLString('SidebarMenu-ButtonLabel-GameInfo')}</button>
    </li>
    `;
}

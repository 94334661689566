import {SideViewBaseClass} from "../SideViewBaseClass";
import {render} from "lit-html";
import {FormHelpers} from "../../util/FormHelpers";
import {TemplateMainMenuSideView} from "../../../templates/template-items/side-view/TemplateMainMenuSideView";
import {SideViewUser} from "./SideViewUser";
import {SideViewBank} from "./SideViewBank";
import {SideViewMenu} from "../SideViewMenu";

export class SideViewMainMenu extends SideViewBaseClass{
    constructor() {
        super("menu",SideViewBaseClass.TYPE.NORMAL,null,SideViewMainMenu.ALIGNMENT);
    }

    renderViewContent() {
        console.log("rendering login");
        render(TemplateMainMenuSideView(this),this.element,{eventContext:this});
    }

    onMenuItemClick(e){
        let action=e.currentTarget.dataset.action;
        switch (action) {
            case "deposit":
                new SideViewBank(SideViewMenu.ITEM_TYPES.DEPOSIT);
                break;
            case "withdraw":
                new SideViewBank(SideViewMenu.ITEM_TYPES.WITHDRAW);
                break;
            case "history":
                new SideViewBank(SideViewMenu.ITEM_TYPES.HISTORY);
                break;
            case "bonus":
                new SideViewBank(SideViewMenu.ITEM_TYPES.BONUSES);
                break;
            case "profile":
                new SideViewUser(SideViewMenu.ITEM_TYPES.PROFILE);
                break;
            case "preferences":
                new SideViewUser(SideViewMenu.ITEM_TYPES.PREFERENCES);
                break;
            case "verification":
                new SideViewUser(SideViewMenu.ITEM_TYPES.VERIFICATION);
                break;
            case "responsibleGambling":
                new SideViewUser(SideViewMenu.ITEM_TYPES.RESPONSIBLE_GAMBLING);
                break;
            case "logout":
                USER.logout();
                break;
            default:
                console.log("unknown action");
        }
    }
}

SideViewMainMenu.ALIGNMENT=SideViewBaseClass.ALIGNMENT.LEFT;
import {EventEmitter} from 'events';
import {Helpers} from "../util/Helpers";
import {CAPP_API} from "./CAPPApi";
import {SetLivechatDepositErrorCustomVariable} from "./LivechatController";
import {USER} from "./User";

class Bank extends EventEmitter{
    constructor() {
        super();

        this.depositMethods=null;
        this.depositBonuses=null;
        this._rawDepositData={
            depositMethods:null,
            paymentAccounts:null
        };

        this.depositSettings={
            selectedMethod:null,
            selectedBonus:null,
            depositAmount:0,
            bonusAmount:0,
            payableAmount:0,
            bonusCodeUsed:false,
            presetAmounts:[]
        };
        this._initializeExternalDepositCallback();


        this.withdrawalSettings={
            selectedMethod:null,
            withdrawalAmount:0,
            formData:null,
            presetAmounts:[]
        };
        this.withdrawalMethods=null;
        this.withdrawalsBankInfo=null;
        this.pendingWithdrawals=null;
        this.existingBankAccounts=null;



    }

    _initializeExternalDepositCallback(){
        //CAPP.bank.paymentResultCallback(true);
        if(!window.CAPP){
            window.CAPP={};
        }
        if(!window.CAPP.bank){
            window.CAPP.bank={};
        }
        window.CAPP.bank.paymentResultCallback=(success, additionalData)=>{
            USER.updatePlayerBalance();
            this.emit("ExternalPaymentResult",{
                success:success,
                additionalData:additionalData
            })
            if(!success){
                try {
                    SetLivechatDepositErrorCustomVariable(this.depositSettings.selectedMethod.PaymentMethod);
                }catch(e){
                    //dont do anything if it fails
                }
            }
        }

    }

    invalidateExistingDepositData(){
        window.sessionStorage.removeItem('depositData');
        window.sessionStorage.removeItem('withdrawalData');
    }

    getDepositData(){
        this.depositSettings.selectedMethod=null;
        this.depositSettings.selectedBonus=null;
        this.depositSettings.depositAmount=0;
        this.depositSettings.bonusAmount=0;
        this.depositSettings.payableAmount=0;
        this.depositSettings.bonusCodeUsed=false;
        this.depositSettings.presetAmounts=[];

        //todo consider storing into session storage
        if(this.existingBankAccounts==null){
            this._loadExistingBankAccounts();
        }


        return new Promise((resolve, reject) =>{
                let sdd=window.sessionStorage.getItem('depositData');
                if(sdd){
                    this._rawDepositData=JSON.parse(sdd);
                    resolve();
                }else{
                    reject();
                }
            })
            .then(()=>{},()=>{return this._loadDepositData()})
            .then(error=>{
                if(error){return error};
                this._processDepositMethods();
                this._processDepositBonuses();
                this._processDepositSettings();

                let depositData = {
                    depositMethods:this.depositMethods,
                    depositBonuses:this.depositBonuses,
                    paymentAccounts:this._rawDepositData.paymentAccounts
                };

                return depositData;
            });
    }

    getPIQDepositData(){
        const requests = [
            CAPP_API.getPIQDepositBonuses(USER.sessionKey, USER.data.LangaugeISO),
            this._loadDepositData()
        ];

        return Promise.all(requests).then(response => {
            console.log(response);
            if(response.error){
                return response;
            }

            this._processDepositSettings();
            const data = response[0];

            this.depositSettings.selectedMethod={PaymentMethod:'PIQ',CappPaymentDisplayName:'Deposit'}
            this.depositBonuses=[];
            if(data.TransactionGetApplicableDepositBonusesResult &&
                data.TransactionGetApplicableDepositBonusesResult.BonusRules &&
                data.TransactionGetApplicableDepositBonusesResult.BonusRules.BonusRuleDeposit) {
                let bonuses=data.TransactionGetApplicableDepositBonusesResult.BonusRules.BonusRuleDeposit;
                bonuses.forEach(bonus=>{
                    this._parseBonusData(bonus);
                    this.depositBonuses.push(bonus);
                });
            }

            const depositData = {
                depositBonuses:this.depositBonuses
            };
            return depositData;
        });
    }

    getWithdrawalData(){
        this.withdrawalSettings={
            selectedMethod:null,
            withdrawalAmount:0,
            formData:null,
            presetAmounts:[]
        };
        if(this.existingBankAccounts==null){
            this._loadExistingBankAccounts();
        }

        return new Promise((resolve, reject)=>{
            let swd=window.sessionStorage.getItem('withdrawalData');
            if(swd){
                this._rawWithdrawalData=JSON.parse(swd);
                resolve();
            }else{
                reject();
            }
        })
            .then(()=>{},()=>{return this._loadWithdrawalData()})
            .then(error=>{
                if(error){return error;}
                this._processWithdrawalMethods();
                this._processWithdrawalSettings();
                if(this._rawWithdrawalData.BanksInfo){
                    this.withdrawalsBankInfo=this._rawWithdrawalData.BanksInfo;
                }
                return {
                    withdrawalMethods:this.withdrawalMethods,
                    banks:this.withdrawalsBankInfo
                };

            })
    }

    getPIQWithdrawalData(){
        return this._loadWithdrawalData()
            .then(() => {
                this._processWithdrawalMethods();
                this._processWithdrawalSettings();
                this.withdrawalSettings.selectedMethod = {
                    PaymentMethod: 'PIQ',
                    PaymentMethodName: 'PIQ',
                    CappPaymentDisplayName: 'Withdrawal'
                };
                this.withdrawalSettings.withdrawalAmount = 0;
                this.withdrawalSettings.formData = null;
                
                return {
                    withdrawalMethods: [{ PaymentMethod: 'PIQ', CappPaymentDisplayName: 'Withdrawal' }],
                    banks: []
                };
            });
    }

    getPendingWithdrawals(){

        return new Promise((resolve)=>{
            CAPP_API.getPendingWthdrawals(USER.sessionKey)
                .then(data=>{
                    if(data.error){
                        resolve(data);
                    }else{
                        this._processPendingWithdrawals(data);
                        resolve(this.pendingWithdrawals);
                    }
                })
        })
    }


    setDepositAmount(value){
        this.depositSettings.depositAmount=value>0?value:0;
        this._updateDepositValues();
        this._updateDepositMethodLimitStatus();
    }

    useBonusCode(bonusCode){
        return CAPP_API.getBonusUsingBonusCode(USER.sessionKey,this.depositMethods[0].PlayerUpperLimit,this.depositMethods[0],null,bonusCode)
            .then(data=>{
                if(data.error){
                    return data;
                }else{
                    if(data.ApplicableBonus==""){
                        return {bonusLoaded:false}
                    }else{
                        //parse bonus data
                        let bonus=data.ApplicableBonus;
                        this._parseBonusData(bonus);
                        this.depositBonuses.push(bonus);
                        this.depositSettings.bonusCodeUsed=true;
                        return {bonusLoaded:true}
                    }
                }
            })
            .catch(e=>{
                return {error:{
                        errorMessage:"Error processing bonus data",
                        errorTitle:"Error"
                    }}
            });
    }

    selectBonus(bonusId) {
        if(bonusId<0){
            this.depositSettings.selectedBonus=null;
        }else{
            for(let i=0; i<this.depositBonuses.length; i++){
                if(this.depositBonuses[i].BaseRule.BonusRuleID==bonusId){
                    this.depositSettings.selectedBonus=this.depositBonuses[i];
                    break;
                }
            }
        }
        this._updateDepositValues();
    }

    getSelectedPaymentProcessType(){
        if(this.depositSettings.selectedMethod){
            return this.depositSettings.selectedMethod.CappPaymentProcessType;
        }
    }

    setDepositMethod(depositMethodName){//CappPaymentName
        if(depositMethodName==null){
            this.depositSettings.selectedMethod=null;
            return;
        }
        for(let i=0; i<this.depositMethods.length; i++){
            if(this.depositMethods[i].PaymentMethod==depositMethodName){
                this.depositSettings.selectedMethod=this.depositMethods[i];
                break;
            }
        }
    }



    getDepositIframeLink(additionalFormData){
        let p=null;
        
        if(this.depositSettings.selectedMethod.CappPaymentCashier =='PIQ'){
            p=this._getPIQIframeLink('deposit',this.depositSettings.depositAmount,this.depositSettings.selectedBonus ? this.depositSettings.selectedBonus.BaseRule.BonusRuleID : -1,this.depositSettings.selectedMethod.PaymentMethod);

        }else{

            switch (this.depositSettings.selectedMethod.PaymentMethod) {
                case"ACEMP_Visa":
                case"ACEMP_Mastercard":
                case"ACOTP_Visa":
                case"ACOTP_Mastercard":
                case"ACASP_Mastercard":
                case"ACASP_JCB":
                case"ACMB_Ewallet":
                case"Interac_DirectBanking":
                    p=this._getIframeLinkUsingPaymentDataSubmit();
                    break;
                case "PIQ":
                    p=this._getPIQIframeLink('deposit',this.depositSettings.depositAmount,this.depositSettings.selectedBonus ? this.depositSettings.selectedBonus.BaseRule.BonusRuleID : -1,this.depositSettings.selectedMethod.PaymentMethod);
                    break;
                default:
                    p=this._getIframeLinkUsingPaymentExternalLink(additionalFormData);
            }

        }
       

        //data here containf either error or iframe link
        return p.then(data=>{
            if(data.error){
                return data;
            }else {
                this.invalidateExistingDepositData();
                return {iframeURL:data};
            }
        });

    }

    setDepositAmountToRange(depositMethodName) {
        let method;
        for(let i=0; i<this.depositMethods.length; i++){
            if(this.depositMethods[i].PaymentMethod==depositMethodName){
                method=this.depositMethods[i];
                break;
            }
        }

        if(method.limitStatus==BANK.DEPOSIT_METHOD_LIMIT_STATUS.TOO_LOW){
            this.setDepositAmount(method.PlayerLowerLimit/100);
        }else if(method.limitStatus==BANK.DEPOSIT_METHOD_LIMIT_STATUS.TOO_HIGH){
            this.setDepositAmount(method.PlayerUpperLimit/100);
        }
        this.setDepositMethod(depositMethodName);
    }

    setWithdrawalMethod(methodName){
        for(let i=0; i<this.withdrawalMethods.length; i++){
            if(this.withdrawalMethods[i].PaymentMethodName==methodName){
                this.withdrawalSettings.selectedMethod=this.withdrawalMethods[i];
                break;
            }
        }
    }

    setWithdrawalAmount(amount){
        this.withdrawalSettings.withdrawalAmount=amount;
        this._updateWithdrawalMethodLimitStatus();
    }

    setWithdrawalAmountToRange(methodName) {
        let method;
        for(let i=0; i<this.withdrawalMethods.length; i++){
            if(this.withdrawalMethods[i].PaymentMethodName==methodName){
                method=this.withdrawalMethods[i];
                break;
            }
        }

        if(method.limitStatus==BANK.DEPOSIT_METHOD_LIMIT_STATUS.TOO_LOW){
            this.setWithdrawalAmount(method.PlayerLowerLimit/100);
        }else if(method.limitStatus==BANK.DEPOSIT_METHOD_LIMIT_STATUS.TOO_HIGH){
            this.setWithdrawalAmount(method.PlayerUpperLimit/100);
        }
        this.setWithdrawalMethod(methodName);
    }

    setIsMaxPendingWithdrawals(methodName, isMaxPendingWithdrawals){
        for(let i=0; i<this.withdrawalMethods.length; i++){
            if(this.withdrawalMethods[i].PaymentMethodName==methodName){
                this.withdrawalMethods[i].isMaxPendingWithdrawals = isMaxPendingWithdrawals;
                break;
            }
        }
    }

    withdraw(){
        return new Promise(resolve=>{
            let amount=this.withdrawalSettings.withdrawalAmount*100;

            if(amount>USER.data.CurrentRealBalance){
                resolve({
                    error:{
                        errorTitle:"Insufficient funds",
                        errorMessage:"Not enough funds to make this withdrawal"
                    }
                });
            }else{
                this._processWithdrawalRequest()
                    .then(data=>{
                        if(data.success){
                            //Clear withdrawalData for getting the latest withdrawalData from API(e.g. isMaxPendingWithdrawals)
                            window.sessionStorage.removeItem('withdrawalData');
                            this.existingBankAccounts=null;
                            USER.updatePlayerBalance();
                        }
                        resolve(data);
                    })
            }
        })
    }

    withdrawPIQ(){
        return new Promise(resolve=>{
            let amount=this.withdrawalSettings.withdrawalAmount*100;

            if(amount>USER.data.CurrentRealBalance){
                resolve({
                    error:{
                        errorTitle:"Insufficient funds",
                        errorMessage:"Not enough funds to make this withdrawal"
                    }
                });
            }else{
                this._getPIQIframeLink('withdrawal',this.withdrawalSettings.withdrawalAmount,-1,this.withdrawalSettings.selectedMethod.PaymentMethodName).then(data=>{
                    resolve(data);
                })
            }
        })
    }

    getBankNameBySwitCode(swift){
        for(let i=0; i<this.withdrawalsBankInfo.length; i++){
            if(this.withdrawalsBankInfo[i].swift_code==swift){
                return this.withdrawalsBankInfo[i].bank_name;
            }
        }
    }

    cancelPendngWithdrawal(transactionKey, methodName){
        let item=Helpers.findItemInArray(this.pendingWithdrawals,"UniqueTransactionID", transactionKey);
        item.cancelingWithdrawal=true;
        return CAPP_API.cancelPendingWithdrawal(USER.sessionKey,transactionKey)
            .then(data=>{
                if(data.error){
                    item.cancelingWithdrawal=false;
                    return data;
                }else{
                    Helpers.removeItemFromArray(this.pendingWithdrawals,item);
                    USER.updatePlayerBalance();
                    this.setIsMaxPendingWithdrawals(methodName, false);
                    //Clear withdrawalData for getting the latest withdrawalData from API(e.g. isMaxPendingWithdrawals)
                    window.sessionStorage.removeItem('withdrawalData');
                    return {success:true}
                }
            })
    }

    _processPendingWithdrawals(pendingWithdrawals){
        this.pendingWithdrawals=[];
        let cur=USER.data.CurrencySymbol;
        if(pendingWithdrawals.Transactions){
            pendingWithdrawals.Transactions.forEach(item=>{
                item.AmountDisplay= `${cur}${Helpers.formatCurrencyCents(item.Amount,true)}`;
                item.dateDisplay = `${Helpers.formatDate(item.Timestamp)} ${Helpers.formatTime(item.Timestamp)}`;
                this.pendingWithdrawals.push(item);
            })
        }
    }

    _loadDepositData(){
        return new Promise((resolve,reject)=>{
            let requests=[
                CAPP_API.getAllDepositMethods(USER.sessionKey,USER.data.CountryISO,USER.data.CurrencyISO, USER.data.LangaugeISO),
                CAPP_API.getAllPaymentAccounts(USER.sessionKey, USER.data.LangaugeISO)
            ];

            Promise.all(requests).then((data)=>{
                //todo check for isDisallowed
                //process deposit methods

                if(data[0].error){
                    //todo error loading deposit data
                    reject(data[0]);
                }

                if(data[1].error){
                    //todo handle error loading payment accounts
                    reject(data[1]);
                }

                this._rawDepositData.depositMethods=data[0];
                this._rawDepositData.paymentAccounts=data[1];

                window.sessionStorage.setItem('depositData', JSON.stringify(this._rawDepositData));
                resolve();
            })
        })

    }

    _loadWithdrawalData(){

        return CAPP_API.getAllWithdrawalmethods(USER.sessionKey, USER.data.CountryISO,USER.data.CurrencyISO, USER.data.LangaugeISO)
            .then(data=>{
                if(data.error){

                }else{
                    this._rawWithdrawalData=data;
                    window.sessionStorage.setItem('withdrawalData', JSON.stringify(this._rawWithdrawalData));
                }
            })
    }

    _loadExistingBankAccounts(){
        return CAPP_API.getExistingBankAccounts(USER.sessionKey)
            .then(data=>{
                if(data.error){

                }else{
                    if(data.result && data.result.accounts){
                        this.existingBankAccounts=data.result.accounts;
                    }
                }
            })
    }

    _processDepositMethods() {
        this.depositMethods=[];
        if(this._rawDepositData.depositMethods.PlayerDepositMethods && this._rawDepositData.depositMethods.PlayerDepositMethods.TransactionPlayerDepositMethod){
            this._rawDepositData.depositMethods.PlayerDepositMethods.TransactionPlayerDepositMethod.forEach(method=>{
                let cur=USER.data.CurrencySymbol;
                //todo see if we still need this
                method.CappPaymentFeeDisplay= `${cur}${Helpers.formatCurrencyCents(method.CappPaymentFeeDeposit,true)}`;

                method.PlayerUpperLimitDisplay=`${cur}${Helpers.formatCurrencyCents(method.PlayerUpperLimit, true)}`;
                method.PlayerUpperLimit = parseFloat(method.PlayerUpperLimit);

                method.PlayerLowerLimitDisplay=`${cur}${Helpers.formatCurrencyCents(method.PlayerLowerLimit, true)}`;
                method.PlayerLowerLimit = parseFloat(method.PlayerLowerLimit);

                if(method.CappPaymentText){
                    method.CappPaymentText = atob(method.CappPaymentText);
                }
                if(method.CappPaymentMethodTooltip){
                    method.CappPaymentMethodTooltip = atob(method.CappPaymentMethodTooltip);
                }

                //require additional deposit forms for specific deposit methods
                switch (method.PaymentMethod) {
                    case "CryptoPay":
                    case 'CryptoPay_BTC':
                    case 'CryptoPay_ETH':
                    case 'CryptoPay_LTC':
                    case 'CryptoPay_BCH':
                    case 'CryptoPay_USDT':
                    case 'CryptoPay_USDC':
                    case 'CryptoPay_DAI':
                        if(method.IsBtcAddressExist!=true){
                            method.requresAdditionalInput=true;
                        }
                        break;
                }


                this.depositMethods.push(method);
            })
        }
    }

    _processWithdrawalMethods(){
        let cur=USER.data.CurrencySymbol;
        this.withdrawalMethods=[];
        let withdrawOptions=[];
        if(this._rawWithdrawalData.TransactionWithdrawOptions && this._rawWithdrawalData.TransactionWithdrawOptions.TransactionWithdrawOption){
            withdrawOptions=this._rawWithdrawalData.TransactionWithdrawOptions.TransactionWithdrawOption;
        }

        if(this._rawWithdrawalData.WithdrawalMethods && this._rawWithdrawalData.WithdrawalMethods.TransactionWithdrawPaymentMethod){
            this._rawWithdrawalData.WithdrawalMethods.TransactionWithdrawPaymentMethod.forEach(method=>{

                for(var i=0; i<withdrawOptions.length; i++){
                    if(method.PaymentMethodName==withdrawOptions[i].PaymentMethodName){
                        method.isMaxPendingWithdrawals=withdrawOptions[i].CappIsMaxPendingWithdrawals;
                        method.displayName=withdrawOptions[i].CappPaymentDisplayName;
                        method.cashier=withdrawOptions[i].CappPaymentCashier;
                        method.image=withdrawOptions[i].CappPaymentImage;
                        method.PlayerLowerLimit=parseFloat(withdrawOptions[i].PlayerLowerLimit);
                        method.PlayerUpperLimit=parseFloat(withdrawOptions[i].CappBit8OverridePaymentMaxWithdrawal) || parseFloat(withdrawOptions[i].PlayerUpperLimit);
		                method.PaymentAccountID=withdrawOptions[i].PaymentAccountID;
                        method.PlayerWithdrawalLimitText=withdrawOptions[i].PlayerWithdrawalLimitText;
		                this.withdrawalMethods.push(method);
                        break;
                    }
                }
            })
        }
    }

    _processWithdrawalSettings() {
        if(this._rawWithdrawalData.CappWithdrawalPresetAmounts){
            this.withdrawalSettings.presetAmounts = this._rawWithdrawalData.CappWithdrawalPresetAmounts;
        }
    }

    _processDepositBonuses() {
        this.depositBonuses=[];
        if(this._rawDepositData.depositMethods.BonusRules && this._rawDepositData.depositMethods.BonusRules.BonusRuleDeposit){
            this._rawDepositData.depositMethods.BonusRules.BonusRuleDeposit.forEach(bonus=>{
                this._parseBonusData(bonus);
                this.depositBonuses.push(bonus);
            });
        }
    }

    _processDepositSettings() {
        if(this._rawDepositData.depositMethods.CappDepositPresetAmounts){
            this.depositSettings.presetAmounts = this._rawDepositData.depositMethods.CappDepositPresetAmounts;
        }
    }

    _parseBonusData(bonus){
        let cur=USER.data.CurrencySymbol;

        if(bonus.bonusTitleTag==undefined || bonus.bonusTitleTag==""){
            bonus.bonusTitleTag="Untitled";
        }

        bonus.Percentage=parseFloat(bonus.Percentage);
        bonus.PercentageDisplay=parseInt(bonus.Percentage*100)+"%";


        bonus.BonusPercentageMaxAmountDisplay=`${cur}${Helpers.formatCurrencyCents(bonus.BonusPercentageMaxAmount, true)}`;
        bonus.BonusPercentageMaxAmount=parseFloat(bonus.BonusPercentageMaxAmount);

        bonus.BonusFixedAmountDisplay=`${cur}${Helpers.formatCurrencyCents(bonus.BonusFixedAmount, true)}`;
        bonus.BonusFixedAmount=parseFloat(bonus.BonusFixedAmount);

        bonus.BonusMinDepositAmountDisplay=`${cur}${Helpers.formatCurrencyCents(bonus.BonusMinDepositAmount, true)}`;
        bonus.BonusMinDepositAmount=parseFloat(bonus.BonusMinDepositAmount);


        bonus.cappMaxBetDisplay=`${cur}${Helpers.formatCurrencyCents(bonus.cappMaxBet, true)}`;

        if (bonus.BaseRule && bonus.BaseRule.WagerRequirementMultiplier){
            bonus.WagerRequirementMultiplierDisplay = `${parseInt(bonus.BaseRule.WagerRequirementMultiplier)}x`;
        }

        try{
            bonus.ExpiryDateDisplay = Helpers.formatDate(bonus.BaseRule.ExpiryDate);
        }catch(e){
            bonus.ExpiryDateDisplay="";
        }

        try {
            bonus.promoText=atob(bonus.promoText);
        }catch (e) {
            //do we do anything here?
        }

        //todo see if we still need this
        if(bonus.BaseRule && bonus.BaseRule.GameWagerWeights && bonus.BaseRule.GameWagerWeights.GameWagerWeight){
            bonus.seeGamesList=true;
        }
    }

    _updateDepositValues(){
        if(this.depositSettings.selectedBonus!=null){

            //check if deposit amount is heigh enough for this bonus
            if(this.depositSettings.selectedBonus.BonusMinDepositAmount/100>this.depositSettings.depositAmount){
                this.depositSettings.bonusAmount=0;
            }else{

                if(this.depositSettings.selectedBonus.IsPercentage){
                    this.depositSettings.bonusAmount=this.depositSettings.selectedBonus.Percentage*this.depositSettings.depositAmount;
                    var m=this.depositSettings.selectedBonus.BonusPercentageMaxAmount/100;
                    if(this.depositSettings.bonusAmount>m){
                        this.depositSettings.bonusAmount=m;
                    }
                }else{
                    this.depositSettings.bonusAmount=this.depositSettings.selectedBonus.BonusFixedAmount/100;
                }

            }


        }else{
            this.depositSettings.bonusAmount=0;
        }

        this.depositSettings.payableAmount=this.depositSettings.depositAmount+this.depositSettings.bonusAmount;

        this.depositBonuses.forEach(item=>{
            if(this.depositSettings.depositAmount==0){
                item.unavaliable=false;
            }else{
                item.unavaliable=item.BonusMinDepositAmount/100>this.depositSettings.depositAmount;
            }

        })
    }

    _updateDepositMethodLimitStatus(){
        if(this.depositSettings.selectedMethod && this.depositSettings.selectedMethod.PaymentMethod=='PIQ'){return;}
        let dVal=this.depositSettings.depositAmount*100;
        this.depositMethods.forEach(item=>{
            if(dVal>item.PlayerUpperLimit){
                item.limitStatus=BANK.DEPOSIT_METHOD_LIMIT_STATUS.TOO_HIGH;
            }else if(dVal<item.PlayerLowerLimit){
                item.limitStatus=BANK.DEPOSIT_METHOD_LIMIT_STATUS.TOO_LOW;
            }else{
                item.limitStatus=BANK.DEPOSIT_METHOD_LIMIT_STATUS.IN_RANGE;
            }
        })

        if(this.depositSettings.selectedMethod && this.depositSettings.selectedMethod.limitStatus!= BANK.DEPOSIT_METHOD_LIMIT_STATUS.IN_RANGE){
            this.depositSettings.selectedMethod=null;
        }
    }

    _getIframeLinkUsingPaymentDataSubmit(){
        return CAPP_API.submitPaymentData(
            USER.sessionKey,
            this.depositSettings.depositAmount*100,
            this.depositSettings.selectedMethod.PaymentMethod,
            this.depositSettings.selectedBonus ? this.depositSettings.selectedBonus.BaseRule.BonusRuleID : -1,
            'web'
        ).then(data=>{
                console.log("_getIframeLinkUsingPaymentDataSubmit",data);
            if(data.error){
                return data;
            }else {
                return data.ReturnURL;
            }
        })
    }

    _getPIQIframeLink(type,amount,bonusId,paymentMethod){
        //get piq link
        return CAPP_API.launchPIQ(
            USER.sessionKey,
            amount,
            bonusId,
            type,
            paymentMethod
        ).then(data=>{
            console.log("_getPIQIframeLink",data);
            if(data.error){
                return data;
            }else {
                return data.data.ReturnURL;
            }
        })
    }

    _getIframeLinkUsingPaymentExternalLink(additionalFormData){
        return CAPP_API.getPaymentExternalLink(
            USER.sessionKey,
            this.depositSettings.selectedMethod.PaymentMethod,
			this.depositSettings.selectedMethod.CappPaymentProcessType,
            this.depositSettings.depositAmount*100,
            this.depositSettings.selectedBonus ? this.depositSettings.selectedBonus.BaseRule.BonusRuleID : 0,
            this.depositSettings.selectedBonus ? this.depositSettings.selectedBonus.BaseRule.BonusCode : 0,
            USER.data.EmailAddress,
            USER.data.FirstName,
            USER.data.LastName,
            USER.data.CurrencyISO,
            USER.data.CountryISO,
            USER.data.LanguageISO,
            additionalFormData
        );
    }

    _updateWithdrawalMethodLimitStatus() {
        if(this.withdrawalSettings.selectedMethod && this.withdrawalSettings.selectedMethod.PaymentMethod=='PIQ'){return;}
        let dVal=this.withdrawalSettings.withdrawalAmount*100;
        this.withdrawalMethods.forEach(item=>{
            if(dVal>item.PlayerUpperLimit){
                item.limitStatus=BANK.DEPOSIT_METHOD_LIMIT_STATUS.TOO_HIGH;
            }else if(dVal<item.PlayerLowerLimit){
                item.limitStatus=BANK.DEPOSIT_METHOD_LIMIT_STATUS.TOO_LOW;
            }else{
                item.limitStatus=BANK.DEPOSIT_METHOD_LIMIT_STATUS.IN_RANGE;
            }
        })
    }

    _processWithdrawalRequest(){

        let amount=this.withdrawalSettings.withdrawalAmount*100;
        let f;
     
        switch (this.withdrawalSettings.selectedMethod.PaymentMethodName) {
            case"IBV4_DirectBanking":
                f=this.withdrawalSettings.formData;
                let bankSwiftCodeBankName = f.bankSwiftCode;
                bankSwiftCodeBankName = bankSwiftCodeBankName.split("|");

                if((typeof bankSwiftCodeBankName[1]) !== 'undefined' && bankSwiftCodeBankName[1].length > 0){
                    let swiftCode = bankSwiftCodeBankName[0];
                    let bankName = bankSwiftCodeBankName[1];

                    return CAPP_API.ibV4Withdrawal(USER.sessionKey,USER.data.EmailAddress,amount,
                        f.bankCountry,
                        f.bankAccountHolderName,
                        bankName,
                        swiftCode,
                        f.bankBSBCode,
                        f.bankAccountNumber,
                        USER.data.CurrencyISO,
                        USER.data.CountryISO,
                        USER.data.LanguageISO
                    )
                    .then(data=>{
                        if(data.IsSuccess==true){
                            return {success:true}
                        }else{
                            return {success:false}
                        }
                    });
                }
                else{
                    return Promise.resolve("error").then(data=>{
                        return {error:{errorTitle:APP_STRINGS.ERROR,errorMessage:"Unsupported payment type"}};
                    });
                }
                break;
            case "BankTransfer":
                f=this.withdrawalSettings.formData;
                f.username=USER.data.EmailAddress;
                f.lang=USER.data.LanguageISO;
                f.currency=USER.data.CurrencyISO;
                console.log("Do bank transfer ",f);
                return CAPP_API.bankTransferWithdrawal(USER.sessionKey,amount,f)
                    .then(data=>{
                        if(data.IsSuccess==true){
                            return {success:true}
                        }else{
                            return {success:false}
                        }
                    });

                break;
            case "CryptoPay":
            case 'CryptoPay_BTC':
            case 'CryptoPay_ETH':
            case 'CryptoPay_LTC':
            case 'CryptoPay_BCH':
            case 'CryptoPay_USDT':
            case 'CryptoPay_USDC':
            case 'CryptoPay_DAI':
                f=this.withdrawalSettings.formData;
                f.paymentAccountID=this.withdrawalSettings.selectedMethod.PaymentAccountID;
                f.lang=USER.data.LanguageISO;
                console.log("Do Bitcoin withdraw ",f);
                return CAPP_API.cryptopayWithdrawal(USER.sessionKey,amount,f)
                    .then(data=>{
                        if(data.IsSuccess==true){
                            return {success:true}
                        }else{
                            return {success:false}
                        }
                    });

                break;
            case "Interac_Withdrawal":
                f=this.withdrawalSettings.formData;
                f.paymentAccountID=this.withdrawalSettings.selectedMethod.PaymentAccountID;
                f.lang=USER.data.LanguageISO;
                console.log("Do "+this.withdrawalSettings.selectedMethod.PaymentMethodName+" ",f);
                return CAPP_API.submitWithdrawData(USER.sessionKey,amount,f)
                    .then(data=>{
                        if(data.IsSuccess==true){
                            return {success:true}
                        }else{
                            return {success:false}
                        }
                    });
                    
                break;
            default:
                return Promise.resolve("error").then(data=>{
                    return {error:{errorTitle:APP_STRINGS.ERROR,errorMessage:"Unsupported payment type"}};
                });
        }
        
    }

    getExistingAccountById(key) {
        let accountsList=this.existingBankAccounts[this.withdrawalSettings.selectedMethod.PaymentMethodName];
        return Helpers.findItemInArray(accountsList,"custom_attribute_key", key);
    }
}



export const BANK=new Bank();


BANK.PAYMENT_PROCESS_TYPES={
    STANDARD_IFRAME:"iframe_std",
    EXTERNAL_WINDOW:"external_window"
};

BANK.DEPOSIT_METHOD_LIMIT_STATUS={
    TOO_HIGH:1,
    TOO_LOW:-1,
    IN_RANGE:0
};
import {LazyImageLoader} from "./LazyImageLoader";

export const InitCarouselController=()=>{


  $('.game-items-container').slick({
      dots: false,
      infinite: true,
      variableWidth: true,
      //lazyLoad: 'ondemand',
      speed: 300,
      slidesToShow: 6,
      // slidesToShow: Math.floor((window.innerWidth-240)/160),
      slidesToScroll: 6,
      // slidesToScroll: Math.ceil(Math.floor((window.innerWidth-240)/160) / 2),
      prevArrow: '<a class="slick-arrow-prev"><img src="'+window.CDN_ASSETS_URL+'/left.svg"></a>',
      nextArrow: '<a class="slick-arrow-next"><img src="'+window.CDN_ASSETS_URL+'/right.svg"></a>',
      responsive: [
        {
          breakpoint: 920,
          settings: "unslick"
        },
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
  });

  $('.slick-arrow-next').click(function() {
    console.log('slick-arrow-next');
    LazyImageLoader.updateLazyImagesList();
  });

  let gameSections=document.querySelectorAll(".games-section");
  updateGameSectionsDisplay(gameSections);
  window.addEventListener('resize',()=>{
      updateGameSectionsDisplay(gameSections);
  })
}

function updateGameSectionsDisplay(gameSections){
    gameSections.forEach(item=>{
        if(checkGameSectionIsWiderThanContainer(item)){
            item.classList.remove("no-scroll");
        }else{
            item.classList.add("no-scroll");
        }
    })
}

function checkGameSectionIsWiderThanContainer(section){
    let container=section.querySelector(".game-items-container");
    if(container==null){return false;}
    let containerWidth=container.offsetWidth;
    let gameItemWidth=section.querySelector(".game-item").parentElement.offsetWidth;
    let numberOfGameItems=section.querySelectorAll(".game-item").length;
    return gameItemWidth*numberOfGameItems>containerWidth;
}
import {html} from 'lit-html';
import {APP_STRINGS} from "../../../../js/core/AppStrings";

export const TemplateChangePassword = (el) => html`
    <div class="view-heading">
        <div class="item-left">
            <img class="back-img" src="${CDN_ASSETS_URL}/left.svg" @click=${el.onSlideOverBackButtonClick} >
            <span class="title">${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangePasswordSubview-Title')}</span>
        </div>
    </div>
    <div class="content">
        <form class="profile-form" id="change-password-form" @submit="${el.onSubmitChangePassword}">
            <div class="form-element-2">
                <label class="mdc-text-field">
                    <div class="mdc-text-field__ripple"></div>
                    <input class="mdc-text-field__input" type="password" aria-labelledby="my-label-id" name="oldPassword">
                        <span class="mdc-floating-label" id="my-label-id">
                            ${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangePasswordSubview-Field-CurrentPassword-Label')}
                        </span>

                    <div class="mdc-line-ripple"></div>
                </label>

                <div class="mdc-text-field-helper-line">
                    <div class="mdc-text-field-helper-text" id="my-helper-id" aria-hidden="true">
                        ${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangePasswordSubview-Field-CurrentPassword-ValidationErrorText')}
                    </div>
                </div>
            </div>

            <div class="form-element-2">
                <label class="mdc-text-field">
                    <div class="mdc-text-field__ripple"></div>
                    <input class="mdc-text-field__input" type="password" aria-labelledby="my-label-id" name="newPassword">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangePasswordSubview-Field-NewPassword-Label')}
                    </span>

                    <div class="mdc-line-ripple"></div>
                </label>

                <div class="mdc-text-field-helper-line">
                    <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent" id="my-helper-id" aria-hidden="true">
                        ${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangePasswordSubview-Field-NewPassword-ValidationHelperText')}
                    </div>
                </div>
            </div>
            
            
            <div class="form-element-2">
                <label class="mdc-text-field">
                    <div class="mdc-text-field__ripple"></div>
                    <input class="mdc-text-field__input" type="password" aria-labelledby="my-label-id" name="confirmNewPassword">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangePasswordSubview-Field-PasswordRepeat-Label')}
                    </span>

                    <div class="mdc-line-ripple"></div>
                </label>

                <div class="mdc-text-field-helper-line">
                    <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent" id="my-helper-id" aria-hidden="true">
                        ${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangePasswordSubview-Field-PasswordRepeat-ValidationHelperText')}
                    </div>
                </div>
            </div>

            <div class="form-element-2">
                <button class="primary-button ${el.loading ? 'loading' : ''}" ><span>
                    ${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ChangePasswordSubview-ButtonLabel-ApplyChanges')}
                </span><preloader-element></preloader-element></button>
            </div>
        </form>
    </div>
`
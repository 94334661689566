import {html} from 'lit-html';
import {HorizontalMenu} from "../../js/ui/HorizontalMenu";

export const TemplateHorizontalMenu = el => html`
<div class="menu-top">
  <ul class="t-left">
      <li class="icon-item dh" @click=${el._onMenuItemClick} data-action="sidebar"><img src="${CDN_ASSETS_URL}/menu.svg"></li>
      <li class="logo-item" @click=${el._onMenuItemClick} data-action="home"><img src="${window.logoImgLink}"></li>
      <li class="menu-item mh" @click=${el._onMenuItemClick} data-action="promotions"><img src="${CDN_ASSETS_URL}/promotion.svg"><span>Promotions</span></li>
      <li class="menu-item mh" @click=${el._onMenuItemClick} data-action="vip"><img src="${CDN_ASSETS_URL}/vip.svg"><span>VIP Program</span></li>
      <li class="menu-item mh" @click=${el._onMenuItemClick} data-action="new-games"><img src="${CDN_ASSETS_URL}/icon-new.svg"><span>New Games</span></li>
      <li class="menu-item mh" @click=${el._onMenuItemClick} data-action="slots"><img src="${CDN_ASSETS_URL}/pokies.svg"><span>Online Pokies</span></li>
      <li class="menu-item mh" @click=${el._onMenuItemClick} data-action="all-casino-games"><img src="${CDN_ASSETS_URL}/casino.svg"><span>All Casino Games</span></li>
  </ul>
  <ul class="t-right">
    ${el.mode==HorizontalMenu.MODE.LOGGED_OUT ? html`
        <li @click=${el._onMenuItemClick} data-action="help" class="icon-item mh"><img src="${CDN_ASSETS_URL}/info.svg"></li>
        <li @click=${el._onMenuItemClick} data-action="search" class="icon-item"><img src="${CDN_ASSETS_URL}/search.svg"></li>
        <li @click=${el._onMenuItemClick} data-action="login" class="button-secondary"><img src="${CDN_ASSETS_URL}/login.svg"><span>Login</span></li>
        <li @click=${el._onMenuItemClick} data-action="signUp" class="button-primary"><img src="${CDN_ASSETS_URL}/sign-up.svg"><span>Sign up</span></li>    
    `:html`
        <li @click=${el._onMenuItemClick} data-action="help" class="icon-item mh"><img class="help-icon" src="${CDN_ASSETS_URL}/info.svg"></li>
        <li @click=${el._onMenuItemClick} data-action="search" class="icon-item mh"><img src="${CDN_ASSETS_URL}/search.svg"></li>
        <li @click=${el._onMenuItemClick} data-action="user" class="icon-item"><img src="${CDN_ASSETS_URL}/profile.svg"></div></li>
        <li @click=${el._onMenuItemClick} data-action="menu" class="icon-item"><img src="${CDN_ASSETS_URL}/user.svg"></li>
        <li @click=${el._onMenuItemClick} data-action="logout" class="icon-item mh"><img src="${CDN_ASSETS_URL}/log-out.svg"></li>
        <li @click=${el._onMenuItemClick} data-action="bank" class="button-primary deposit-btn"><img src="${CDN_ASSETS_URL}/wallet.svg"><span>Deposit</span></li>
    `}
      
  </ul>
</div>
`;
import {html} from 'lit-html';
import {APP_STRINGS} from "../../../js/core/AppStrings";
import { SideViewRegister } from '../../../js/ui/sideViews/SideViewRegister';

export const TemplateRegisterSideView = (el) => html`
    <div class="view-heading">
        <div class="item-left">
            <span class="title">${APP_STRINGS.getWPMLString('SideViewRegister-Title')}</span>
        </div>
        <div class="item-right">
            <img class="close-img" src="${CDN_ASSETS_URL}/close.svg" @click=${el.close}>
        </div>
    </div>
    <div class="scrolling-content">
        <div class="content">
        <preloader-element class="primary" style="width: 100%; height: 5em; display: ${el.loadingRegisterInfo ? 'flex' : 'none'};" ></preloader-element>
        
        <form id="register-form" class="${el.registrationDone?'reigistration-done':''}" @submit=${el.onFormSubmitRegister} autocomplete="off" style="display: ${el.loadingRegisterInfo ? 'none' : 'block'  };">
            <div class="register-progress-header">
                <!-- <div class="register-progress-step">Step <span class="progress-step">${el.step}</span> of <span>3</span>
                </div>
                <div class="ui-progress-bar register-progress-bar step-${el.step}"><div class="progress"></div></div> -->
                ${(el.step != 1) ? html`
                    <img src="${CDN_ASSETS_URL}/left-2.svg" class="register-back" @click=${el.onChangeStep}>
                ` : ''}
                <div class="register-progress-step ${(el.step > 1) ? 'complete' : ''} ${(el.step == 1) ? 'active' : ''}" >
                    <span>1</span>
                    <img src="${CDN_ASSETS_URL}/single.svg">
                </div>
                <img src="${CDN_ASSETS_URL}/right-2.svg">
                <img src="${CDN_ASSETS_URL}/right-2.svg">
                <div class="register-progress-step ${(el.step > 2) ? 'complete' : ''} ${(el.step == 2) ? 'active' : ''}" >
                    <span>2</span>
                    <img src="${CDN_ASSETS_URL}/single.svg">
                </div>
                <img src="${CDN_ASSETS_URL}/right-2.svg">
                <img src="${CDN_ASSETS_URL}/right-2.svg">
                <div class="register-progress-step ${(el.step == 3) ? 'active' : ''}">
                    <span>3</span>
                    <img src="${CDN_ASSETS_URL}/single.svg">
                </div>
            </div>
            <div class="step-1" style="display:${(el.step == '1') ? 'block;' : 'none;'}">
                <div class="form-element-2">
                    <label class="mdc-text-field mdc-email-address">
                        <div class="mdc-text-field__ripple"></div>
                        <input class="mdc-text-field__input email-address" type="text" aria-labelledby="my-label-id" name="emailAddress" spellcheck="false">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Email')}
                    </span>

                        <div class="mdc-line-ripple"></div>
                    </label>

                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent" id="my-helper-id" aria-hidden="true">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-Email')}
                        </div>
                    </div>
                </div>

                <div class="form-element-2">
                    <label class="mdc-text-field mdc-password">
                        <div class="mdc-text-field__ripple"></div>
                        <input class="mdc-text-field__input" autocomplete="new-password" type="password" aria-labelledby="my-label-id" name="password">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Password')}
                    </span>

                        <div class="mdc-line-ripple"></div>
                    </label>

                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent" id="my-helper-id" aria-hidden="true">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-Password')}
                        </div>
                    </div>
                </div>

                <div class="form-element-2">
                    <label class="mdc-text-field mdc-confirm-password">
                        <div class="mdc-text-field__ripple"></div>
                        <input class="mdc-text-field__input" type="password" autocomplete="new-password" aria-labelledby="my-label-id">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-PasswordRepeat')}
                    </span>

                        <div class="mdc-line-ripple"></div>
                    </label>

                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent" id="my-helper-id" aria-hidden="true">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-PasswordRepeat')}
                        </div>
                    </div>
                </div>

                <div class="form-element-2">
                    <div class="mdc-form-field">
                        <div class="mdc-checkbox mdc-tc-checkbox">
                            <input type="checkbox"
                                    class="mdc-checkbox__native-control"
                                    id="checkbox-1" name="t&c"/>

                            <div class="mdc-checkbox__background">
                                <img class="mdc-checkbox__checkmark" src="${CDN_ASSETS_URL}/single-pp.svg">
                                <!--<svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                                    <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                                </svg>-->
                                <div class="mdc-checkbox__mixedmark"></div>
                            </div>
                            <div class="mdc-checkbox__ripple"></div>
                        </div>
                        <div class="mdc-text-field-helper-line">
                            <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent" id="my-helper-id" aria-hidden="true">
                            </div>
                        </div>
                        <label class="terms-label" for="checkbox-1">${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-TermsAndConditions-Iagree-Text')}&nbsp;</label>
                        <a class="Text-Link-Small terms-link" href="${window.tncLink}" target="_blank">${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-TermsAndConditions-LinkLabel')}</a>
                    </div>
                </div>

                <div class="form-element-2">
                    <span><a class="Text-Link-Small sign-up-code-label" @click=${el.toggleSignUpCode}>${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-ButtonLabel-SignUpCode')} <img class="arrow-sign-up-code" src="${CDN_ASSETS_URL}/down.svg"></a> </span>
                </div>

                <div class="form-element-2 sign-up-code">
                    <label class="mdc-text-field sign-up-code-mdc">
                        <div class="mdc-text-field__ripple"></div>
                        <input class="mdc-text-field__input" type="password" aria-labelledby="my-label-id" name="couponCode">
                        <span class="mdc-floating-label" id="my-label-id">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-SignUpCode')}
                        </span>

                        <div class="mdc-line-ripple"></div>
                    </label>
                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent" id="my-helper-id" aria-hidden="true">
                        </div>
                    </div>
                </div>

            </div>

            <div class="step-2" style="display:${(el.step == '2') ? 'block;' : 'none;'}">
                <div class="form-element-2">
                    <label class="mdc-text-field mdc-first-name">
                        <div class="mdc-text-field__ripple"></div>
                        <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="firstName">
                        <span class="mdc-floating-label" id="my-label-id">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-FirstName')}
                        </span>

                        <div class="mdc-line-ripple"></div>
                    </label>
                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
                                id="my-helper-id" aria-hidden="true">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-FirstName')}
                        </div>
                    </div>

                </div>

                <div class="form-element-2">
                    <label class="mdc-text-field mdc-last-name">
                        <div class="mdc-text-field__ripple"></div>
                        <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="lastName">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-LastName')}
                    </span>

                        <div class="mdc-line-ripple"></div>
                    </label>

                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
                                id="my-helper-id" aria-hidden="true">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-LastName')}
                        </div>
                    </div>
                </div>

                <div class="form-element-2">
                    <div class="mdc-select mdc-gender mdc-select--required">
                        <div class="mdc-select__anchor">
                            <i class="mdc-select__dropdown-icon"></i>
                            <div id="demo-selected-text" class="mdc-select__selected-text" role="button"
                                    aria-haspopup="listbox"
                                    aria-labelledby="demo-label demo-selected-text"
                                    aria-controls="my-helper-text" aria-describedby="my-helper-text"></div>
                            <span id="demo-label"
                                    class="mdc-floating-label mdc-floating-label--float-above">
                                ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Gender')}
                            </span>

                            <div class="mdc-line-ripple"></div>
                        </div>

                        <div class="mdc-select__menu mdc-menu mdc-menu-surface" role="listbox">
                            <ul class="mdc-list">
                                <li class="mdc-list-item" data-value="M" role="option">
                                    ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Gender-SelectItem-Male')}
                                </li>
                                <li class="mdc-list-item" data-value="F" role="option">
                                    ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Gender-SelectItem-Female')}
                                </li>
                                ${renderGenderControls()}
                            </ul>
                        </div>
                    </div>
                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
                                id="my-helper-text" aria-hidden="true">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-Gender')}
                        </div>
                    </div>
                </div>

                <div class="form-element-2 dob-holder">
                    <label class="mdc-text-field mdc-dob ">
                        <div class="mdc-text-field__ripple"></div>
                        <input class="mdc-text-field__input flatpickr dob-input" type="text" aria-labelledby="my-label-id" name="dateOfBirth" >
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-DateOfBirth')}
                    </span>

                        <div class="mdc-line-ripple"></div>

                    </label>
                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
                                id="my-helper-id" aria-hidden="true">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-DateOfBirth')}
                        </div>
                    </div>
                    <input class="date-picker-overlay" type="date">

                </div>

                <div>
                    ${renderNationalityControls(el)}
                </div>

                <div class="form-element-2">
                    <div class="mdc-select mdc-currency mdc-select--required">
                        <div class="mdc-select__anchor">
                            <i class="mdc-select__dropdown-icon"></i>

                            <div id="demo-selected-text" class="mdc-select__selected-text" role="button"
                                    aria-haspopup="listbox"
                                    aria-labelledby="demo-label demo-selected-text"
                                    aria-controls="my-helper-text-currency" aria-describedby="my-helper-text-currency"></div>
                            <span id="demo-label"
                                    class="mdc-floating-label mdc-floating-label--float-above">
                                ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Currency')}
                            </span>

                            <div class="mdc-line-ripple"></div>
                        </div>

                        <div class="mdc-select__menu mdc-menu mdc-menu-surface" role="listbox">
                            <ul class="mdc-list">
                                ${getCurrencyList(el)}
                            </ul>
                        </div>
                    </div>
                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
                                id="my-helper-text-currency" aria-hidden="true">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-Currency')}
                        </div>
                    </div>
                </div>

            </div>

            <div class="step-3 ${el.loading ? 'disabled' : ''}" style="display:${(el.step == '3') ? 'block;' : 'none;'}'}">

                <div class="form-element-2">
                    <label class="mdc-text-field mdc-address1">
                        <div class="mdc-text-field__ripple"></div>
                        <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="address1">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Address1')}
                    </span>

                        <div class="mdc-line-ripple"></div>
                    </label>

                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
                                id="my-helper-id" aria-hidden="true">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-Address1')}
                        </div>
                    </div>
                </div>

                <div class="form-element-2">
                    <label class="mdc-text-field mdc-address2">
                        <div class="mdc-text-field__ripple"></div>
                        <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="address2">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Address2')}
                    </span>

                        <div class="mdc-line-ripple"></div>
                    </label>

                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
                                id="my-helper-id" aria-hidden="true">
                                ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-Address2')}
                        </div>
                    </div>
                </div>


                <div class="form-element-2">
                    <label class="mdc-text-field mdc-city">
                        <div class="mdc-text-field__ripple"></div>
                        <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="city">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-TownOrCity')}
                    </span>

                        <div class="mdc-line-ripple"></div>
                    </label>

                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
                                id="my-helper-id" aria-hidden="true">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-TownOrCity')}
                        </div>
                    </div>
                </div>

                <div class="form-element-2">
                    <div class="mdc-select mdc-country mdc-select--required">
                        <div class="mdc-select__anchor">
                            <i class="mdc-select__dropdown-icon"></i>

                            <div id="demo-selected-text" class="mdc-select__selected-text" role="button"
                                    aria-haspopup="listbox"
                                    aria-labelledby="demo-label demo-selected-text"
                                    aria-controls="my-helper-text-country" aria-describedby="my-helper-text-country"></div>
                            <span id="demo-label"
                                    class="mdc-floating-label mdc-floating-label--float-above">
                                    ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Country')}
                            </span>

                            <div class="mdc-line-ripple"></div>
                        </div>
                        <div class="mdc-select__menu mdc-menu mdc-menu-surface" role="listbox">
                            <ul class="mdc-list">
                                ${getCountryList(el)}
                            </ul>
                        </div>
                    </div>
                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
                                id="my-helper-text-country" aria-hidden="true">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-Country')}
                        </div>
                    </div>
                </div>

                <div class="form-element-2">
                    <label class="mdc-text-field mdc-postcode">
                        <div class="mdc-text-field__ripple"></div>
                        <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="postcode">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-PostCodeOrZIP')}
                    </span>

                        <div class="mdc-line-ripple"></div>
                    </label>

                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
                                id="my-helper-id" aria-hidden="true">
                                ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-PostCodeOrZIP')}
                        </div>
                    </div>
                </div>

                <div class="form-element-2">
                    <div class="inputs-group mobile-number-input-group">
                        <div class="mdc-select mdc-mobile-prefix mdc-select--required">
                            <div class="mdc-select__anchor" aria-required="true">
                                <i class="mdc-select__dropdown-icon"></i>

                                <div id="demo-selected-text" class="mdc-select__selected-text" role="button"
                                        aria-haspopup="listbox"
                                        aria-labelledby="demo-label demo-selected-text"></div>
                            <span id="demo-label"
                                    class="mdc-floating-label mdc-floating-label--float-above">
                                ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-PhoneNumberPrefix')}
                            </span>

                                <div class="mdc-line-ripple"></div>
                            </div>

                            <div class="mdc-select__menu mdc-menu mdc-menu-surface" role="listbox">
                                <ul class="mdc-list">
                                    ${getMobilePrefixList(el)}
                                </ul>
                            </div>
                        </div>

                        <label class="mdc-text-field mdc-mobile-number">
                            <div class="mdc-text-field__ripple"></div>
                                    <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="mobilePhone">
                            <span class="mdc-floating-label" id="my-label-id">
                                ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-PhoneNumber')}
                            </span>

                            <div class="mdc-line-ripple"></div>
                        </label>
                    </div>

                    <div class="mdc-text-field-helper-line mobile-number-helper-text">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
                                id="my-helper-id" aria-hidden="true">
                                ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-PhoneNumber')}
                        </div>
                    </div>

                </div>

                <div>
                    ${renderSecretQuestionControls(el)}
                </div>

                <div>
                    ${renderSecretAnswerControls(el)}
                </div>

            </div>
            
            <div class="register-buttons">
                <div id="g-recapture-register" class="g-recaptcha" data-sitekey="${SITE_KEY}" data-size="invisible" data-callback="onSubmit"></div>
                <button type="button" class="primary-button next-1 ${el.loading ? 'loading' : ''}" @click=${el.onNext1} style="display:${(el.step == '1') ? 'block;' : 'none;'}">
                    <span>${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-ButtonLabel-NextStep')}</span>
                    <preloader-element></preloader-element>
                </button>
                <button type="button" class="primary-button next-2 ${el.loading ? 'loading' : ''}" @click=${el.onNext2} style="display:${(el.step == '2') ? 'block;' : 'none;'}">
                    <span>${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-ButtonLabel-NextStep')}</span>
                    <preloader-element></preloader-element>
                </button>
                <button class="primary-button next-3 ${el.loading ? 'loading' : ''}" @click=${el.onSubmitRegister} style="display:${(el.step == '3') ? 'block;' : 'none;'}">
                    <span>${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-ButtonLabel-RegisterNewAccount')}</span>
                    <preloader-element></preloader-element>
                </button>
            </div>

            </form>
        </div>
    </div>
    
`

function renderGenderControls() {
    console.log("checking gender boolean" + window.ALLOW_UNDEFINED_GENDER);
    if (window.ALLOW_UNDEFINED_GENDER) {
        return html`
            <li class="mdc-list-item" data-value="U" role="option">
                ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Gender-SelectItem-PreferNotToSay')}
            </li>
        `
    }
}

function renderNationalityControls(el) {
    console.log(window.USE_NATIONALITY);
    console.log("checking");
    if(window.USE_NATIONALITY){
        return html`
            <div class="form-element-2">
                <div class="mdc-select mdc-nationality mdc-select--required">
                    <div class="mdc-select__anchor" aria-required="true">
                        <i class="mdc-select__dropdown-icon"></i>

                        <div id="demo-selected-text" class="mdc-select__selected-text" role="button"
                                aria-haspopup="listbox"
                                aria-labelledby="demo-label demo-selected-text"
                                aria-controls="my-helper-text-nationality" aria-describedby="my-helper-text-nationality"></div>
                        <span id="demo-label"
                                class="mdc-floating-label mdc-floating-label--float-above">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Citizenship')}
                        </span>

                        <div class="mdc-line-ripple"></div>
                    </div>

                    <div class="mdc-select__menu mdc-menu mdc-menu-surface" role="listbox">
                        <ul class="mdc-list">
                            ${getNationalityList(el)}
                        </ul>
                    </div>
                </div>
                <div class="mdc-text-field-helper-line">
                    <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
                            id="my-helper-text-nationality" aria-hidden="true">
                        ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-Citizenship')}
                    </div>
                </div>
            </div>
        `
    }
}

function renderSecretQuestionControls(el) {
    if (window.USE_SECRET_QUESTION) {
        return html`
            <div class="form-element-2">
                <div class="mdc-select mdc-security-question mdc-select--required">
                    <div class="mdc-select__anchor">
                        <i class="mdc-select__dropdown-icon"></i>

                        <div id="demo-selected-text" class="mdc-select__selected-text" role="button"
                                aria-haspopup="listbox"
                                aria-labelledby="demo-label demo-selected-text"
                                aria-controls="my-helper-text-secret-question" aria-describedby="my-helper-text-secret-question"></div>
                        <span id="demo-label"
                                class="mdc-floating-label mdc-floating-label--float-above">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-SecurityQuestion')}
                        </span>

                        <div class="mdc-line-ripple"></div>
                    </div>
                    <div class="mdc-select__menu mdc-menu mdc-menu-surface" role="listbox">
                        <ul class="mdc-list">
                            ${getSecurityQuestionList(el)}
                        </ul>
                    </div>
                </div>
                <div class="mdc-text-field-helper-line">
                    <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
                            id="my-helper-text-secret-question" aria-hidden="true">
                        ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-SecurityQuestion')}
                    </div>
                </div>
            </div>
        `
    }
}

function renderSecretAnswerControls(el) {
    if (window.USE_SECRET_ANSWER) {
        return html `
            <div class="form-element-2">
                <label class="mdc-text-field mdc-security-answer">
                    <div class="mdc-text-field__ripple"></div>
                    <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="secretAnswer">
                <span class="mdc-floating-label" id="my-label-id">
                    ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-SecurityAnswer')}
                </span>

                    <div class="mdc-line-ripple"></div>
                </label>

                <div class="mdc-text-field-helper-line">
                    <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
                            id="my-helper-id" aria-hidden="true">
                        ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-SecurityAnswer')}
                    </div>
                </div>
            </div>
        `
    }
}

function getNationalityList(el){
    if(Object.keys(el.registerInfo).length > 0){
        var citizenshipList = el.registerInfo.citizenshipList.Citizenship
        const displayList = [];
        for (const [key, value] of Object.entries(citizenshipList)) {
            displayList.push(
                html`
                    <li class="mdc-list-item" data-value="${value.bit8CitizenshipId}" role="option">
                        ${value.displayName}
                    </li>
                `
            ) 
        }
        return displayList
    }
}

function getCurrencyList(el){
    if(Object.keys(el.registerInfo).length > 0){
        var currencyList = el.registerInfo.countryCodeInfo.Currencies.Currency
        const displayList = [];
        for (const [key, value] of Object.entries(currencyList)) {
            displayList.push(
                html`
                    <li class="mdc-list-item" data-value="${value.CurrencyISO}" role="option">
                        ${value.CurrencyISO} ${value.CurrencySymbol}
                    </li>
                `
            ) 
        }
        return displayList
    }
}

function getMobilePrefixList(el){
    if(Object.keys(el.registerInfo).length > 0){
        var prefixList = el.registerInfo.countryCodeInfo.DialingCodes
        const displayList = [];
        for (const [key, value] of Object.entries(prefixList)) {
            displayList.push(
                html`
                    <li class="mdc-list-item" data-value="${value}" role="option">
                        +${value}
                    </li>
                `
            ) 
        }
        return displayList
    }
}

function getSecurityQuestionList(el){
    if(Object.keys(el.registerInfo).length > 0){
        var prefixList = el.registerInfo.secretQuestionList.PlayerSecretQuestion
        const displayList = [];
        for (const [key, value] of Object.entries(prefixList)) {
            displayList.push(
                html`
                    <li class="mdc-list-item" data-value="${value.ClientSecretQuestionID}" role="option">
                        ${value.Question}
                    </li>
                `
            ) 
        }
        return displayList
    }
}

function getCountryList(el){
    if(Object.keys(el.registerInfo).length > 0){
        var countryList = el.registerInfo.countryCodeInfo.Countries
        const displayList = [];
        for (const [key, value] of Object.entries(countryList)) {
            displayList.push(
                html`
                    <li class="mdc-list-item" data-value="${value.countryCode}" role="option">
                        ${value.displayName}
                    </li>
                `
            )
        }
        return displayList
    }
}

/**
 * register form request :
emailAddress: test25oct19sc59@maildrop.cc
countryCustom: 
countryISO: CA
userName: test25oct19sc59
citizenshipCustom: 
citizenshipID: 153
password: test25oct19sc59
languageISO: en
currencyISO: CAD
dateOfBirth: 2001-02-02
activateAccount: true
loginOnSuccess: true
firstName: test25oct19sc59
city: test25oct19sc59
lastName: test25oct19sc59
postcode: asdwq
gender: M
mobilePrefix: 1
mobilePhone: 568949855
address: test25oct19sc59;test25oct19sc59
secretQuestion: 1
secretAnswer: test25oct19sc59
g-recaptcha-response: 03AGdBq27Dqo6Hjt1OicgX5nsIaot7A2hsKK0dQBE_HZxpnChxsSTJ0SfIESaK0MU96nBfpx0ydYBNz8Z4vulNxKTvgu37YcM-dnZS3wFHdmAXTF_A0YaJnpJNVXq9ORkxGrfnyUwyjIDC1wxKNxk96uiwoI9OKkuU55weXgBSsJ2lME4-XTKZlJem-RCovqSAwFzqGuZMag7IOMF3_Qpa4z7-Wvb8O7rLLTHKVmiHT4_y2G1FEa0xkVjgfSE4geK-dHLUjunlyY1QjlzsrI_FO4hFgz3TWpls5TPxwe4T27fQC1DC-u9Hcomkj9uCa8-RlrGny5IWerdwVcpvJv3WUlGelgD0a9sM9c-dXoC5d0qn6inwsQ_T4ysgxbXc0iAbikGVh3qPj5ENESAoJCUhF6fLZ-uLLUpeVg
couponCode: 
currentUrl: https://www.your-casino-site.com/en/
 */
import {render} from "lit-html";
import {TemplateHorizontalMenu} from "../../templates/template-items/TemplateHorizontalMenu";
import {USER} from "../core/User";
import {SIDE_VIEW_MANAGER} from "./SideViewManager";
import {SideViewBaseClass} from "./SideViewBaseClass";
import {SideViewLogin} from "./sideViews/SideViewLogin";
import {SideViewUser} from "./sideViews/SideViewUser";
import {SideViewBank} from "./sideViews/SideViewBank";
import {SideViewNotifications} from "./sideViews/SideViewNotifications";
import {SideViewRegister} from "./sideViews/SideViewRegister";
import {SideViewMainMenu} from "./sideViews/SideViewMainMenu";
import {SideViewForgotPassword} from "./sideViews/SideViewForgotPassword";
import {SideViewGameInfo} from "./sideViews/SideViewGameInfo";
import {Helpers} from "../util/Helpers";
import {SearchView} from "./SearchView";
import {Sidebar} from "./Sidebar";
import {SideviewSidebarMenu} from "./sideViews/SideviewSidebarMenu";
import {SideViewRegisterShort} from "./sideViews/SideViewRegisterShort";
import {SideViewMenu} from "./SideViewMenu";

const BASE_URL=window.location.origin;
const LANGUAGE=Helpers.getCookie('_icl_current_language');

export class HorizontalMenu {
    constructor() {
        this.userData=null;
        this.mode=HorizontalMenu.MODE.LOGGED_OUT;
        this.container=document.getElementById("horizontal-menu");
        this.sidebar=null;
        if(this.container){
            this._init();
        }
    }

    _init(){
        SideViewBank.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
        SideViewLogin.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
        SideViewRegister.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
        SideViewRegisterShort.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
        SideViewUser.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
        SideViewMainMenu.ALIGNMENT=SideViewBaseClass.ALIGNMENT.LEFT;
        SideViewForgotPassword.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;

        if(USER.isLoggedIn){
            this.mode=HorizontalMenu.MODE.LOGGED_IN;
            this.userData=USER.data;
        }

        this._setupEventListeners();
        this._renderMenu();

    }

    _setupEventListeners(){
        USER.addListener("onLoginStateChange",e=>{
            this.userData=USER.data;
            if(e.loggedIn){
                this.mode=HorizontalMenu.MODE.LOGGED_IN;
            }else{
                this.mode=HorizontalMenu.MODE.LOGGED_OUT;
                SIDE_VIEW_MANAGER.closeAnySideview();
            }
            this._renderMenu();
        });

        USER.addListener("onUserBalanceUpdate",()=>{
            let bDisplay=this.container.querySelector(".current-balance-display");
            if(bDisplay){
                bDisplay.innerHTML=this.userData.CurrentBalanceDisaply;
            }
        });
    }


    _renderMenu(){
        render(TemplateHorizontalMenu(this), this.container, {eventContext:this});
    }

    _openSidebar(){
        new SideviewSidebarMenu();
    }


    _onMenuItemClick(e){
        let action=e.currentTarget.dataset.action;
        switch (action) {
            case "home":
                window.location.href=BASE_URL+'/'+LANGUAGE+'/';
                break;
            case "login":
                new SideViewLogin();
                break;
            case "user":
                new SideViewUser();
                break;
            case "bank":
                new SideViewBank();
                break;
            case "bankWithdraw":
                new SideViewBank().menu.setSelectedItem(SideViewMenu.ITEM_TYPES.WITHDRAW);
                break;
            case "notifications":
                new SideViewNotifications();
                break;
            case "signUp":
                this.launchRegistration();
                break;
            case "menu":
                new SideViewMainMenu();
                break;
            case "logout":
                USER.logout();
                break;
            case "promotions":
                try {
                    window.location.href=document.querySelector("input[name='promotions-link']").value;
                }catch(e){
                    console.log("No promotions link found on page")
                }
                break;
            case "vip":
                try {
                    window.location.href=document.querySelector("input[name='vip-program-link']").value;
                }catch(e){
                    console.log("No promotions link found on page")
                }
                break;
            case "new-games":
                try {
                    window.location.href=document.querySelector("input[name='game-category-link_new-games']").value;
                }catch(e){
                    console.log("No new games link found on page")
                }
                break;
            case "slots":
                try {
                    window.location.href=document.querySelector("input[name='game-category-link_slots']").value;
                }catch(e){
                    console.log("No slots link found on page")
                }
                break;
            case "livecasino":
                try {
                    window.location.href=document.querySelector("input[name='game-category-link_livecasino']").value;
                }catch(e){
                    console.log("No live casino link found on page")
                }
                break;
            case "all-casino-games":
                try {
                    window.location.href=document.querySelector("input[name='game-category-link_all-casino-games']").value;
                }catch(e){
                    console.log("No all casino games link found on page")
                }
                break;
            case "help":
                window.location.href = window.helpLink;
                break;
            case "search":
                if(this.searchView){
                    return;
                }
                console.log("launch search control");
                this.searchView=new SearchView(()=>{this.searchView=null;});
                break;
            case "link":
                window.location.href=e.currentTarget.dataset.url;
                break;
            case "sidebar":
                console.log("Open the sidebar");
                this._openSidebar();
                break;
            default:
                console.log(`Action not defined: ${action}`)

        }
    }

    launchRegistration(){
        new SideViewRegister();
    }

    onForgottenPasswordClick(e){
        e.preventDefault();
        console.log("Open forgotten password view");
        new SideViewForgotPassword();
    }

    scrollToActiveMenu() {
        setTimeout(()=>{
            let mainContainer=document.getElementById("main-container");
            if(mainContainer) {
                    console.log(document.getElementsByClassName('tab-capsule active btn-game-category'));
                    var menus = document.getElementsByClassName('btn-game-category');
                    for (var i = 0; i < menus.length; i++) {
                        if (menus[i].classList.contains('active')) {
                            document.getElementsByClassName('tab-capsule active btn-game-category')[0].scrollIntoView();
                        }
                    }
            }
        },2000);
    }
}

HorizontalMenu.MODE={
    LOGGED_IN:"logged-in",
    LOGGED_OUT:"logged-out"
}
import {EventEmitter} from 'events';
import {USER} from "./User";
import {Helpers} from "../util/Helpers";



const BASE_URL=window.location.origin+"/api/";
const REF_DATA_URL=window.location.origin+"/reference_data/";
const BO_LANGUAGE=Helpers.getCookie('bo_language_code');
const LANGUAGE=Helpers.getCookie('_icl_current_language');

class CAPPApi extends EventEmitter{
    constructor() {
        super();
        console.log("CAPP api instance created");
    }

    get(apiMethod, data){
        console.log("get ", BASE_URL+apiMethod);

        return fetch(`${BASE_URL}${apiMethod}?${data ? new URLSearchParams(data).toString() : ''}`,{
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                //'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
        }).then(response=>{
            if(response.ok){
                return response.json();
            }else{
                throw new Error("Server error "+response.status);
            }

        }).then(data=>{
            let response=data.response;
            if(response.error){
                response.error.errorMessage=APP_STRINGS.getStringById(data.response.error.errorID);
                response.error.errorTitle=APP_STRINGS.getTitleById(data.response.error.errorID);
            }
            return(response);
        }).then(data=>this._checkForServerErrors(data))
            .catch(error=>{
            console.error(error);
                this.emit("ServerError",error);
        })
    }

    post(apiMethod, data){
        console.log("do post to", BASE_URL+apiMethod);

        return fetch(BASE_URL+apiMethod,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                //'Content-Type': 'application/json'
                 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: new URLSearchParams(data)//JSON.stringify(data) // body data type must match "Content-Type" header
        }).then(response=>{
            return response.json();
        }).then(data=>{

            //todo check for server errors and do additional stuff like get error messages and so on
            let response=data.response;
            if(response.error){
                response.error.errorMessage=APP_STRINGS.getStringById(data.response.error.errorID);
                response.error.errorTitle=APP_STRINGS.getTitleById(data.response.error.errorID);

            }
            return(response);
        }).then(data=>this._checkForServerErrors(data))
          .catch(error=>{
            console.error(error);
            this.emit("ServerError",error);
        })
    }

    _checkForServerErrors(response){
        return new Promise((resolve,reject)=>{
            if(response.error && response.error.errorID){

                //todo check for server errors and do additional stuff like get error messages and so on
                switch (response.error.errorID) {
                    case "500":
                    case 500:
                        if(USER.isLoggedIn){
                            this.emit("SessionExpired",response.error);
                        }

                        resolve();
                        break;
                    default:
                        resolve(response)
                }
            }else{
                resolve(response);
            }
        })
    }


    /**
     * @method login
     * @param username {string} - username
     * @param password {string} - password
     * @param language {string} - language code for preferred language
     * @param currentUrl {string} - current window url
     * @async
     * @description Login using REST api method sessionLogin
     */
    login(username, password){
        let data= {
            lang: BO_LANGUAGE,
            clientUsername: username,
            clientPassword: password,
            currentUrl: window.location.href
        };
        return this.post("sessionLoginV2",data);
    }

    /**
     * @method login
     * @param sessionKey {string} - sessionKey
     * @param lang {string} - language code for preferred language
     * @param currentUrl {string} - current window url
     * @async
     * @description Login using REST api method sessionLogin
     */
    loginBySessionKey(sessionKey){
        let data= {
            sessionKey: sessionKey,
            lang: BO_LANGUAGE,
            currentUrl: window.location.href
        };
        return this.post("sessionLoginV2",data);
    }

    /**
     * @method logout
     * @param sessionKey {string} - user session key
     * @description Logout using REST api method sessionLogout
     */
    logout(sessionKey){
       let data={
           sessionKey: sessionKey
       }
       return this.get("sessionLogout",data);
    }

    loadApplicationStrings(){
        return fetch(`${REF_DATA_URL}alert_strings/${LANGUAGE}.json`,{
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors', // no-cors, *cors, same-origin
            cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
        }).then(response=>{
            return response.json();
        });
    }

    loadWPMLStrings(){
        return fetch(`${REF_DATA_URL}wpml_strings/${LANGUAGE}.json`,{
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors', // no-cors, *cors, same-origin
            cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
        }).then(response=>{
            return response.json();
        });
    }

    loadUser(){
        let data={
            sessionKey: USER.sessionKey,
            lang: LANGUAGE
        }
        return this.get("playerGetAllDetailsForLoggedIn",data);
    }

    getPlayerBalance(sessionKey,lang){
        let data = {
            lang: lang,
            sessionKey: sessionKey
        };
        return this.get('playerGetQuickBalance',data);
    }

    getAccessStatus(sessionKey){
        return this.get('playerGetAccessStatus',{sessionKey:sessionKey});
    }

    getPaymentAccounts(sessionKey,lang){
        let data = {
            lang: lang,
            sessionKey: sessionKey
        };
        return this.get('playerGetPaymentAccounts',data);
    }

    resendActivationCode(sessionKey){
        let data = {
            sessionKey: sessionKey
        };
        return this.get('playerResendActivationCode',data);
    }

    resetPassword(email){
        let data = {
            emailAddress: email,
            // userName: username
        }
        return this.get("playerRequestPasswordReset",data);
    }

    changePassword(oldPassword, newPassword, sessionKey){
        let data = {
            oldPassword: oldPassword,
            newPassword: newPassword,
            sessionKey: sessionKey
        }
        return this.post('playerUpdateAccountPassword', data);
    }

    updatePlayerInfo(data){
        return this.post('playerUpdateAccount', data);
    }

    register(data){

        if(window.isSimplifiedSignUpForm){
            return this.post('playerRegisterAccountSimplify', data);
        }
        else{
            return this.post('playerRegisterAccount', data);
        }
    }

    registerConfirmLocation(clientId,signature,answer){
        let data={
            clientId:clientId,
            signature:signature,
            answer:answer
        }
        return this.post('playerConfirmLocation', data);
    }

    checkPlayerInvite(email){
        let data={
            email:email
        }
        return this.post('playerIsPlayerInvited', data);
    }



    /*BANK FUNCTIONS START*/
    getAllDepositMethods(sessionKey, countryISO, currencyISO, lang){
        let data={
            sessionKey:sessionKey,
            countryISO:countryISO,
            currencyISO:currencyISO,
            lang:lang
        };
        return this.get("transactionGetAllDepositMethods",data);
    };

    getAllPaymentAccounts(sessionKey,lang){
        let data={sessionKey:sessionKey, lang:lang};
        return this.get("playerGetPaymentAccounts",data);
    }

    getPaymentExternalLink(sessionKey, paymentMethod, processType, amount, bonusRuleID,bonusCode, email, first_name, last_name, currency_code, country_code, languageISO, additionalData){
        let data = {
            sessionKey: sessionKey,
            amount: amount,
            paymentMethod: paymentMethod,
			processType: processType,
            bonusRuleID: bonusRuleID,
            bonusCode: bonusCode,
            merchant_customer_id: email,
            country_code: country_code,
            currency_code: currency_code,
            languageISO: languageISO,
            first_name: first_name,
            last_name: last_name
        };

        if(additionalData){
            for(let prop in additionalData){
                data[prop]=additionalData[prop];
            }
        }

        return this.post('getPaymentExternalLink', data);
    }

    submitPaymentData(sessionKey, depositAmount, paymentMethod, bonusRuleID, siteType){
        let data = {
            sessionKey: sessionKey,
            depositAmount: depositAmount,
            paymentMethod:paymentMethod,
            bonusRuleID: bonusRuleID,
            siteType: siteType
        };
        return this.get('transactionDepositAmount', data);
    }

    launchPIQ(sessionKey,depositAmount, bonusRuleID, type,platformPaymentMethod){
        let data = {
            sessionKey: sessionKey,
            amount: depositAmount,
            bonusId: bonusRuleID,
            method: type, //deposit or withdraw
            platformPaymentMethod:platformPaymentMethod 
        };
        return this.post('launchPIQ', data);
    }

    getPIQDepositBonuses(sessionKey,lang){
        let data={
            sessionKey:sessionKey,
            lang:lang
        }
        return this.get('playerGetDepositBonuses',data);
    }


    getBonusUsingBonusCode(sessionKey,depositAmount,paymentMethod,bonusRuleID,bonusCode){
        let data = {
            sessionKey: sessionKey,
            depositAmount: depositAmount,
            paymentMethod: paymentMethod,
            bonusRuleID: bonusRuleID,
            bonusCode:bonusCode
        };
        return this.get('transactionPreDepositCheckIfBonusMetWithObject', data);
    }

    getAllWithdrawalmethods(sessionKey, countryISO, currencyISO, lang){
        let data = {
            sessionKey: sessionKey,
            countryISO: countryISO,
            currencyISO: currencyISO,
            lang: lang
        };
        return this.get('transactionGetAllWithdrawOptions',data);
    }

    getExistingBankAccounts(sessionKey){
        let data = {
            sessionKey: sessionKey
        };
        return this.get('viewWithdrawalAccount',data);
    }

    bankTransferWithdrawal(sessionKey,amount,data){
        data.sessionKey= sessionKey;
        data.amount= amount;
        return this.post('withdrawBankTransfer',data);
    }

    ibV4Withdrawal(sessionKey,username,amount,bankCountry,bankAccountHolderName,bankName,bankSwiftCode,bankBSBCode,bankAccountNumber,currencyCode,countryISO,lang){
        let data = {
            sessionKey:sessionKey,
            username:username,
            amount:amount,
            bankCountry:bankCountry,
            bankAccountHolderName:bankAccountHolderName,
            bankName:bankName,
            bankSwiftCode:bankSwiftCode,
            bankBSBCode:bankBSBCode,
            bankAccountNumber:bankAccountNumber,
            currencyCode:currencyCode,
            countryISO:countryISO,
            lang:lang
        };
        return this.post('ibV4MakeWithdrawalReserveFunds',data);
    }

    cryptopayWithdrawal(sessionKey,amount,data){
        data.sessionKey= sessionKey;
        data.amount= amount;
        return this.get('cryptopayWithdrawal',data);
    }

    submitWithdrawData(sessionKey,amount,data){
        data.sessionKey= sessionKey;
        data.amount= amount;
        return this.get('transactionWithdrawAmount',data);
    }

    getPendingWthdrawals(sessionKey){
        let data = {
            sessionKey: sessionKey
        };
        return this.get('getPendingWithdrawalsForPlayer',data);
    }

    cancelPendingWithdrawal(sessionKey, transactionKey){
        let data = {
            sessionKey: sessionKey,
            transactionKey: transactionKey,
            reason:""
        };
        return this.get("transactionCancelWithdrawal",data);
    }


    /*BANK FUNCTIONS END*/




    
    checkEmailExist(emailAddress){
        let data = {
            emailAddress : emailAddress
        }
        return this.get('playerValidateEmailExists', data);
    }

    checkSignUpCode(SignupCode){
        let data = {
            SignupCode : SignupCode
        }
        return this.get('getSignupCode', data);
    }

    getCountryCodeInfo(){
        return this.get('getCountryCodeInfo', {});
    }

    getCitizenshipList(){
        return this.get('getCitizenshipList', {});
    }

    getSecretQuestionList(){
        let data = {
            lang : LANGUAGE
        }
        return this.get('playerGetSecretQuestionsByLanguageCode', data);
    }

    transactionGetAllDepositLimit(sessionKey){
        let data = {
            sessionKey:sessionKey
        }
        return this.get('transactionGetAllDepositLimit', data);
    }

    gameLimitGetAll(sessionKey){
        let data = {
            sessionKey:sessionKey
        }
        return this.get('gameLimitGetAll', data);
    }

    transactionSetDepositLimit(interval, amount, sessionKey){
        let data = {
            sessionKey: sessionKey,
            interval: interval,
            amount: amount,
        }
        return this.get('transactionSetDepositLimit', data);
    }

    setBetLimit(interval, amount, sessionKey){
        let data = {
            sessionKey: sessionKey,
            interval: interval,
            amount: amount,
        }
        return this.get('gameLimitSetBetAmount', data);
    }

    setLossLimit(interval, amount, sessionKey){
        let data = {
            sessionKey: sessionKey,
            interval: interval,
            amount: amount,
        }
        return this.get('gameLimitSetLossAmount', data);
    }

    setTimeLimit(timeDuration, sessionKey){
        let data = {
            sessionKey: sessionKey,
            timeDuration: timeDuration,
        }
        return this.get('gameLimitSetTimeLimit', data);
    }

    setSelfExclude(minutes, isIndefinite, reason, sessionKey){
        let data = {
            sessionKey: sessionKey,
            numMinutes: minutes,
            isIndefinite: isIndefinite,
            reason: reason,
        }
        return this.get('playerRestrictionSetSelfExlusion', data);
    }

    getAvailableBonuses(sessionKey){
        let data = {
            sessionKey: sessionKey
        }
        return this.get('playerGetDepositAndPreAuthBonuses', data);
    }

    getActiveBonuses(sessionKey){
        let data = {
            sessionKey: sessionKey
        }
        return this.get('playerGetActiveBonuses', data);
    }

    claimBonus(bonusAwardedPreID, sessionKey){
        let data = {
            bonusAwardedPreID: bonusAwardedPreID,
            sessionKey: sessionKey
        }
        return this.get('playerAuthBonus', data);
    }
    
    setTopPriorityBonus(bonusAwardedPreID, sessionKey){
        let data = {
            bonusAwardedID: bonusAwardedPreID,
            sessionKey: sessionKey
        }
        return this.get('playerSetTopPriorityForBonusAwareded', data);
    }

    redeemVoucherCode(voucherCode, sessionKey, lang){
        let data = {
            voucherCode: voucherCode,
            sessionKey: sessionKey,
            lang: lang
        }
        return this.get('voucherRedeemCode', data);
    }

    forfeitBonus(bonusAwardedID, sessionKey){
        let data = {
            bonusAwardedID: bonusAwardedID,
            sessionKey: sessionKey
        }
        return this.get('playerForfeitBonus', data);
    }

    getGameDetailLite(searchString,gameCategory, gameMakers, gameThemes, pokieFilters, lineFilters, featureFilters, newParam, hot, order, orderBy, limit, page, lang, platform){
        let data = {
            s: searchString,
            gameCategory: gameCategory,
            gameMakers: gameMakers,
            gameThemes: gameThemes,
            pokieFilters: pokieFilters,
            lineFilters: lineFilters,
            featureFilters: featureFilters,
            new: newParam,
            hot: hot,
            order: order,
            orderby: orderBy,
            limit: limit,
            page: page,
            lang: lang,
            platform: platform
        }
        return this.get('gamesGetDetailsLite', data);
    }

    loadGamesPage(category,maker,pageSize,pageNum,language,sorting){
        let data = {
            gameCategory: category,
            order: sorting,
            limit: pageSize,
            page: pageNum,
            lang: language
        }

        if(maker !== ''){
            data.gameMakers = maker;
        }
        return this.get('gamesGetDetailsLite', data);
    }

    getAccountTransactions(filterStartDate, filterEndDate, sessionKey){
        let data = {
            filterStartDate: filterStartDate,
            filterEndDate: filterEndDate,
            sessionKey: sessionKey
        }
        return this.get('transactionGetAccountTransactions', data);
        // sessionKey: 6489a859deba4a669c408bbbb8b62b46
        // filterStartDate: 2020-05-05
        // filterEndDate: 2020-05-06

    }

    getPlayerGamePlayHistory(startDate, endDate, limit, page, filter, sessionKey){
        let data = {
            dateFrom: startDate,
            dateTo: endDate,
            perPage: limit,
            pageNo: page,
            filter: filter,
            sessionKey: sessionKey
        }
        return this.get('playerGetPlayerTrailWithFilter', data);
    }

    getPlayerBonusHistory(dateFrom, removeActives, sessionKey){
        let data = {
            dateFrom : dateFrom,
            removeActives: removeActives,
            sessionKey: sessionKey
        }
        return this.get('playerGetAllBonusesDetails', data);
    }

    getPlayerRacesHistory(dateFrom, removeActives, sessionKey){
        let data = {
            dateFrom : dateFrom,
            removeActives: removeActives,
            sessionKey: sessionKey
        }
        return this.get('playerGetAllTournamentStatuses', data);
    }

    gameStartPreCheck(sessionKey, gameId){
        let data={
            sessionKey:sessionKey,
            gameId:gameId,
        };
        return this.get('gameStartPreCheck', data);
    }

    getGameUrl(sessionKey, gameId, fun, lang){
        let data={
            sessionKey:sessionKey,
            gameID:gameId,
            fun:fun,
            lang:lang
        };

        return this.get('gameGetStartURL', data);
    }

    getFavouriteGames(sessionKey){
        let data={
            sessionKey:sessionKey
        };
        return this.get('gameGetPlayerFavouriteGames', data);
    }

    setFavouriteGameFlag(sessionKey, gameId, value){
        let data = {
            sessionKey: sessionKey,
            gameID: gameId,
            assignFlag: value
        };
        return this.get('gameMarkGameAsFavourite', data);
    }


    getPlayerPointsHistory(dateFrom, sessionKey){
        let data = {
            dateFrom : dateFrom,
            sessionKey: sessionKey
        }
        return this.get('sessionGetSessionStatisticsHistory', data);
    }

    getPlayerShopPurchasesHistory(dateFrom, sessionKey){
        let data = {
            dateFrom : dateFrom,
            sessionKey: sessionKey
        }
        return this.get('playerGetLoyaltyAdjustments', data);
    }

    redeemLoyaltyPointsPrize(prizeId,sessionKey){
        let data = {
            loyaltyRedemptionID: prizeId,
            sessionKey: sessionKey
        }
        return this.get('loyaltyRedeemLoyaltyPointsToPrize', data);
    }




    activateAccount(activateCode, ea){
        let data = {
            ea: ea,
            activateCode: activateCode
        }
        return this.get('playerActivateAccount', data)
    }

    playerResetPasswordWithResetCode(emailAddress, passwordResetCode){
        let data = {
            emailAddress: emailAddress,
            passwordResetCode: passwordResetCode
        }
        return this.get('playerResetPassword', data)
    }

    uploadDocument(formData){


        return new Promise(res=>{
            $.ajax({
                url:BASE_URL+"kycUploader",
                type:'POST',
                data:formData,
                mimeType: "multipart/form-data",
                contentType: false,
                cache: false,
                processData: false,
                success: function (data, textStatus, jqXHR) {
                    let o=JSON.parse(data);
                    if(o.response){
                        if(o.response.error){
                            o.response.error.errorMessage=APP_STRINGS.getStringById(o.response.error.errorID);
                            o.response.error.errorTitle=APP_STRINGS.getTitleById(o.response.error.errorID);
                        }
                        res(o.response);
                    }
                }
            });
        })
/*
        return fetch(BASE_URL+"kycUploader",{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                //'Content-Type': 'multipart/form-data'
                'x-requested-with': 'XMLHttpRequest'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: formData
        }).then(response=>{
            return response.json();
        }).then(data=>{

            //todo check for server errors and do additional stuff like get error messages and so on
            let response=data.response;
            if(response.error){
                response.error.errorMessage=APP_STRINGS.getStringById(data.response.error.errorID);
                response.error.errorTitle=APP_STRINGS.getTitleById(data.response.error.errorID);

            }
            return(response);
        }).then(data=>this._checkForServerErrors(data))
            .catch(error=>{
                console.error(error);
                this.emit("ServerError",error);
            })*/
    }

    getNewFeeds(){
        let data = {
            lang: LANGUAGE,
        }
        return this.get('getFeeds', data)
    }

    getPageSettings(){
        return this.get("getPageSettings",{});
    }

    submitCountryRestrictionEmail(email, csrfToken){
        let data = {
            email: email,
            csrf_token: csrfToken
        };
        return this.post(`addRestrictedCountryUserSubmission`,data);
    }


}


/*
Api.prototype.uploadDocument = function(username, userId, image, paymentMethod, idDocument, accountReference,  callback){
        var data = new FormData();
        data.append('username', username);
        data.append('ImageFile', image);
        data.append('paymentMethod', paymentMethod);
        data.append('AccountID', userId);
        data.append('idDocument', idDocument);
        data.append('accountReference', idDocument);


       //console.log("Data to upload");
       //console.log(data);

        $.ajax({
            url:this.baseUrl+this.methods.uploadDocument,
            type:'POST',
            data:data,
            mimeType: "multipart/form-data",
            contentType: false,
            cache: false,
            processData: false,
            success: function (data, textStatus, jqXHR) {
               //console.log("file uploaded");
               //console.log(data);
               //console.log(textStatus);
			    if(data != ''){
                    var o=JSON.parse(data);

                    if(o.response){

                        if(o.response.error){
                            callback(false);
                        }
                        else{
                            callback(true);
					    }
                    }
                    else{
                        callback(false);
                    }
		        }
                else{
                    callback(false);
			    }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                callback(false);
               //console.log("error uploading file");
            }
        });
    };
* */





// expose CAPPApi as singleton
export const CAPP_API = new CAPPApi();
import {Helpers} from "../util/Helpers";
import {USER} from "./User";

export const InitLivechatController=(hideByDefault)=>{

    window.LC_API = window.LC_API || {};
    window.LC_API.on_after_load = function() {
        if(USER.isLoggedIn){
            setLoggedInMode();
            if(hideByDefault){
                LC_API.hide_chat_window();
            }
        }else{
            setLoggedOutMode();
        }

        USER.on("onLoginStateChange",()=>{

            if(USER.isLoggedIn){
                setLoggedInMode();
                if(hideByDefault){
                    LC_API.hide_chat_window();
                }else{
                    LC_API.minimize_chat_window();
                }
            }else{
                setLoggedOutMode();
            }
        })
    };
}

export const SetLivechatDepositErrorCustomVariable=paymentType=>{
    let vars=getCustomVaribles();
    vars.push({name: 'DepositFailed', value: paymentType});
    LC_API.set_custom_variables(vars);
}

function setLoggedOutMode(){
    if(LC_API){
        if(window.hideChatWindowOnLogout){
            LC_API.hide_chat_window();
        }
        LC_API.set_custom_variables([]);
    }
}

function setLoggedInMode(){
    LC_API.set_custom_variables(createCustomVaribles());
    __lc.visitor={
        name: `${USER.data.FirstName} ${USER.data.LastName}`,
        mail: USER.data.EmailAddress
    }
}



function createCustomVaribles() {
    return [
        { name: "First Name", value: USER.data.FirstName },
        { name: "Email", value: USER.data.EmailAddress },
        //{ name: "Platform", value: window.clientInformation.platform},
        { name: "Current Real Balance", value: USER.data.CurrencySymbol+Helpers.formatCurrencyCents(parseFloat(USER.data.CurrentRealBalance)) },
        { name: "CurrentBonusBalance", value: USER.data.CurrencySymbol+Helpers.formatCurrencyCents(parseFloat(USER.data.CurrentBonusBalance)) },
        { name: "LoyaltyLevelName", value:  USER.data.VIPLevelName},
        { name: "AccountActivated", value:  USER.data.AccountActivated},
        { name: "KycChecked", value: USER.data.IsKYCChecked },
        //{ name: "PlayerProfileUrl", value:  },
    ];
}

//window.__lc.params.unshift();
import {render} from "lit-html";
import {SideViewBaseClass} from "../SideViewBaseClass";
import {TemplateNotificationSideView} from "@/templates/template-items/side-view/TemplateNotificationSideView";
import {FormHelpers} from "../../util/FormHelpers";
import {USER} from "../../core/User";
import {Popup} from "../Popup";


export class SideViewNotifications extends SideViewBaseClass{
    constructor() {
        super("forgot-password",SideViewBaseClass.TYPE.NORMAL);
        this.loading=false;
    }

    renderViewContent() {
        render(TemplateNotificationSideView(this),this.element,{eventContext:this});
        FormHelpers.initializeFormComponents(this.element);
    }

}
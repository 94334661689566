import {render} from "lit-html";
import validator from "validator/es";
import {SideViewBaseClass} from "../SideViewBaseClass";
import {USER} from "../../core/User";
import {FormHelpers} from "../../util/FormHelpers";
import {TemplateRegisterShortSideView} from "../../../templates/template-items/side-view/TemplateRegisterShortSideView";
import {Validations} from "@/js/util/Validations";
import {Helpers} from "../../util/Helpers";
import {Popup} from "../Popup";
import {SlideOverViewStatus} from "../SlideOverViewStatus";
import {SIDE_VIEW_MANAGER} from "../SideViewManager";
import {SideViewUser} from "./SideViewUser";
import {SearchView} from "../SearchView";

const LANGUAGE=Helpers.getCookie('_icl_current_language');

export class SideViewRegisterShort extends SideViewBaseClass{
    constructor() {
        super("register",SideViewBaseClass.TYPE.WIDE,null, SideViewRegisterShort.ALIGNMENT);
        this.loading = false;
        this.loadingRegisterInfo = false;
        this.registrationDone=false;
        this.registerInfo = {}
        this.recaptchaAPI = null
        this.widgetID = null;
        this.redirectAfterLoginURL=null;

    }

    renderViewContent() {
        $.getScript('https://www.google.com/recaptcha/api.js?onload=onReadyCallback&render=explicit', function(data, textStatus, jqxhr ) {
            console.log(data);
            console.log(textStatus);
            console.log(jqxhr);
        });

        this.registerInfo = {}
        this.loadingRegisterInfo = true;
        this.updateView()
        this.getRegisterData()

    }

    getRegisterData(){
        this.loadingRegisterInfo = true;
        USER.getRegisterDataList().then(data=>{
            this.registerInfo = data;
            this.loadingRegisterInfo = false;
            this.updateView()

            FormHelpers.initializeFormComponents(this.element);

            this._setupEventListener()
        });
    }

    updateView(){
        render(TemplateRegisterShortSideView(this),this.element,{eventContext:this});
    }

    _getMDCInputFields(){
        let mdcInput = {
            //step-1
            emailAddress: document.querySelector(".mdc-email-address").MDCTextField,
            password: document.querySelector(".mdc-password").MDCTextField,
            confirmPassword: document.querySelector(".mdc-confirm-password").MDCTextField,
            firstName: document.querySelector(".mdc-first-name").MDCTextField,
            lastName: document.querySelector(".mdc-last-name").MDCTextField,
            mobilePrefix: document.querySelector(".mdc-mobile-prefix").MDCSelect,
            mobileNumber: document.querySelector(".mdc-mobile-number").MDCTextField,
            tncCheckBox: document.querySelector(".mdc-tc-checkbox").MDCCheckbox,


        }
        return mdcInput
    }

    _setupEventListener(){
        var input = this._getMDCInputFields()
        input.emailAddress.listen('change', (e) => {
            var emailAddress = e.currentTarget.MDCTextField
            if(validator.isEmpty(emailAddress.value)){
                this._setErrorMessage(emailAddress, APP_STRINGS.getStringById("460"));
            }else if(!validator.isEmail(emailAddress.value)){
                this._setErrorMessage(emailAddress, APP_STRINGS.getStringById("460"));
            }else{
                this._setValidInput(emailAddress);
            }
        });

        if(Helpers.getDeviceType() == 'mobile'){
            input.emailAddress.listen('keyup', (e) => {
                var emailAddress = e.currentTarget.MDCTextField
                emailAddress.value = emailAddress.value.toLowerCase()
            });
        }

        input.emailAddress.listen('change', (e) => {
            var emailAddress = e.currentTarget.MDCTextField
            if(validator.isEmpty(emailAddress.value)){
                this._setErrorMessage(emailAddress, APP_STRINGS.getStringById("460"));
            }else if(!validator.isEmail(emailAddress.value)){
                this._setErrorMessage(emailAddress, APP_STRINGS.getStringById("460"));
            }else{
                this._setValidInput(emailAddress);
            }
        });

        input.password.listen('change', (e) => {
            var password = e.currentTarget.MDCTextField
            if(validator.isEmpty(password.value)){
                this._setErrorMessage(password, 'Empty password');
                return
            }
            var validatePasswordFormat = Validations.validatePassword(password.value)
            if(!validatePasswordFormat.valid){
                this._setErrorMessage(password, validatePasswordFormat.message);
            }else {
                this._setValidInput(password);
            }
        });

        input.confirmPassword.listen('change', (e) => {
            var confirmPassword = e.currentTarget.MDCTextField
            if($('.mdc-password').children('input').val() != confirmPassword.value){
                this._setErrorMessage(confirmPassword, APP_STRINGS.getStringById("2221005"));
            }else{
                this._setValidInput(confirmPassword);
            }
        });


        input.firstName.listen('change', (e) => {
            var firstName = e.currentTarget.MDCTextField
            var reg = /^[^,./<>?;':"[\]{}\-=_+`~!@#$%^&*() ]*$/;
            if(validator.isEmpty(firstName.value)){
                this._setErrorMessage(firstName, APP_STRINGS.getStringById("450"));
            }else if(!validator.isLength(firstName.value, {min: 2, max: 40})){
                this._setErrorMessage(firstName, APP_STRINGS.getStringById("450"));
            }else if (!(reg.test(firstName.value))) {
                this._setErrorMessage(firstName, APP_STRINGS.getStringById("450"));
            }else{
                this._setValidInput(firstName);
            }
        });

        input.lastName.listen('change', (e) => {
            var lastName = e.currentTarget.MDCTextField
            var reg = /^[^,./<>?;':"[\]{}\-=_+`~!@#$%^&*() ]*$/;
            if(validator.isEmpty(lastName.value)){
                this._setErrorMessage(lastName, APP_STRINGS.getStringById("451"));
            }else if(!validator.isLength(lastName.value, {min: 2, max: 40})){
                this._setErrorMessage(lastName, APP_STRINGS.getStringById("451"));
            }else if (!(reg.test(lastName.value))) {
                this._setErrorMessage(lastName, APP_STRINGS.getStringById("451"));
            }else{
                this._setValidInput(lastName);
            }
        });


        input.mobilePrefix.listen('MDCSelect:change', (e) => {
            var mobilePrefix = e.currentTarget.MDCSelect
            if(validator.isEmpty(mobilePrefix.value)){
                mobilePrefix.useNativeValidation = false;
                mobilePrefix.valid = false;
                this._setMobileHelperText(false,'Please select mobile prefix');
            }else{
                mobilePrefix.useNativeValidation = true;
                mobilePrefix.valid = true;
                this._setMobileHelperText(true,'');
            }
        });

        input.mobileNumber.listen('change', (e) => {
            var mobileNumber = e.currentTarget.MDCTextField;

            if(!validator.isLength(mobileNumber.value, {min: 6, max: 19})){
                mobileNumber.useNativeValidation = false;
                mobileNumber.valid = false;
                this._setMobileHelperText(false,APP_STRINGS.getStringById("462"));
            }else if(!validator.isNumeric(mobileNumber.value)) {
                mobileNumber.useNativeValidation = false;
                mobileNumber.valid = false;
                this._setMobileHelperText(false, APP_STRINGS.getStringById("462"));
            }else if(validator.isEmpty(input.mobilePrefix.value)){
                input.mobilePrefix.useNativeValidation = false;
                input.mobilePrefix.valid = false;
                this._setMobileHelperText(false,'Please select mobile prefix');
            }else{
                mobileNumber.useNativeValidation = true;
                mobileNumber.valid = true;
                this._setMobileHelperText(true,'');
            }
        });

    }

    _setMobileHelperText(valid,message){
        //.mobile-number-input-group
        if(valid){
            //$(mobileNumber.root_).parents('.inputs-group').removeClass('mdc-text-field--invalid')
            ///$(mobileNumber.root_).parents('.inputs-group').siblings('.mdc-text-field-helper-line').children('.mdc-text-field-helper-text').html('')
            console.log("setting valid input for mobile",$('.mobile-number-input-group .mdc-text-field-helper-line .mdc-text-field-helper-text'));
            $('.mobile-number-input-group').removeClass('mdc-text-field--invalid');
            $('.mobile-number-helper-text .mdc-text-field-helper-text').html(message);
        }else{
            //$(mobileNumber.root_).parents('.inputs-group').siblings('.mdc-text-field-helper-line').children('.mdc-text-field-helper-text').html(APP_STRINGS.getStringById("462"))
            //$(mobileNumber.root_).parents('.inputs-group').addClass('mdc-text-field--invalid')

            $('.mobile-number-helper-text .mdc-text-field-helper-text').html(message)
            $('.mobile-number-input-group').addClass('mdc-text-field--invalid');

        }
    }

    onFormSubmitRegister(e){
        e.preventDefault();
        let inputData=FormHelpers.getFormDataAsObject(e.target);

        var mdcInput = this._getMDCInputFields();

        this.loading=true;
        render(TemplateRegisterShortSideView(this),this.element,{eventContext:this});

        //validation before send
        if(!this._validateRegistrationFields()){
            this.loading=false;
            this.updateView()
            return;
        };

        this._checkIfEmailExists();

    }

    _validateRegistrationFields(){
        let flag = true;

        let mdcInput = this._getMDCInputFields();


        let emailAddress=mdcInput.emailAddress;
        if(validator.isEmpty(emailAddress.value)){
            this._setErrorMessage(emailAddress, APP_STRINGS.getStringById("460"));
            flag = false;
        }

        if(!validator.isEmail(emailAddress.value)){
            this._setErrorMessage(emailAddress, APP_STRINGS.getStringById("460"));
            flag = false;
        }


        let password=mdcInput.password;
        let confirmPassword=mdcInput.confirmPassword;

        if(validator.isEmpty(password.value)){
            this._setErrorMessage(password, APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-Password-EmptyPassword'));
            flag = false;
        }

        var validatePasswordFormat = Validations.validatePassword(password.value)
        if(!validatePasswordFormat.valid){
            this._setErrorMessage(password, validatePasswordFormat.message);
            flag = false;
        }


        if(password.value != confirmPassword.value){
            this._setErrorMessage(confirmPassword, APP_STRINGS.getStringById("2221005"));
            flag = false;
        }


        let firstName=mdcInput.firstName;
        if(validator.isEmpty(firstName.value)){
            this._setErrorMessage(firstName, APP_STRINGS.getStringById("450"));
            flag = false;
        }

        if(!validator.isLength(firstName.value, {min: 2, max: 40})){
            this._setErrorMessage(firstName, APP_STRINGS.getStringById("450"));
            flag = false;
        }

        var reg = /^[^,./<>?;':"[\]{}\-=_+`~!@#$%^&*() ]*$/;
        if (!(reg.test(firstName.value))) {
            this._setErrorMessage(firstName, APP_STRINGS.getStringById("450"));
            flag = false;
        }

        let lastName=mdcInput.lastName;
        if(validator.isEmpty(lastName.value)){
            this._setErrorMessage(lastName, APP_STRINGS.getStringById("451"));
            flag = false;
        }

        if(!validator.isLength(lastName.value, {min: 2, max: 40})){
            this._setErrorMessage(lastName, APP_STRINGS.getStringById("451"));
            flag = false;
        }

        var reg = /^[^,./<>?;':"[\]{}\-=_+`~!@#$%^&*() ]*$/;
        if (!(reg.test(lastName.value))) {
            this._setErrorMessage(lastName, APP_STRINGS.getStringById("451"));
            flag = false;
        }

        let mobilePrefix=mdcInput.mobilePrefix;
        let mobileNumber=mdcInput.mobileNumber;
        if(validator.isEmpty(mobilePrefix.value)){
            mobilePrefix.useNativeValidation = false;
            mobilePrefix.valid = false;
            this._setMobileHelperText(false, "Please select mobile prefix");
            flag =  false;
        }else{
            //$(mobileNumber.root_).parents('.inputs-group').siblings('.mdc-text-field-helper-line').children('.mdc-text-field-helper-text').html("");

            if(!validator.isNumeric(mobileNumber.value) ||
                !validator.isLength(mobileNumber.value, {min: 6, max: 19})){
                mobileNumber.useNativeValidation = false;
                mobileNumber.valid = false;
                this._setMobileHelperText(false, APP_STRINGS.getStringById("462"));

                flag =  false;
            }else{
                mobileNumber.useNativeValidation = true;
                mobileNumber.valid = true;
                this._setMobileHelperText(true, "");
            }

        }

        var tnc = mdcInput.tncCheckBox;
        if(!tnc.checked){
            flag =  false;
            this._setErrorMessage(tnc, APP_STRINGS.getStringById("2221020"));
        }

        return flag;
    }

    _setErrorMessage(input, message){
        input.useNativeValidation = false;
        input.valid = false;
        input.helperTextContent = message;
    }

    _setValidInput(input, message = ''){
        input.useNativeValidation = true;
        input.valid = true;
        input.helperTextContent = message;
    }

    scrollFormToTop() {
        this.element.querySelector(".scrolling-content").scrollTop=0;
    }

    _checkIfEmailExists(){
        let inputField = this._getMDCInputFields();
        USER.checkEmailExist(inputField.emailAddress.value)
            .then(data=>{
                //2) check email
                if(typeof data.error == 'undefined' && !data.Exists){
                    this._runRecaptcha();
                }else{
                    //show error message data.error.errorMessage
                    this.loading=false;
                    this.updateView()
                    this._setErrorMessage(inputField.emailAddress, APP_STRINGS.getStringById(data.error.errorID))
                }
            });
    }

    _runRecaptcha(){
        var _register = this
        try{
            window.registerWidgetID = grecaptcha.render('g-recapture-register', {
                'sitekey' : SITE_KEY,
                'callback' : function(token){
                    return new Promise(function(resolve, reject) {
                        resolve();
                    }).then(callback => {
                        var recaptchaResponse = grecaptcha.getResponse(window.registerWidgetID)
                        _register.submitRegisterRequest(recaptchaResponse)
                    })
                }
            });
        }catch(error){
            // grecaptcha.reset(window.registerWidgetID)
        }

        grecaptcha.execute(window.registerWidgetID )
    }

    submitRegisterRequest(recaptchaResponse){
        var mdcInput = this._getMDCInputFields();
        let registerController = this;
        let data = {
            emailAddress: mdcInput.emailAddress.value,
            password: mdcInput.password.value,
            languageISO: LANGUAGE,
            activateAccount: true, // true or false where to obtain?
            loginOnSuccess: true, // true or false where to obtain?
            firstName: mdcInput.firstName.value,
            lastName: mdcInput.lastName.value,
            mobilePrefix: mdcInput.mobilePrefix.value,
            mobilePhone: mdcInput.mobileNumber.value,
            currentUrl: window.location.href,
            'g-recaptcha-response': recaptchaResponse
        }

        //call register API
        return USER.register(data)
            .then(data=>{
                if(data.error){
                    new Popup(
                        APP_STRINGS.getWPMLString('SideViewRegister-Alert-FailedRegistration-Title'),
                        `${APP_STRINGS.getWPMLString('SideViewRegister-Alert-FailedRegistration-Message')} Alert code  ${data.error.errorID}`,
                        [APP_STRINGS.OK])
                }else{
                    //redirect to register complete page

                    this.registrationDone=true;

                    new SlideOverViewStatus(
                        registerController.element,
                        SlideOverViewStatus.STATUS_VIEW_TYPE.SUCCESS,
                        APP_STRINGS.getWPMLString('SideViewRegister-SuccessView-Title'),
                        APP_STRINGS.getWPMLString('SideViewRegister-SuccessView-Message'),
                        [APP_STRINGS.getWPMLString('SideViewRegister-SuccessView-ButtonLabel-Continue')],(btnId)=>{
                            SIDE_VIEW_MANAGER.closeAnySideview();

                            if(data.requireLocationConfirmation){
                                let p=new Popup(
                                    APP_STRINGS.getWPMLString('SideViewRegister-Alert-LocationConfirmation-Title'),
                                    APP_STRINGS.getWPMLString('SideViewRegister-Alert-LocationConfirmation-Message'),
                                    [APP_STRINGS.getWPMLString('SideViewRegister-Alert-LocationConfirmation-ButtonLabel-No'),APP_STRINGS.getWPMLString('SideViewRegister-Alert-LocationConfirmation-ButtonLabel-Yes')],
                                    btnId=>{
                                        let answer= btnId==0 ? "no" : "yes";
                                        USER.registerConfirmLocation(data.ClientID, data.signature, answer)
                                            .then(resp=>{
                                                if(resp.error){
                                                    new Popup(resp.error.errorTitle, resp.error.errorMessage, ['OK'])
                                                }
                                            })
                                        if(answer=="yes"){
                                            this._login(mdcInput.emailAddress.value, mdcInput.password.value, data);
                                        }else{
                                            if(this.redirectAfterLoginURL!=null){
                                                document.location.href=this.redirectAfterLoginURL;
                                            }
                                        }
                                    });
                                p.dontUseCloseButton();
                            }else{
                                this._login(mdcInput.emailAddress.value, mdcInput.password.value, data);
                            }

                        });

                }
                registerController.loading=false;
                registerController.updateView();
                grecaptcha.reset(window.registerWidgetID);
            });
    }

    _login(email,password,loginData){
        USER.login(email,password,loginData).then(data=>{
            if(data.error){
                new Popup(
                    APP_STRINGS.getWPMLString('SideViewRegister-Alert-LoginError-Title'),
                    `${APP_STRINGS.getWPMLString('SideViewRegister-Alert-LoginError-Message')} Alert code  ${data.error.errorID}`,
                    [APP_STRINGS.getWPMLString('SideViewRegister-Alert-LoginError-ButtonLabel-Ok')]
                );
            }else if(this.redirectAfterLoginURL!=null){
                document.location.href=this.redirectAfterLoginURL;
            }

        })
    }

    onMenuItemClick(e){
        let action=e.currentTarget.dataset.action;
        switch (action) {
            case "user":
                new SideViewUser();
                break;
            case "logout":
                USER.logout();
                break;
            case "search":
                if(this.searchView){
                    return;
                }
                console.log("launch search control");
                this.searchView=new SearchView(()=>{this.searchView=null;});
                this.close();
                break;
            case "help":
                window.location.href = window.helpLink;
                break;
            case "faqs":
                try {
                    window.location.href=document.querySelector("input[name='faqs-link']").value;
                }catch(e){
                    console.log("No FAQs link found on page")
                }
                break;
            default:
                console.log("unknown action");
        }
    }
}

SideViewRegisterShort.ALIGNMENT=SideViewBaseClass.ALIGNMENT.LEFT;
import {html} from 'lit-html';
import {unsafeHTML} from "lit-html/directives/unsafe-html";
export const TemplatePopup = (el) => html`
    <div class="popup">
        <div class="header"><h4>${el.title}</h4><img @click="${el.onCloseButtonClick}" class="close-btn" src="${CDN_ASSETS_URL}/close.svg"></div>
        <div class="content">
            ${el.contentTemplate?el.contentTemplate(el):html`<p>${unsafeHTML(el.content)}</p>`}
        </div>

        <div class="popup-controls">
            ${el.buttons.map((item,index)=>html`
                <button data-button-id="${index}" @click=${el.onButtonClick} class="primary-button">${item}</button>
            `)}
        </div>

    </div>
    
`;
import {html} from 'lit-html';
import {APP_STRINGS} from "../../../../js/core/AppStrings";

export const TemplateRGGeneralLimits = (el) => html`
<div class="view-with-heading">
<div class="view-heading">
        <div class="item-left">
            <img class="back-img" src="${CDN_ASSETS_URL}/left.svg" @click=${el.onSlideOverBackButtonClick}>
            <span class="title">${el.limitTypeDisplayNamesList[el.selectedLimitTypeID]} ${APP_STRINGS.getWPMLString('SideViewUser-Limits-GeneralLimits-TitlePart-Limit')}</span>
        </div>
    </div>
    <div class="scrolling-content">
        <div class="content">
        <div class="capsule-tabs">
        <div class="capsule-tabs-inner capp-scrollbar">
            <div class="buttons-container">
                ${displayIntervalType(el)}
            </div>
        </div>
        </div>
        <form class="limits-form ${el.updateLimitLoading ? 'disabled' : ''}" @submit=${el.onSubmitLimitForm} data-limit-type="${el.selectedLimitTypeID}">
            <p class="progress-description">${(el.selectedIntervalAmount == null) ? APP_STRINGS.getWPMLString('SideViewUser-Limits-GeneralLimits-NoLimitsSet-Text') : el.selectedIntervalAmount }</p>
            <h4>Set ${el.intervalType[el.selectedIntervalTypeID].intervalDisplayName.toLowerCase()} ${el.limitTypeDisplayNamesList[el.selectedLimitTypeID].toLowerCase()} ${APP_STRINGS.getWPMLString('SideViewUser-Limits-GeneralLimits-TitlePart-Limit').toLowerCase()}</h4>
            <div class="form-element-2">
                <label class="tf1 mdc-text-field mdc-text-field--with-leading-icon mdc-limit-amount">
                    <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id">
                    <i class="material-icons mdc-text-field__icon mdc-text-field__icon--leading icon-${el.userData.CurrencyISO}"></i>
                    <span class="mdc-floating-label" id="my-label-id">${el.limitTypeDisplayNamesList[el.selectedLimitTypeID]} ${APP_STRINGS.getWPMLString('SideViewUser-Limits-GeneralLimits-LimitsForm-InputPlaceholder-Amount')}</span>

                    <div class="mdc-line-ripple"></div>
                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent" id="my-helper-id" aria-hidden="true">
                        </div>
                    </div>
                </label>
            </div>
            <div class="form-element-2 favourite-amount">
                <ul>
                    ${el.presetAmount.map((value,index)=>
    html`<li class="${el.selectedPresetAmountID==index ? `selected` : '' }" @click=${el.onClickPresetAmount} data-preset-id="${index}" data-type="general"><span>${el.userData.currencySymbol}${value}</span></li>`
)}
                </ul>
            </div>
            <div class="form-element-2 note">
                <img class="back-img" src="${CDN_ASSETS_URL}/info.svg">
                <p>${APP_STRINGS.getWPMLString('SideViewUser-Limits-GeneralLimits-LimitsForm-AdditionalInfo-Text')}</p>
            </div>
            <div class="form-element-2">
                <button class="primary-button ${el.updateLimitLoading ? 'loading' : ''}"><span>${APP_STRINGS.getWPMLString('SideViewUser-Limits-GeneralLimits-LimitsForm-ButtonLabelPart-Update')} ${el.limitTypeDisplayNamesList[el.selectedLimitTypeID]} ${APP_STRINGS.getWPMLString('SideViewUser-Limits-GeneralLimits-TitlePart-Limit')}</span><preloader-element></preloader-element></button>
            </div>
        </form>

        </div>
    </div>
    
</div>
    
`

function displayIntervalType(el){
    const displayList = [];
    for (const [key, value] of Object.entries(el.intervalType)) {
        if(value.id == 0){
            if(el.selectedLimitTypeID == 0 || el.selectedLimitTypeID == 2){
                //deposit and wager
                displayList.push(
                    html `<button class="tab-capsule ${(el.selectedIntervalTypeID == key) ? 'active' : ''}" @click=${el.onClickIntervalType} data-interval-id="${value.id}">
                            ${value.intervalDisplayName}
                        </button>`
                )
            }
        }else if(value.id == 5){
            if(el.selectedLimitTypeID == 1 || el.selectedLimitTypeID == 2){
                //loss and wager
                displayList.push(
                    html `<button class="tab-capsule ${(el.selectedIntervalTypeID == key) ? 'active' : ''}" @click=${el.onClickIntervalType} data-interval-id="${value.id}">${value.intervalDisplayName}</button>`
                )
            }
        }else{
            displayList.push(
                html `<button class="tab-capsule ${(el.selectedIntervalTypeID == key) ? 'active' : ''}" @click=${el.onClickIntervalType} data-interval-id="${value.id}">${value.intervalDisplayName}</button>`
            )
        }
        
    }
    return displayList;
}
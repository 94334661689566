import {render} from "lit-html";
import {FormHelpers} from "../../../util/FormHelpers";
import {TemplateHistoryContentView} from "../../../../templates/template-items/side-view/TemplateHistoryContentView";
import {SideViewSubBaseClass} from "../../SideViewSubBaseClass";
import {Helpers} from "@/js/util/Helpers";

export class SideViewSubHistory extends SideViewSubBaseClass{
    constructor(container){
        super(container);
    }

    _init() {
        var today = new Date();
        var customEndDate = (Helpers.isLastDay(today)) ? 1 : today.getDate() + 1;
        var customEndMonth = (Helpers.isLastDay(today)) ? today.getMonth()+2 : today.getMonth()+1;
        var customEndYear = today.getFullYear();

        //To overcome the issue of the last day of the year: YYYY-12-31
        if(customEndMonth == 13){
            customEndMonth = 1;
            customEndYear = customEndYear+1;
        }

        this.transactionType = {
            transaction : {
                displayName: APP_STRINGS.getWPMLString('SideViewBank-History-HistoryTypeMenu-MenuItem-Transactions'),
                defaultFilter: 'day',
            },
            gamePlay : {
                displayName: APP_STRINGS.getWPMLString('SideViewBank-History-HistoryTypeMenu-MenuItem-GamePlay'),
                defaultFilter: 'day',
            },
            bonus : {
                displayName: APP_STRINGS.getWPMLString('SideViewBank-History-HistoryTypeMenu-MenuItem-Bonuses'),
                defaultFilter: 'day',
            },
            //tentatively hide races and shop
            // races : {
            //     displayName: 'Races',
            //     defaultFilter: 'day',
            // },
            // shop: {
            //     displayName: 'Shop',
            //     defaultFilter: 'day',
            //     subMenu: [
            //         'Points',
            //         'Purchases'
            //     ]
            // }
        }
        this.presetFilterDate = {
            day : {
                displayName : APP_STRINGS.getWPMLString('SideViewBank-History-DateRangePresets-PresetItem-Day'),
                startDate : Helpers.formatDate(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), true),
                endDate : Helpers.formatDate(customEndYear+'-'+(customEndMonth)+'-'+(customEndDate), true),
            },
            week : {
                displayName : APP_STRINGS.getWPMLString('SideViewBank-History-DateRangePresets-PresetItem-Week'),
                startDate : Helpers.formatDate(new Date(today.getFullYear(), today.getMonth(), (today.getDate() - 7)), true),
                endDate : Helpers.formatDate(customEndYear+'-'+(customEndMonth)+'-'+(customEndDate), true),
            },
            fortnight : {
                displayName : APP_STRINGS.getWPMLString('SideViewBank-History-DateRangePresets-PresetItem-Fortnight'),
                startDate : Helpers.formatDate(new Date(today.getFullYear(), today.getMonth(), (today.getDate() - 14)), true),
                endDate : Helpers.formatDate(customEndYear+'-'+(customEndMonth)+'-'+(customEndDate), true),
            },
            month: {
                displayName : APP_STRINGS.getWPMLString('SideViewBank-History-DateRangePresets-PresetItem-Month'),
                startDate : Helpers.formatDate(new Date(today.getFullYear(), today.getMonth(), (today.getDate() - 30)), true),
                endDate : Helpers.formatDate(customEndYear+'-'+(customEndMonth)+'-'+(customEndDate), true),
            },
            custom: {
                displayName : APP_STRINGS.getWPMLString('SideViewBank-History-DateRangePresets-PresetItem-Custom'),
                startDate : Helpers.formatDate(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(), true),
                endDate : Helpers.formatDate(customEndYear+'-'+(customEndMonth)+'-'+(customEndDate), true),
            }
        }
        this.selectedTransaction = 'transaction' //set default transaction type
        this.selectedFilterDateKey = this.transactionType[this.selectedTransaction].defaultFilter //set default filter of transaction
        this.isShowCustomBox = false;
        this.loadingHistoryData = true;
        this.historyData = {
            transactionData : [],
            gamePlayData: [],
            bonusData: [],
            racesData: [],
            shopData:{
                points: [],
                purchases: []
            }
        }
        this.selectedShopSubMenu = 0;
        super._init();
        
    }

    renderView(){
        this.loadingHistoryData = true
        this.updateView()
        this.triggerGetTransactionData()
        FormHelpers.initializeFormComponents(this.element);
        var today = new Date();
        window.flatpickr($('.start-date-input'),{
            maxDate: today,
            dateFormat: "Y-m-d",
        })
        window.flatpickr($('.end-date-input'),{
            maxDate: today,
            dateFormat: "Y-m-d",
        })
    }

    updateView(){
        render(TemplateHistoryContentView(this),this.element,{eventContext:this});
    }

    _getMDCInputFields(){
        var mdcInput = {
            startDate: document.querySelector(".mdc-start-date").MDCTextField,
        }
        if(document.querySelector(".mdc-end-date")){
            mdcInput.endDate = document.querySelector(".mdc-end-date").MDCTextField
        }

        return mdcInput
    }

    onToggleCustomBox(e){
        e.preventDefault()
        this.isShowCustomBox = !this.isShowCustomBox
        this.selectedFilterDateKey = 'custom'
        this.updateView()
        FormHelpers.initializeFormComponents(this.element);
        var today = new Date();
        window.flatpickr($('.start-date-input'),{
            maxDate: today,
            dateFormat: "Y-m-d",
        })
        window.flatpickr($('.end-date-input'),{
            maxDate: today,
            dateFormat: "Y-m-d",
        })
    }

    onClickTransactionType(e){
        e.preventDefault()
        let selectedTransaction = e.currentTarget.dataset.transactionKey
        this.selectedTransaction = selectedTransaction
        this.selectedFilterDateKey = this.transactionType[selectedTransaction].defaultFilter
        this.isShowCustomBox = false;
        this.loadingHistoryData = true
        this.triggerGetTransactionData()
        this.updateView()
        
    }

    onClickPresetDate(e){
        e.preventDefault()
        let selectedFilterDateKey = e.currentTarget.dataset.presetDateKey
        if(selectedFilterDateKey != 'custom'){
            this.isShowCustomBox = false;
        }
        this.selectedFilterDateKey = selectedFilterDateKey
        this.loadingHistoryData = true
        this.updateView()
        this.triggerGetTransactionData()
    }

    onClickCustomFilter(e){
        e.preventDefault()
        var input = this._getMDCInputFields()
        this.presetFilterDate[this.selectedFilterDateKey].startDate = Helpers.formatDate(input.startDate.value, true)
        if(input.endDate){
            this.presetFilterDate[this.selectedFilterDateKey].endDate = Helpers.formatDate(input.endDate.value, true)
        }
        this.loadingHistoryData = true
        this.updateView()
        this.triggerGetTransactionData()
    }

    onToggleShopSubMenu(e){
        e.preventDefault()
        this.selectedShopSubMenu = parseInt(e.currentTarget.dataset.subMenuKey)
        this.updateView()
    }

    triggerGetTransactionData(){
        let startDate = ''
        let endDate = ''
        let controller = this

        startDate = this.presetFilterDate[this.selectedFilterDateKey].startDate
        endDate = this.presetFilterDate[this.selectedFilterDateKey].endDate

        switch(this.selectedTransaction){
            case 'transaction':
                USER.getAccountTransactions(startDate, endDate).then(data => {
                    controller.historyData.transactionData = data
                    controller.loadingHistoryData = false
                    controller.updateView()
                })
            break;
            case 'gamePlay':
                USER.getPlayerGamePlayHistory(startDate, endDate, 200, 1).then( data => {
                    controller.historyData.gamePlayData = data
                    controller.loadingHistoryData = false
                    controller.updateView()
                })
            break;
            case 'bonus':
                USER.getPlayerBonusHistory(startDate, 1).then(data => {
                    controller.historyData.bonusData = data
                    controller.loadingHistoryData = false
                    controller.updateView()
                })
            break;
            case 'races': 
                USER.getPlayerRacesHistory(startDate, 1).then(data => {
                    controller.historyData.racesData = data
                    controller.loadingHistoryData = false
                    controller.updateView()
                })
            break;
            case 'shop':
                USER.getPlayerShopHistory(startDate).then(data => {
                    controller.historyData.shopData.points = data.pointsHistoryList
                    controller.historyData.shopData.purchases = data.purchasesHistoryList
                    controller.loadingHistoryData = false
                    controller.updateView()
                })
            break;
            default:
                console.log('no such transaction type '+this.selectedTransaction)
            break;
        }
        
        
    }
}
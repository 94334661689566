import {BankFormBase} from "./BankFormBase";
import {Template_BankTransfer_Withdrawal} from "../../../../../templates/template-items/side-view/bank-paymetns-forms/Template_BankTransfer_Withdrawal";
import {BANK} from "../../../../core/Bank";
import {Helpers} from "../../../../util/Helpers";

export class BankFormDirectBankTransfer extends BankFormBase{
    constructor(container, country, existingAccounts, callback) {
        super(container, callback);
        this.country=country;
        this.existingAccounts=existingAccounts;

        this._init();
    }

    _getFormTemplate() {
        return Template_BankTransfer_Withdrawal;
    }


    _validateInput(input){
        if(input.root_.dataset.field=="bankSwiftCode" || input.root_.dataset.field=="bankAddress"){
            return this._validateSwiftField();
        }else{
            return super._validateInput(input);
        }
    }

    _validateSwiftField(){
        let valid=true;
        let switftInput=null;
        let bankAddressInput=null;
        this.formInputs.forEach(input=>{
            console.log(input.root_.dataset.field);
            if(input.root_.dataset.field=="bankSwiftCode"){
                switftInput=input;
            }
            if(input.root_.dataset.field=="bankAddress"){
                bankAddressInput=input;
            }
        })

        if(switftInput.value.length==0 && bankAddressInput.value.length==0){
            valid=false;
        }
        this._setInputState(switftInput,valid);

        return valid;
    }

    _applyExistingAccount(input){
        if(input.value=="new-account"){
            this.formInputs.forEach(input=>{
                //todo we might need to rething this for other bank forms, perhaps create reset object in bank model
                if(input.root_.dataset.field!="" && input.root_.dataset.field!="existingAccount" && input.root_.dataset.field!="bankCountry"){
                    input.value="";
                    input.foundation_.adapter_.floatLabel(false)
                }

            })
        }else{
            let account=Helpers.findItemInArray(this.existingAccounts,"custom_attribute_key", input.value);
            this.formInputs.forEach(input=>{
                if(account[input.root_.dataset.field]){
                    input.value=account[input.root_.dataset.field];
                    input.foundation_.adapter_.floatLabel(true)
                }
            })
        }
    }


}


import {USER} from "../core/User";
import {Popup} from "./Popup";
import {PreloaderOverlayPopup} from "./PreloaderOverlayPopup";

export class AccountActivationProcessorPopup extends PreloaderOverlayPopup{

    constructor(activateCode,ea) {
        super();
        this.activateCode = activateCode;
        this.ea=ea;
        this.loading = true;
        this._init();
    }

    _init(){
        
        this.preloader = new PreloaderOverlayPopup();
        this.processActivation();

    }

    processActivation(){
       
        if(this.loading ==true){
            this.preloader.createPreloaderPopup();
        }
        
        if (this.activateCode &&  this.activateCode) {
            USER.activateAccount(this.activateCode, this.activateCode).then(data=>{
                this.loading =false;
                this.preloader.removePreloaderPopup();
                if(data && data.ActivationSuccess==true){
                    new Popup(APP_STRINGS.getTitleById("2221019"),APP_STRINGS.getStringById('2221019'),[APP_STRINGS.OK]);
                    //WHY????????
                    //window.location.href = window.location.origin

                }else{
                    new Popup(APP_STRINGS.ERROR,APP_STRINGS.getStringById('2220052'),[APP_STRINGS.OK]);
                }
                
            })
        }
    }

}
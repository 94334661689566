import {html} from 'lit-html';
import {APP_STRINGS} from "../../../../js/core/AppStrings";

export const TemplateUserPreferences = (el) => html`
<div id="user-preferences">   
    <div class="content">
        <div class="preferences-container">
            <h4>${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-GeneralPreferencesSection-Title')}</h4>
            <preloader-element class="primary" style="width: 100%; height: 5em; display: ${el.loading ? 'flex' : 'none'};"></preloader-element>
            <div class="details" style="display: ${el.loading ? 'none' : 'grid'  };">
                <div class="item">
                    <div class="field">
                        <span>${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-GeneralPreferencesSection-FieldLabel-Password')}</span>
                        <button @click=${el.onPreferenceItemClick} data-action="changePassword">
                            ${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-GeneralPreferencesSection-ButtonLabel-Change')}
                        </button>
                    </div>
                    <div class="data">
                        <span>••••••••</span>
                    </div>
                </div>
                <div class="item">
                    <div class="field">
                        <span>${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-GeneralPreferencesSection-FieldLabel-Address')}</span>
                        <button @click=${el.onPreferenceItemClick} data-action="changeAddress">
                            ${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-GeneralPreferencesSection-ButtonLabel-Change')}
                        </button>
                    </div>
                    <div class="data">
                        <span>${ getUserAddress(el.userData.Address1, 'address1') }</span>
                        ${ getUserAddress(el.userData.Address1, 'address2') }
                        <span>${el.userData.CountryName}</span>
                        <span>${el.userData.PostCode}</span>
                    </div>
                </div>
                <div class="item">
                    <div class="field">
                        <span>${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-GeneralPreferencesSection-FieldLabel-PhoneNumber')}</span>
                        <button @click=${el.onPreferenceItemClick} data-action="changePhoneNumber">
                            ${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-GeneralPreferencesSection-ButtonLabel-Change')}
                        </button>
                    </div>
                    <div class="data">
                        <span>${ getUserPhoneNumber(el) }</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="preferences-container">
            <h4><span>${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ContactPreferencesSection-Title')}</span><preloader-element class="primary" style="display: ${el.loadingPromotionSwitch ? 'flex' : 'none'};"></preloader-element></h4>
            <form id="contact-preferencies-form">

                <div class="form-element-2">
                    <div class="mdc-form-field full-width">
                        <label for="post-switch">${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ContactPreferencesSection-FieldLabel-Post')}</label>

                        <div class="mdc-switch">
                            <div class="mdc-switch__track"></div>
                            <div class="mdc-switch__thumb-underlay">
                                <div class="mdc-switch__thumb"></div>
                                <input type="checkbox" id="post-switch" class="mdc-switch__native-control"
                                    role="switch"
                                    aria-checked="${el.userData.ContactByPhone}">
                            </div>
                        </div>

                    </div>
                </div>
                <div class="form-element-2">
                    <div class="mdc-form-field full-width">
                        <label for="email-switch">${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ContactPreferencesSection-FieldLabel-Email')}</label>

                        <div class="mdc-switch">
                            <div class="mdc-switch__track"></div>
                            <div class="mdc-switch__thumb-underlay">
                                <div class="mdc-switch__thumb"></div>
                                <input type="checkbox" id="email-switch" class="mdc-switch__native-control"
                                    role="switch"
                                    aria-checked="${el.userData.ContactByEmail}">
                            </div>
                        </div>

                    </div>
                </div>
                <div class="form-element-2">
                    <div class="mdc-form-field full-width">
                        <label for="sms-switch">${APP_STRINGS.getWPMLString('SideViewUser-UserPreferences-ContactPreferencesSection-FieldLabel-SMS')}</label>

                        <div class="mdc-switch">
                            <div class="mdc-switch__track"></div>
                            <div class="mdc-switch__thumb-underlay">
                                <div class="mdc-switch__thumb"></div>
                                <input type="checkbox" id="sms-switch" class="mdc-switch__native-control"
                                    role="switch"
                                    aria-checked="${el.userData.ContactBySMS}">
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
        
`

function getUserAddress(address, type){
    var addresses = address.split(";");
    if(type == 'address1'){
        return html `${addresses[0]}`
    }else if(type == 'address2'){
        return html `<span>${addresses[1]}</span>`
    }
}

function getUserPhoneNumber(el){
    return (el.userData.MobilePhone == null) ? '-' : el.userData.MobilePhone
}
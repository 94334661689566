import {html} from 'lit-html';
import {APP_STRINGS} from "../../../js/core/AppStrings";

export const TemplateRegisterShortSideView = (el) => html`
    <div class="view-heading">
        <div class="item-left">
            <span class="title">${APP_STRINGS.getWPMLString('SideViewRegister-Title')}</span>
        </div>
        <div class="item-right">
            <img class="close-img" src="${CDN_ASSETS_URL}/close.svg" @click=${el.close}>
        </div>
    </div>
    <div class="scrolling-content">
        <div class="content">
        <preloader-element class="primary" style="width: 100%; height: 5em; display: ${el.loadingRegisterInfo ? 'flex' : 'none'};" ></preloader-element>
        
        <form id="register-form" class="${el.registrationDone?'reigistration-done':''}" @submit=${el.onFormSubmitRegister} autocomplete="off" style="display: ${el.loadingRegisterInfo ? 'none' : 'block'  };">
            <div class="step-1" style="display: block;">
                <div class="form-element-2">
                    <label class="mdc-text-field mdc-email-address">
                        <div class="mdc-text-field__ripple"></div>
                        <input class="mdc-text-field__input email-address" type="text" aria-labelledby="my-label-id" name="emailAddress" spellcheck="false">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Email')}
                    </span>

                        <div class="mdc-line-ripple"></div>
                    </label>

                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent" id="my-helper-id" aria-hidden="true">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-Email')}
                        </div>
                    </div>
                </div>

                <div class="form-element-2">
                    <label class="mdc-text-field mdc-password">
                        <div class="mdc-text-field__ripple"></div>
                        <input class="mdc-text-field__input" autocomplete="new-password" type="password" aria-labelledby="my-label-id" name="password">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Password')}
                    </span>

                        <div class="mdc-line-ripple"></div>
                    </label>

                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent" id="my-helper-id" aria-hidden="true">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-Password')}
                        </div>
                    </div>
                </div>

                <div class="form-element-2">
                    <label class="mdc-text-field mdc-confirm-password">
                        <div class="mdc-text-field__ripple"></div>
                        <input class="mdc-text-field__input" type="password" autocomplete="new-password" aria-labelledby="my-label-id">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-PasswordRepeat')}
                    </span>

                        <div class="mdc-line-ripple"></div>
                    </label>

                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent" id="my-helper-id" aria-hidden="true">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-PasswordRepeat')}
                        </div>
                    </div>
                </div>

                <div class="form-element-2">
                    <label class="mdc-text-field mdc-first-name">
                        <div class="mdc-text-field__ripple"></div>
                        <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="firstName">
                        <span class="mdc-floating-label" id="my-label-id">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-FirstName')}
                        </span>

                        <div class="mdc-line-ripple"></div>
                    </label>
                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
                             id="my-helper-id" aria-hidden="true">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-FirstName')}
                        </div>
                    </div>

                </div>

                <div class="form-element-2">
                    <label class="mdc-text-field mdc-last-name">
                        <div class="mdc-text-field__ripple"></div>
                        <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="lastName">
                        <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-LastName')}
                    </span>

                        <div class="mdc-line-ripple"></div>
                    </label>

                    <div class="mdc-text-field-helper-line">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
                             id="my-helper-id" aria-hidden="true">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-LastName')}
                        </div>
                    </div>
                </div>

                <div class="form-element-2">
                    <div class="inputs-group mobile-number-input-group">
                        <div class="mdc-select mdc-mobile-prefix mdc-select--required">
                            <div class="mdc-select__anchor" aria-required="true">
                                <i class="mdc-select__dropdown-icon"></i>

                                <div id="demo-selected-text" class="mdc-select__selected-text" role="button"
                                     aria-haspopup="listbox"
                                     aria-labelledby="demo-label demo-selected-text"></div>
                                <span id="demo-label"
                                      class="mdc-floating-label mdc-floating-label--float-above">
                                ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-PhoneNumberPrefix')}
                            </span>

                                <div class="mdc-line-ripple"></div>
                            </div>

                            <div class="mdc-select__menu mdc-menu mdc-menu-surface" role="listbox">
                                <ul class="mdc-list">
                                    ${getMobilePrefixList(el)}
                                </ul>
                            </div>
                        </div>

                        <label class="mdc-text-field mdc-mobile-number">
                            <div class="mdc-text-field__ripple"></div>
                            <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="mobilePhone">
                            <span class="mdc-floating-label" id="my-label-id">
                                ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-PhoneNumber')}
                            </span>

                            <div class="mdc-line-ripple"></div>
                        </label>
                    </div>

                    <div class="mdc-text-field-helper-line mobile-number-helper-text">
                        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
                             id="my-helper-id" aria-hidden="true">
                            ${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-PhoneNumber')}
                        </div>
                    </div>

                </div>

                <div class="form-element-2">
                    <div class="mdc-form-field">
                        <div class="mdc-checkbox mdc-tc-checkbox">
                            <input type="checkbox"
                                    class="mdc-checkbox__native-control"
                                    id="checkbox-1" name="t&c"/>

                            <div class="mdc-checkbox__background">
                                <img class="mdc-checkbox__checkmark" src="${CDN_ASSETS_URL}/single-pp.svg">
                                <!--<svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                                    <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                                </svg>-->
                                <div class="mdc-checkbox__mixedmark"></div>
                            </div>
                            <div class="mdc-checkbox__ripple"></div>
                        </div>
                        <div class="mdc-text-field-helper-line">
                            <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent" id="my-helper-id" aria-hidden="true">
                            </div>
                        </div>
                        <label class="terms-label" for="checkbox-1">${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-TermsAndConditions-Iagree-Text')}&nbsp;</label>
                        <a class="Text-Link-Small terms-link" href="${window.tncLink}" target="_blank">${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-TermsAndConditions-LinkLabel')}</a>
                    </div>
                </div>
                

            </div>
            
            <div class="register-buttons">
                <div id="g-recapture-register" class="g-recaptcha" data-sitekey="${SITE_KEY}" data-size="invisible" data-callback="onSubmit"></div>
                <button class="primary-button next-3 ${el.loading ? 'loading' : ''}" @click=${el.onSubmitRegister} style="display:block;">
                    <span>${APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-ButtonLabel-RegisterNewAccount')}</span>
                    <preloader-element></preloader-element>
                </button>
            </div>

            </form>
        </div>
    </div>
    
`


function getMobilePrefixList(el){
    if(Object.keys(el.registerInfo).length > 0){
        var prefixList = el.registerInfo.countryCodeInfo.DialingCodes
        const displayList = [];
        for (const [key, value] of Object.entries(prefixList)) {
            displayList.push(
                html`
                    <li class="mdc-list-item" data-value="${value}" role="option">
                        +${value}
                    </li>
                `
            ) 
        }
        return displayList
    }
}
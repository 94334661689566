import {html} from 'lit-html';
import {unsafeHTML} from "lit-html/directives/unsafe-html";
import {BANK} from "../../../js/core/Bank";
import {Helpers} from "@/js/util/Helpers";
import {APP_STRINGS} from "../../../js/core/AppStrings";

export const TemplateDepositContentView = (el) => html`
<div id="deposit">
    <div class="content">
        <preloader-element class="primary" style="width: 100%; height: 5em; display: ${el.loadingDepositData ? 'flex' : 'none'};" ></preloader-element>
            <form class="deposit-container" @submit=${el.onFormSubmit} style="display: ${el.loadingDepositData ? 'none' : 'block'  };" autocomplete="off">
                <div class="title-container">
                    <span class="title Heading-4">${APP_STRINGS.getWPMLString('SideViewBank-Deposit-AmountSection-Title')}</span>
                </div>
                <section class="amount-container">
                    <div class="form-element-2">
                        <label class="tf4 mdc-text-field mdc-text-field--with-leading-icon" id="depositAmountMDC">
                            <input class="mdc-text-field__input" type="number" aria-labelledby="deposit-amount-label" name="depositAmount"  >
                            <i class="material-icons mdc-text-field__icon mdc-text-field__icon--leading icon-${el.currencyISO}"></i>
                            <span class="mdc-floating-label" id="deposit-amount-label">${APP_STRINGS.getWPMLString('SideViewBank-Deposit-AmountSection-AmountInput-Label')}</span>
                
                            <div class="mdc-line-ripple"></div>
                        </label>
                
                    </div>
                    <div class="favourite-amount">
                        <ul>
                            ${el.presetValues.map((presetValue,index)=>
                                    html`<li @click=${el.onDepositPresetClick} data-preset-id="${index}" class="${el.selectedPreset==index ? `selected` : '' }"><span>${el.currencySymbol}${Helpers.formatNumber(presetValue)}</span></li>`
                            )}
                        </ul>
                    </div>
                </section>
                <div class="title-container">
                    <span class="title Heading-4">${APP_STRINGS.getWPMLString('SideViewBank-Deposit-BonusSection-Title')}</span>
                </div>
                <section class="radio-items-section bonuses-section">
                    ${el.depositData ? getBonusItems(el):''}
                </section>
                <div class="title-container">
                    <span class="title Heading-4">${APP_STRINGS.getWPMLString('SideViewBank-Deposit-SummarySection-Title')}</span>
                </div>
                <section class="summary-section">
                    <ul>
                        <li class="item">
                            <label>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-SummarySection-FieldLabel-DepositAmount')}</label>
                            <span class="Heading-4">${`${el.currencySymbol}${Helpers.formatNumber(el.BANK.depositSettings.depositAmount)}`}</span>
                        </li>
                        <li class="item">
                            <label>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-SummarySection-FieldLabel-BonusAmount')}</label>
                            <span class="Heading-4">${`${el.currencySymbol}${Helpers.formatNumber(el.BANK.depositSettings.bonusAmount)}`}</span>
                        </li>
                        <li class="item">
                            <label>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-SummarySection-FieldLabel-PlayableAmount')}</label>
                            <span class="Heading-4">${`${el.currencySymbol}${Helpers.formatNumber(el.BANK.depositSettings.payableAmount)}`}</span>
                        </li>
                        <li class="item">
                            <button @click=${el.onNextButtonClick} type="button" class="primary-button" ?disabled=${el.BANK.depositSettings.depositAmount==0 || el.specialSelect==-2}>
                                ${APP_STRINGS.getWPMLString('SideViewBank-Deposit-SummarySection-ButtonLabel-Next')}
                            </button>
                        </li>
                    </ul>
                </section>
            </form>

    </div>
</div>
`;

export const TemplateDepositAccountActivationView = (el) => html`
<div id="deposit-verification">
    <div class="content">
        <div class="verification-container">
            <ol class="status-message-container">
                <li>
                    <span class="Heading-3">${APP_STRINGS.getWPMLString('SideViewBank-Deposit-AccountNotActivatedSection-Title')}</span>
                </li>
                <li class="status-desc">
                    <span>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-AccountNotActivatedSection-InfoText-Part1')}</span>
                </li>
                <li class="status-desc">
                    <span>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-AccountNotActivatedSection-InfoText-Part2')}</span>
                </li>
                <li>
                    <button class="primary-button ${el.verificationEmailLoading ? 'loading' : ''}" @click=${el.onAccountActivationEmailButtonClick}>
                        <span>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-AccountNotActivatedSection-ButtonLabel-ResendEmail')}</span>
                        <preloader-element></preloader-element>
                    </button>
                </li>
            </ol>
        </div>
    </div>
</div>
`

function getBonusItems(el){
    let bonusItems=[];
    el.depositData.depositBonuses.forEach(bonusItem=>{
        let expanded=el.expandedBonusItems.indexOf(bonusItem.BaseRule.BonusRuleID)<0 ? false : true;
        let selected=el.BANK.depositSettings.selectedBonus && el.BANK.depositSettings.selectedBonus.BaseRule.BonusRuleID == bonusItem.BaseRule.BonusRuleID;
        let bonusTemplate=html`
            <div class="radio-box-2  ${bonusItem.unavaliable ? 'dtl' : ''} ${ selected ? 'selected' : ''} " data-bonus-id="${bonusItem.BaseRule.BonusRuleID}" @click=${el.onBonusItemSelect}>
                        <label class="form-element">
                            <input type="radio" name="bonus-type" value="${bonusItem.BaseRule.BonusRuleID}"  .checked=${selected}>
                            <span class="Heading-5">${bonusItem.bonusTitleTag}</span>
                        </label>
                        <div class="rb-content">
                            <div class="rb-heading">
                                <span class="Paragraph-Small">${bonusItem.bonusBrief}</span>
                            </div>
                            <div class="rb-info ${expanded ? 'show' : ''}">
                                <div class="info-list">
                                    <label>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-BonusSection-BonusItem-FieldLabel-BonusType')}</label> 
                                    <span class="Paragraph-Small-Bold">${bonusItem.cappBonusTypeName}</span>
                                    ${bonusItem.IsPercentage == true ?
                                        html`<label>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-BonusSection-BonusItem-FieldLabel-MatchAmount')}</label> 
                                            <span class="Paragraph-Small-Bold">${bonusItem.PercentageDisplay}</span>`
                                        :
                                        html`<label>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-BonusSection-BonusItem-FieldLabel-BonusAmount')}</label> 
                                            <span class="Paragraph-Small-Bold">${bonusItem.BonusFixedAmountDisplay}</span>`
                                    }
                                    
                                    <label>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-BonusSection-BonusItem-FieldLabel-MaxBonusAmount')}</label> 
                                    <span class="Paragraph-Small-Bold">${bonusItem.BonusPercentageMaxAmountDisplay}</span>
                                    
                                    <label>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-BonusSection-BonusItem-FieldLabel-MinimumDeposit')}</label> 
                                    <span class="Paragraph-Small-Bold">${bonusItem.BonusMinDepositAmountDisplay}</span>
                                    
                                    <label>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-BonusSection-BonusItem-FieldLabel-WageringRequirement')}</label> 
                                    <span class="Paragraph-Small-Bold">${bonusItem.WagerRequirementMultiplierDisplay}</span>
                                    
                                    <label>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-BonusSection-BonusItem-FieldLabel-MaxBet')}</label> 
                                    <span class="Paragraph-Small-Bold">${bonusItem.cappMaxBetDisplay}</span>
                                    
                                    <label>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-BonusSection-BonusItem-FieldLabel-ExpiryDate')}</label> 
                                    <span class="Paragraph-Small-Bold">${bonusItem.ExpiryDateDisplay}</span>
                                    
                                    <label>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-BonusSection-BonusItem-FieldLabel-ValidGames')}</label> 
                                    <span class="Paragraph-Small-Bold games-item">${bonusItem.cappGames}</span>
                                    
                                </div>
                            </div>
                            <button class="Text-Link-Small info-ctrl-btn" type="button" @click=${el.onBonusDetailsClick} data-bonus-id="${bonusItem.BaseRule.BonusRuleID}">
                                <span>${expanded ? APP_STRINGS.getWPMLString('SideViewBank-Deposit-BonusSection-BonusItem-ButtonLabel-Collapse') : APP_STRINGS.getWPMLString('SideViewBank-Deposit-BonusSection-BonusItem-ButtonLabel-Expand')}</span>
                                <img src="${CDN_ASSETS_URL}/${expanded ? 'up.svg' : 'down.svg'}">
                            </button>
                        </div>
                    </div>
        `;
        bonusItems.push(bonusTemplate);
    });

    if(!el.BANK.depositSettings.bonusCodeUsed){
        let selected=el.specialSelect==-2 ? true : false;

        bonusItems.push(html`
        <div class="radio-box-2 bonus-code-item ${selected?'selected':''}" @click=${el.onBonusItemSelect} data-bonus-id="-2">
            <label class="form-element">
                <input type="radio" name="bonus-type" .checked=${selected}  value="-2" >
                <span class="Heading-5">${APP_STRINGS.getWPMLString('SideViewBank-Deposit-BonusSection-BonusItem-BonusCodeItem-Title')}</span>
            </label>
            <div class="rb-content">
                ${el.bonusCodeLoading ? html`<preloader-element></preloader-element>` : ''}
                <div class="form-element-2">
                    <label class="mdc-text-field" id="bonus-code-input-MDC">
                        <div class="mdc-text-field__ripple"></div>
                        <input ?disabled=${el.bonusCodeLoading} class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" spellcheck="false">
                        <span class="mdc-floating-label" id="my-label-id">
                            ${APP_STRINGS.getWPMLString('SideViewBank-Deposit-BonusSection-BonusItem-BonusCodeItem-BonusCodeInput-Label')}
                        </span>
                        <div class="mdc-line-ripple"></div>
                    </label>
                </div>
                ${selected ? html`
                    <div class="bonus-code-instruction">${APP_STRINGS.getWPMLString('SideViewBank-Deposit-BonusSection-BonusItem-BonusCodeItem-HelperText')}</div>
                `:''}
                
            </div>
        </div>
    `);
    }


    let noBOnuseSelected=el.specialSelect==-1 ? true : false;
    bonusItems.push(html`

        <div class="radio-box-2 ${noBOnuseSelected ? 'selected':''}" @click=${el.onBonusItemSelect} data-bonus-id="-1">
            <label class="form-element">
                <input type="radio" name="bonus-type"  value="-1" .checked=${noBOnuseSelected}>
                <span class="Heading-5">${APP_STRINGS.getWPMLString('SideViewBank-Deposit-BonusSection-BonusItem-NoBonusItem-Title')}</span>
             </label>
        </div>
    `);


    return bonusItems;
}

export const TemplateDepositStepTwo = (el) => html`
<div id="deposit-step-2" class="view-with-heading">
    <div class="view-heading">
            <div class="item-left">
                <img @click=${el.onSlideOverBackButtonClick}  class="back-img" src="${CDN_ASSETS_URL}/left.svg">
                <div class="sub-title">
                    <div class="inner-sub-title">
                        <span class="sub-title Paragraph-Small">${APP_STRINGS.getWPMLString('SideViewBank-Deposit-PaymentMethodsSection-HeaderInfo-DepositAmount-Label')}</span>
                        <span class="sub-desc Paragraph-Normal">${`${el.currencySymbol}${el.BANK.depositSettings.depositAmount}`}</span>
                    </div>
                    <div class="inner-sub-title">
                        <span class="sub-title Paragraph-Small">${APP_STRINGS.getWPMLString('SideViewBank-Deposit-PaymentMethodsSection-HeaderInfo-SelectedBonus-Label')}</span>
                        <span class="sub-desc Paragraph-Normal">${el.BANK.depositSettings.selectedBonus ? html`${el.BANK.depositSettings.selectedBonus.bonusTitleTag}` : '-'}</span>
                    </div>
                </div>
            </div>
            <div class="item-right">
                <!--<img class="close-img" src="${CDN_ASSETS_URL}/chat.svg">-->
            </div>
		</div>
		<div class="scrolling-content" >
		    <div class="content">
                <form class="deposit-container">
                    <div class="title-container">
                        <span class="title Heading-4">${APP_STRINGS.getWPMLString('SideViewBank-Deposit-PaymentMethodsSection-Title')}</span>
                    </div>
                    <section class="radio-items-section deposit-methods-section">
                        ${getPaymentMethodsItems(el)}
                    </section>
                    
                    <section id="payment-form-container" class="payment-section payment-method-form-holder"></section>
                    <div class="title-container">
                        <span class="title Heading-4">${APP_STRINGS.getWPMLString('SideViewBank-Deposit-SummarySection-Title')}</span>
                    </div>
                    <section class="summary-section">
                        <ul>
                            <li class="item">
                                <label>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-SummarySection-FieldLabel-DepositAmount')}</label>
                                <span class="Heading-4">${`${el.currencySymbol}${Helpers.formatNumber(el.BANK.depositSettings.depositAmount)}`}</span>
                            </li>
                            <li class="item">
                                <label>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-SummarySection-FieldLabel-BonusAmount')}</label>
                                <span class="Heading-4">${`${el.currencySymbol}${Helpers.formatNumber(el.BANK.depositSettings.bonusAmount)}`}</span>
                            </li>
                            <li class="item">
                                <label>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-SummarySection-FieldLabel-PlayableAmount')}</label>
                                <span class="Heading-4">${`${el.currencySymbol}${Helpers.formatNumber(el.BANK.depositSettings.payableAmount)}`}</span>
                            </li>
                            <li class="item">
                                <button type="button" class="primary-button"  @click=${el.onDepositButtonClick} ?disabled=${el.BANK.depositSettings.selectedMethod==null || el.BANK.depositSettings.depositAmount==0} >
                                    ${APP_STRINGS.getWPMLString('SideViewBank-Deposit-SummarySection-ButtonLabel-Continue')}
                                </button>
                            </li>
                        </ul>
                    </section>
                </form>
            </div>
        </div>
		
</div>
        
`;

function getPaymentMethodsItems(el){
    let paymentMethodTemplates=[];
    el.depositData.depositMethods.forEach(methodData=>{
        let selected=false;
        if(el.BANK.depositSettings.selectedMethod && methodData.PaymentMethod==el.BANK.depositSettings.selectedMethod.PaymentMethod){
            selected=true;
        }
        let isDisabled=false;
        if(methodData.limitStatus!=BANK.DEPOSIT_METHOD_LIMIT_STATUS.IN_RANGE){
            isDisabled=true;
        }

        let pm=html`
        <div class="radio-box-2 deposit-method-item ${selected ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}"  data-payment-method-id="${methodData.PaymentMethodID}" data-payment-method="${methodData.PaymentMethod}" @click=${el.onDepositMethodSelect}>
        <div class="inner">
            <label class="form-element">
                <input type="radio" name="payment-method" value="${methodData.PaymentMethod}" .checked=${selected} >
                <span class="Heading-5">${methodData.CappPaymentDisplayName}</span>
            </label>
            <div class="rb-content">
                <div class="method-info">
                    <div class="pm-img">
                        <img src="${methodData.CappPaymentImage}">
                    </div>
                    
                    <div class="limits-display">
                        <span>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-PaymentMethodsSection-PaymentMethodItem-FieldLabel-MinimumDeposit')}</span>
                        <span class="limit-value">${methodData.PlayerLowerLimitDisplay}</span>
                        <span>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-PaymentMethodsSection-PaymentMethodItem-FieldLabel-MaximumDeposit')}</span>
                        <span class="limit-value">${methodData.PlayerUpperLimitDisplay}</span>
                    </div>
                </div>
            </div>
        </div>    
            ${getLimitControl(el,methodData)}
        </div>
    `;
        paymentMethodTemplates.push(pm);
    })
    return paymentMethodTemplates;
}

function getLimitControl(el,methodData){
    switch (methodData.limitStatus) {
        case BANK.DEPOSIT_METHOD_LIMIT_STATUS.TOO_LOW:
            return html`<div class="limit-control"><span>Stake too low for this method.</span>.
                            <a data-payment-method="${methodData.PaymentMethod}" @click=${el.onLimitControlClick}>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-PaymentMethodsSection-PaymentMethodItem-ButtonLabel-SetMinimumAmount')}</a>
                        </div>`;
            break;
        case BANK.DEPOSIT_METHOD_LIMIT_STATUS.TOO_HIGH:
            return html`<div class="limit-control"><span>Stake too high for this method.</span>
                            <a data-payment-method="${methodData.PaymentMethod}" @click=${el.onLimitControlClick}>${APP_STRINGS.getWPMLString('SideViewBank-Deposit-PaymentMethodsSection-PaymentMethodItem-ButtonLabel-SetMaximumAmount')}</a>
                        </div>`;
            break;
        default:
            return;
    }
}


export const TemplateDepositIframePaymentSlideOver = (el) => html`
<div id="iframe-deposit-slide-over">
    <div class="view-heading">
                <div class="item-left">
                    <img @click=${el.onSlideOverPaymentIframeBackButtonClick}  class="back-img" src="${CDN_ASSETS_URL}/left.svg">
                        <span class="title">${el.paymentSlideoverTitle}</span>
                </div>
    </div>
    <div class="content">
        ${el.loadingIframeURL ?
            html`
            <preloader-element class="primary" style="width: 100%; height: 5em;"  ></preloader-element>
            `
            : html``
        }
        <iframe style="display: ${el.loadingIframeURL ? 'none':'block'}" @load=${el._onPaymentIframeLoaded} class="payment-iframe ${el.BANK.depositSettings.selectedMethod==null?'':el.BANK.depositSettings.selectedMethod.PaymentMethod}" frameBorder="0" src="${el.paymentIframeURL==null?'':el.paymentIframeURL}">
    </div>
</div>
        
`;


import {html} from 'lit-html';
import {APP_STRINGS} from "../../../js/core/AppStrings";

export const TemplateMainMenuSideView=el=>html`
<div class="view-heading">
    <span class="title">${APP_STRINGS.getWPMLString('SideViewAccountMenu-Title')}</span>
    <img @click="${el.close}" class="close-img" class="close-img" src="${CDN_ASSETS_URL}/close.svg">
</div>
<div class="scrolling-content">
    <div class="content">
        <ul>
            <li @click=${el.onMenuItemClick} data-action="deposit"><img src="${CDN_ASSETS_URL}/wallet-2.svg">
                <span>${APP_STRINGS.getWPMLString('SideViewAccountMenu-MenuItem-Deposit')}</span>
            </li>
            <li @click=${el.onMenuItemClick} data-action="withdraw"><img src="${CDN_ASSETS_URL}/withdraw.svg">
                <span>${APP_STRINGS.getWPMLString('SideViewAccountMenu-MenuItem-Withdraw')}</span>
            </li>
            <li @click=${el.onMenuItemClick} data-action="history"><img src="${CDN_ASSETS_URL}/history.svg">
                <span>${APP_STRINGS.getWPMLString('SideViewAccountMenu-MenuItem-History')}</span>
            </li>
            <li @click=${el.onMenuItemClick} data-action="bonus"><img src="${CDN_ASSETS_URL}/bonus.svg">
                <span>${APP_STRINGS.getWPMLString('SideViewAccountMenu-MenuItem-Bonuses')}</span>
            </li>
            <li @click=${el.onMenuItemClick} data-action="profile"><img src="${CDN_ASSETS_URL}/user-2.svg">
                <span>${APP_STRINGS.getWPMLString('SideViewAccountMenu-MenuItem-Profile')}</span>
            </li>
            <li @click=${el.onMenuItemClick} data-action="preferences"><img src="${CDN_ASSETS_URL}/preferences.svg">
                <span>${APP_STRINGS.getWPMLString('SideViewAccountMenu-MenuItem-Preferences')}</span>
            </li>
            <li @click=${el.onMenuItemClick} data-action="verification"><img src="${CDN_ASSETS_URL}/verification.svg">
                <span>${APP_STRINGS.getWPMLString('SideViewAccountMenu-MenuItem-Verification')}</span>
            </li>
            <li @click=${el.onMenuItemClick} data-action="responsibleGambling"><img src="${CDN_ASSETS_URL}/responsible-gambling.svg">
                <span>${APP_STRINGS.getWPMLString('SideViewAccountMenu-MenuItem-ResponsibleGambling')}</span>
            </li>
        </ul>
    </div>
</div>

`;
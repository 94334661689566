import {html} from 'lit-html';
export const Template_IBV4_DirectBanking= el =>html`
    <form class="payment-method-form ${el._loading? 'loading' : ''} ${el._disabled ? 'disabled' : ''}">
        ${el.existingAccounts ? html`
             <div class="form-element-2" id="existing accounts">
                <div class="mdc-select" data-field="existingAccount">
                    <div class="mdc-select__anchor">
                        <i class="mdc-select__dropdown-icon"></i>
        
                        <div id="demo-selected-text" class="mdc-select__selected-text" role="button" aria-haspopup="listbox2"
                             aria-labelledby="demo-label demo-selected-text"></div>
                        <span id="demo-label"
                              class="mdc-floating-label mdc-floating-label--float-above">
                                ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-IBV4_DirectBanking-Form-FieldLabel-ExistingAccounts')}
                                </span>
        
                        <div class="mdc-line-ripple"></div>
                    </div>
                    <div class="mdc-select__menu mdc-menu mdc-menu-surface" role="listbox2">
                        <ul class="mdc-list">
                            <li class="mdc-list-item" data-value="new-account" role="option">
                                ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-IBV4_DirectBanking-Form-ExistingAccounts-ItemLabel-NewAccount')}
                            </li>
                            ${el.existingAccounts.map(account=>html`
                            <li class="mdc-list-item" data-value="${account.custom_attribute_key}" role="option">${account.bankName} ${account.bankAccountNumber}</li>
                            `)}
                        </ul>
                    </div>
                </div>
            </div>
        ` : ''}
        <div class="form-element-2">
            <label class="mdc-text-field" data-field="bankCountry">
                <div class="mdc-text-field__ripple"></div>
                <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" value="${el.country}" name="bankCountry" disabled>
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-IBV4_DirectBanking-Form-FieldLabel-BankCountry')}
                    </span>

                <div class="mdc-line-ripple"></div>
            </label>
        </div>
        <div class="form-element-2">
            <label class="mdc-text-field" data-validation-method="required" data-field="bankAccountHolderName">
                <div class="mdc-text-field__ripple"></div>
                <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" aria-controls="account-helper-text"  name="bankAccountHolderName">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-IBV4_DirectBanking-Form-FieldLabel-NameOnAccount')}
                    </span>
                <div class="mdc-line-ripple"></div>
            </label>
            <div class="mdc-text-field-helper-line">
                <div class="mdc-text-field-helper-text" aria-hidden="true" id="account-helper-text">
                    ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-IBV4_DirectBanking-Form-FieldValidation-NameOnAccount')}
                </div>
            </div>
        </div>
        ${el.banks && el.banks.length>0 ? html`
            <div class="form-element-2" id="bankSelect">
                <div class="mdc-select" data-validation-method="required" data-field="bankSwiftCode">
                    <div class="mdc-select__anchor">
                        <i class="mdc-select__dropdown-icon"></i>
        
                        <div id="demo-selected-text" class="mdc-select__selected-text" role="button" aria-haspopup="listbox2"
                             aria-labelledby="demo-label demo-selected-text"></div>
                        <span id="demo-label"
                              class="mdc-floating-label mdc-floating-label--float-above">
                                ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-IBV4_DirectBanking-Form-FieldLabel-Bank')}      
                        </span>
        
                        <div class="mdc-line-ripple"></div>
                    </div>
                    <div class="mdc-select__menu mdc-menu mdc-menu-surface" role="listbox2">
                        <ul class="mdc-list">
                            <!--<li class="mdc-list-item mdc-list-item--selected" aria-selected="true" data-value="" role="option"></li>-->
                            ${el.banks.map(bank=>html`
                            <li class="mdc-list-item" data-value="${bank.swift_code}|${bank.bank_name}" role="option">${bank.bank_name}</li>
                            `)}
                        </ul>
                    </div>
                </div>
            </div>
        `:``}
        
        <div class="form-element-2">
            <label class="mdc-text-field" data-validation-method="bsd" data-field="bankBSBCode">
                <div class="mdc-text-field__ripple"></div>
                <input class="mdc-text-field__input" type="number" aria-labelledby="my-label-id" name="bankBSBCode">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-IBV4_DirectBanking-Form-FieldLabel-BSDNumber')}
                    </span>
                <div class="mdc-line-ripple"></div>
            </label>
            <div class="mdc-text-field-helper-line">
                <div class="mdc-text-field-helper-text" aria-hidden="true">
                    ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-IBV4_DirectBanking-Form-FieldValidation-BSDNumber')}
                </div>
            </div>
        </div>
        <div class="form-element-2">
            <label class="mdc-text-field" data-validation-method="required" data-field="bankAccountNumber">
                <div class="mdc-text-field__ripple"></div>
                <input class="mdc-text-field__input" type="number" aria-labelledby="my-label-id" name="bankAccountNumber">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-IBV4_DirectBanking-Form-FieldLabel-AccountNumber')}
                    </span>
                <div class="mdc-line-ripple"></div>
            </label>
            <div class="mdc-text-field-helper-line">
                <div class="mdc-text-field-helper-text" aria-hidden="true">
                    ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-IBV4_DirectBanking-Form-FieldValidation-AccountNumber')}
                </div>
            </div>
        </div>
        <div class="form-element-2">
            <button type="button" class="primary-button ${el._loading ? 'loading' : ''}" @click=${el._onWithdrawButtonClick} >
                <span>${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PaymentMethodSection-ButtonLabel-Withdraw')}</span>
                <preloader-element></preloader-element>
            </button>
        </div>
    </form>
    
        
    
`;
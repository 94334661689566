import {SideViewBaseClass} from "../SideViewBaseClass";
import {SideViewMenu} from "../SideViewMenu";
import {SideViewSubDeposits} from "./subViews/SideViewSubDeposits";
import {SideViewSubWithdrawal} from "./subViews/SideViewSubWithdrawal";
import {SideViewSubHistory} from "./subViews/SideViewSubHistory";
import {SideViewSubBonuses} from "./subViews/SideViewSubBonuses";
import {USER} from "../../core/User";
import {SideViewSubPIQDeposits} from "./subViews/SideViewSubPIQDeposits";
import {SideViewSubPIQWithdrawal} from "./subViews/SideViewSubPIQWithdrawal";

export class SideViewBank extends SideViewBaseClass{
    constructor(firstView) {
        super("bank-side-view",SideViewBaseClass.TYPE.WIDE, firstView, SideViewBank.ALIGNMENT);
        this.menu;
    }

    close() {

        if(this.currentContent){
            this.currentContent.close();
        }
        super.close();
    }

    renderViewContent() {
        this.contentElement = document.createElement('div');
        this.contentElement.classList.add("scrolling-content");

        this.menuItems = [
            SideViewMenu.ITEM_TYPES.DEPOSIT,
            SideViewMenu.ITEM_TYPES.WITHDRAW,
            SideViewMenu.ITEM_TYPES.HISTORY,
            SideViewMenu.ITEM_TYPES.BONUSES
        ];
        this.menu = new SideViewMenu(this.menuItems, this.element,this.contentElement);


        this.element.appendChild(this.contentElement);

        this.menu.on("change",e=>{
            console.log("Change event from menu",e);
            this.renderContentView(e.selectedId);
        });
        this.menu.on("close",()=>this.close());

        this.menu.setSelectedItem(this.firstView ? this.firstView : SideViewMenu.ITEM_TYPES.DEPOSIT);

    }

    renderContentView(itemId){
        if(this.currentContent){this.currentContent.close();}
        switch (itemId) {
            case SideViewMenu.ITEM_TYPES.DEPOSIT:
                if(USER.data.cashier=='PIQ'){
                    this.currentContent=new SideViewSubPIQDeposits(this.contentElement);
                }else{
                    this.currentContent=new SideViewSubDeposits(this.contentElement);
                }

                break;
            case SideViewMenu.ITEM_TYPES.WITHDRAW:
                if(USER.data.cashier=='PIQ'){
                    this.currentContent=new SideViewSubPIQWithdrawal(this.contentElement);
                }else{
                    this.currentContent=new SideViewSubWithdrawal(this.contentElement);
                }

                break;
            case SideViewMenu.ITEM_TYPES.HISTORY:
                this.currentContent=new SideViewSubHistory(this.contentElement);
                break;
            case SideViewMenu.ITEM_TYPES.BONUSES:
                this.currentContent=new SideViewSubBonuses(this.contentElement);
                this.currentContent.on("OnDepositBonusActivateRequest",data=>{this.openDepositTabWithPreselectedBonus(data)});
                break;
        }
    }

    openDepositTabWithPreselectedBonus(data){
        this.menu.setSelectedItem(SideViewMenu.ITEM_TYPES.DEPOSIT);
        this.currentContent.selectBonus(data.bonusID);
    }

}

SideViewBank.ALIGNMENT=SideViewBaseClass.ALIGNMENT.LEFT;
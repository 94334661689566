import {render} from "lit-html";
import {FormHelpers} from "../../../util/FormHelpers";
import {
    TemplateDepositContentView, TemplateDepositIframePaymentSlideOver,
    TemplateDepositStepTwo,
    TemplateDepositAccountActivationView
} from "../../../../templates/template-items/side-view/TemplateDepositContentView";
import {SideViewSubBaseClass} from "../../SideViewSubBaseClass";
import {BANK} from "../../../core/Bank";
import {Popup} from "../../Popup";
import {SlideOverView} from "../../SlideOverView";
import {SlideOverViewStatus} from "../../SlideOverViewStatus";
import {Helpers} from "../../../util/Helpers";
import {SIDE_VIEW_MANAGER} from "../../SideViewManager";
import {APP_STRINGS} from "../../../core/AppStrings";
import {CryptoPayAdditionalForm} from "./bankForms/CryptoPayAdditionalForm";
import {USER} from "../../../core/User";
import {TemplateChangePhoneNumber} from "../../../../templates/template-items/side-view/user-preferences/TemplateChangePhoneNumber";

export class SideViewSubDeposits extends SideViewSubBaseClass{
    constructor(container) {
        super(container);
        this.depositData=null;
        this.additionalPaymentForm=null;

    }

    _init() {
        this.BANK=BANK;
        this.expandedBonusItems=[];
        this.selectedPreset=null;
        this.loadingDepositData=true;
        this.verificationEmailLoading=false;
        this.step2SlideOver=null;
        this.currencySymbol=USER.data.CurrencySymbol;
        this.currencyISO=USER.data.CurrencyISO;

        this.presetValues=[];


        this.loadingIframeURL=false;
        this.iframePaymentSlideOver=null;
        this.paymentIframeURL=null;
        this.paymentResultStatusSlideOver=null;
        this.bonusCodeInputMDC=null;
        this.bonusCodeLoading=false;
        this.specialSelect=-1;

        this.getDepositData();
        super._init();

    }

    close() {
        BANK.removeAllListeners("ExternalPaymentResult");
        if(USER.data && USER.data.cashier=='PIQ'){
            USER.updatePlayerBalance();
        }
        super.close();
    }

    _getPresetValues(){
        let presetValues;

        if(BANK.depositSettings.presetAmounts && BANK.depositSettings.presetAmounts.length > 0){
            presetValues = BANK.depositSettings.presetAmounts;
        }
        else{
            switch (this.currencyISO){
                case 'INR':
                    presetValues=[500, 1000, 2000, 3000, 5000];
                    break;
                default:
                    presetValues=[10,20,50,100,500];
            }
        }
        return presetValues;
    }

    renderView(){
        this.updateView();
        let inp=this.element.querySelector('#depositAmountMDC');
        if(inp){
            this.depositAmountInputMDC=inp.MDCTextField;
            this._setupEventListeners();
        }
    }


    _setupEventListeners(){
        this.depositAmountInputMDC.foundation_.adapter_.registerInputInteractionHandler("keyup", (e)=>{
            //todo focus out on enter key
            this.selectedPreset=null;
            let val=parseFloat(this.depositAmountInputMDC.value);
            BANK.setDepositAmount(isNaN(val) ? 0 : val);
            this.updateView();
        })

        this.depositAmountInputMDC.foundation_.adapter_.registerInputInteractionHandler("change", (e)=>{
            let val=parseFloat(this.depositAmountInputMDC.value);
            BANK.setDepositAmount(isNaN(val) ? 0 : val);

            this.updateView();
        })

    }

    _setupBonusCodeListeners(){
        this.bonusCodeInputMDC.foundation_.adapter_.registerInputInteractionHandler("change", (e)=>{
            let val=this.bonusCodeInputMDC.value;
            if(val==""){return;}
            this.bonusCodeLoading=true;
            this.updateView();
            BANK.useBonusCode(val)
                .then(data=>{
                    this.bonusCodeLoading=false;
                    if(data.error){
                        new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
                    }else if(data.bonusLoaded==false){
                        new Popup(
                            APP_STRINGS.getWPMLString('SideViewBank-Deposit-Alert-InvalidBonusCode-Title'),
                            APP_STRINGS.getWPMLString('SideViewBank-Deposit-Alert-InvalidBonusCode-Message'),
                            [APP_STRINGS.OK]);
                    }
                    this.updateView();
                })
        })
    }

    updateView(){
        //todo probably move this to bank class and dont load withdrawal data at all
        if(USER.data.AccountActivated != true){
            render(TemplateDepositAccountActivationView(this),this.element,{eventContext:this});
        }else{
            render(TemplateDepositContentView(this),this.element,{eventContext:this});
        }
        FormHelpers.initializeFormComponents(this.element);
    }

    getDepositData(){
        BANK.getDepositData().then(data=>{
            this.loadingDepositData=false;

            if(data.error){
                new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
            }else{
                this.depositData=data;
                this.presetValues=this._getPresetValues();
            }
            this.updateView();

            let inp=this.element.querySelector('#bonus-code-input-MDC');
            if(inp){
                this.bonusCodeInputMDC=inp.MDCTextField;
                this._setupBonusCodeListeners();
            }

            if(this.defaultBonus){
                this.selectBonus(this.defaultBonus);
            }
        });
    }

    onAccountActivationEmailButtonClick(){
        console.log("Call api to resend activation email");
        this.verificationEmailLoading=true;
        this.updateView();
        USER.resendActivationEmail()
            .then(data=>{
                this.verificationEmailLoading=false;
                this.updateView();
                if(data.error){
                    new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
                }else{
                    new Popup(
                        APP_STRINGS.getWPMLString('SideViewBank-Deposit-ResendActivationEmailAlert-Success-Title'),
                        APP_STRINGS.getWPMLString('SideViewBank-Deposit-ResendActivationEmailAlert-Success-Message'),
                        [APP_STRINGS.OK]);
                }
            })
    }

    onDepositPresetClick(e){
        this.selectedPreset=parseInt(e.currentTarget.dataset.presetId);
        BANK.setDepositAmount(parseFloat(this.presetValues[this.selectedPreset]));
        this.depositAmountInputMDC.value=this.presetValues[this.selectedPreset];

        this.updateView();
    }

    onBonusItemSelect(e){
        let bonusId=parseInt(e.currentTarget.dataset.bonusId);
        BANK.selectBonus(bonusId);

        if(bonusId<0){
            this.specialSelect=bonusId;
        }else{
            this.specialSelect=0;
        }

        this.updateView();
    }

    selectBonus(bonusId){
        if(this.depositData){
            console.log("deposit data is ready setting the bonus");
            BANK.selectBonus(bonusId);
            this.updateView();
        }else{
            console.log("deposit data still loading");
            console.log("setting as default bonus");
            this.defaultBonus=bonusId;
        }
    }

    onFormSubmit(e){
        //prevent form submit
        e.preventDefault();
    }

    onNextButtonClick(){
        this.step2SlideOver=new SlideOverView(this.container.parentElement,TemplateDepositStepTwo,this);
        this.depositMethodAdditionalFormContainer=this.container.parentElement.querySelector("#payment-form-container");
        console.log('this.depositMethodAdditionalFormContainer',this.depositMethodAdditionalFormContainer);


    }

    onSlideOverBackButtonClick(){
        if(this.changePhoneSlideOver){
            this.changePhoneSlideOver.close();
            this.changePhoneSlideOver=null;
        }else if(this.step2SlideOver){
            this.step2SlideOver.close();
            this.step2SlideOver=null;
            this.depositAmountInputMDC.value=BANK.depositSettings.depositAmount;

        }
        this.updateView();
    }

    onBonusDetailsClick(e){
        e.stopPropagation();
        let bonusId=parseInt(e.currentTarget.dataset.bonusId);
        let index=this.expandedBonusItems.indexOf(bonusId);
        if(index<0){
            this.expandedBonusItems.push(bonusId);
        }else{
            this.expandedBonusItems.splice(index,1);
        }

        this.updateView();
    }

    onDepositMethodSelect(e){
        if(this.additionalPaymentForm){
            this.additionalPaymentForm.destroy();
            this.additionalPaymentForm=null;
        }

        BANK.setDepositMethod(e.currentTarget.dataset.paymentMethod);
        this.step2SlideOver.update();
        if(BANK.depositSettings.selectedMethod.requresAdditionalInput==true){
            console.log("RENDER ADDITIONAL DEPOSIT FORM",BANK.depositSettings.selectedMethod);
            let formCurrencyISO = Helpers.getCurrencyIsoByPaymentMethod(e.currentTarget.dataset.paymentMethod, this.currencyISO);
            this.additionalPaymentForm=new CryptoPayAdditionalForm(this.depositMethodAdditionalFormContainer, formCurrencyISO);
        }

    }

    onDepositButtonClick(){

        if(this._phoneNumberVerification()){
            return;
        }

        if(this.depositButtonDisabled==true){return;}
        this.depositButtonDisabled=true;

        let additionalFormData=null;

        if(this.additionalPaymentForm){
            let res=this.additionalPaymentForm.processFormData();
            console.log("Validate form:",this.additionalPaymentForm, res);
            if(res.valid==true){
                additionalFormData=res.formData;
            }else{
                this.depositButtonDisabled=false;
                return;
            }

        }



        switch (BANK.getSelectedPaymentProcessType()) {
            case BANK.PAYMENT_PROCESS_TYPES.STANDARD_IFRAME:
            case BANK.PAYMENT_PROCESS_TYPES.EXTERNAL_WINDOW:
                this._startStandardIframePayment(additionalFormData);
                break;
            default:
                console.log("Invalid payment process type")

        }
    }

    onLimitControlClick(e){
        e.preventDefault();
        BANK.setDepositAmountToRange(e.currentTarget.dataset.paymentMethod);
        this.step2SlideOver.update();
    }

    _startStandardIframePayment(additionalFormData){


        this.loadingIframeURL=true;
        this.paymentIframeURL=null;
        this.paymentSlideoverTitle=BANK.depositSettings.selectedMethod.CappPaymentDisplayName;
        this.iframePaymentSlideOver=new SlideOverView(this.container.parentElement,TemplateDepositIframePaymentSlideOver,this);


        BANK.getDepositIframeLink(additionalFormData).then(data=>{
            if(data.error){
                this.iframePaymentSlideOver.close();
                new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
            }else{

                if(BANK.getSelectedPaymentProcessType()==BANK.PAYMENT_PROCESS_TYPES.EXTERNAL_WINDOW){
                    window.location.href=data.iframeURL;
                }else{
                    BANK.addListener("ExternalPaymentResult",(result)=>{this.handleExternalPaymentResult(result)})
                    this.paymentIframeURL=data.iframeURL;
                    this.iframePaymentSlideOver.update();
                }

            }

            setTimeout(()=>{
                this.depositButtonDisabled=false;
            },100)

        });
    }

    _onPaymentIframeLoaded(){
        this.loadingIframeURL=false;
        if(this.iframePaymentSlideOver){
            this.iframePaymentSlideOver.update();
        }

    }

    onSlideOverPaymentIframeBackButtonClick(){
        this.iframePaymentSlideOver.close();
        this.iframePaymentSlideOver=null;
    }

    handleExternalPaymentResult(result) {
        //this.iframePaymentSlideOver.close();
        BANK.removeAllListeners("ExternalPaymentResult");

        if(result.success==true) {
            let additionalMessage="";
            let showBalance=true;
            let title=APP_STRINGS.getWPMLString('SideViewBank-Deposit-DepositResultScreen-Success-Title');

            if(result.additionalData){

                if((typeof result.additionalData.paymentMethod) !== 'undefined'){

                    if((typeof result.additionalData.status) !== 'undefined' && result.additionalData.status === 'inProgress'){
                        showBalance=false;

                        switch(result.additionalData.paymentMethod){
                            case 'CryptoPay':
                            case 'CryptoPay_BTC':
                            case 'CryptoPay_ETH':
                            case 'CryptoPay_LTC':
                            case 'CryptoPay_BCH':
                            case 'CryptoPay_USDT':
                            case 'CryptoPay_USDC':
                            case 'CryptoPay_DAI':
                                title=APP_STRINGS.getWPMLString('ExternalPaymentPopup-Pending-Title');
                                additionalMessage=APP_STRINGS.getWPMLString('ExternalPaymentPopup-Pending-Message');
                                break;
                        }
                    }
                }
                
                if(result.additionalData.title){
                    title=result.additionalData.title;
                }
                if(result.additionalData.additionalMessage){
                    additionalMessage=result.additionalData.additionalMessage;
                }
                if((typeof result.additionalData.showBalance) != 'undefined'){
                    showBalance=result.additionalData.showBalance;
                }
            }

            /*
            {
                additionalData:{
                    title:"Popup title"
                    additionalMessage:"some message",
                    showBalance:true/false
                }
            }*/

            USER.updateUserData()
                .then(() => {
                    let content="";

                    if(showBalance === true){
                        content+=`<span>Your new balance is </span><span class="highlight">${this.currencySymbol}${Helpers.formatCurrencyCents(USER.data.CurrentBalance, true)}</span>`
                    }

                    if(additionalMessage.length>0){
                        if(content.length>0){
                            content+=`<br>`
                        }
                        content+=`<span>${additionalMessage}</span>`
                    }
                    new SlideOverViewStatus(
                        this.container.parentElement,
                        SlideOverViewStatus.STATUS_VIEW_TYPE.SUCCESS,
                        title,
                        content,
                        [APP_STRINGS.getWPMLString('SideViewBank-Deposit-DepositResultScreen-Success-ButtonLabel-Continue')],
                        (btnId)=>{
                            BANK.removeAllListeners("ExternalPaymentResult");
                            SIDE_VIEW_MANAGER.closeAnySideview();
                    });
                })
        }else{
            let content="<span>Apologies, your deposit has not gone through.Please try again.</span>"
            new SlideOverViewStatus(
                this.container.parentElement,SlideOverViewStatus.STATUS_VIEW_TYPE.FAILED,
                APP_STRINGS.getWPMLString('SideViewBank-Deposit-DepositResultScreen-Failed-Title'),
                content,
                [APP_STRINGS.getWPMLString('SideViewBank-Deposit-DepositResultScreen-Failed-Title-ButtonLabel-GoBack')],
                ()=>{
                    if(this.iframePaymentSlideOver){
                        this.iframePaymentSlideOver.close();
                    }
            });

        }

    }

    _phoneNumberVerification() {
        console.log('_startStandardIframePayment',BANK.depositSettings.selectedMethod);
        if(BANK.depositSettings.selectedMethod.CappRequirePhoneVerification){

            let mobileNumber=`+${USER.data.MobilePrefix} ${USER.data.MobilePhone}`;
            let message=APP_STRINGS.getWPMLString('PhoneNumberConfirmationPopup-message-text');
            message=message.replace('$$PhoneNumber$$',mobileNumber);

            new Popup(
                APP_STRINGS.getWPMLString('PhoneNumberConfirmationPopup-title'),
                message,
                [
                    APP_STRINGS.getWPMLString('PhoneNumberConfirmationPopup-ConfirmButton-label'),
                    APP_STRINGS.getWPMLString('PhoneNumberConfirmationPopup-ChangeButton-label')
                ],
                btnId=>{
                    if(btnId){
                        if(btnId==0){
                            this._verifyPhoneNumber({});
                        }else{
                            this.registerInfo = {}
                            this.changePhoneSlideOver=new SlideOverView(this.container.parentElement,TemplateChangePhoneNumber,this);
                            this._getRegisterData();
                        }
                    }
                }
            );
            return true;
        }
        return false;
    }

    _getRegisterData() {
        this.loadingRegisterInfo = true;
        this.changePhoneSlideOver.update();
        USER.getRegisterDataList().then(data => {
            this.registerInfo = data;
            this.loadingRegisterInfo = false;
            this.changePhoneSlideOver.update()
            FormHelpers.initializeFormComponents(this.changePhoneSlideOver.element);
        });
    }
    onSubmitUpdateProfile(e){
        e.preventDefault();
        let action = e.currentTarget.dataset.updateAction
        let statusString = ''
        let successButtonString = ''
        this.loadingUpdate = true;
        this.changePhoneSlideOver.update()
        let inputData = {
            mobilePrefix: document.querySelector(".mdc-mobile-prefix").MDCSelect,
            mobileNumber: document.querySelector(".mdc-mobile-number").MDCTextField,
        };
        let updateData={};
        updateData.mobilePrefix = inputData.mobilePrefix.value;
        updateData.mobilePhone = inputData.mobileNumber.value;
        this._verifyPhoneNumber(updateData);

    }

    _verifyPhoneNumber(updateData){
        this.depositButtonDisabled=true;
        updateData.sessionKey = USER.sessionKey;
        updateData.VerifiedPhoneNumber=1;


        USER.updatePlayerInfo(updateData).then(data=>{
            this.depositButtonDisabled=false;

            if(data.error){
                new Popup(APP_STRINGS.ERROR,data.error.text,[APP_STRINGS.OK])
            }else{
                if(this.changePhoneSlideOver){
                    this.changePhoneSlideOver.close();
                    this.changePhoneSlideOver=null;
                }
                BANK.depositSettings.selectedMethod.CappRequirePhoneVerification=false;
                this.onDepositButtonClick();
            }
        });
    }

}
import {render} from "lit-html";
import {FormHelpers} from "../util/FormHelpers";

export class SlideOverView {
    constructor(container, template, context) {
        this.container=container;
        this.template=template;
        this.context=context;
        this.element=document.createElement("div");
        this.element.classList.add("slide-over");
        this.container.appendChild(this.element);
        this.update();
        FormHelpers.initializeFormComponents(this.element);

    }

    update(){
        render(this.template(this.context),this.element,{eventContext:this.context});
    }

    close(){
        delete this.context;
        this.element.classList.add("close");
        setTimeout(()=>{
            this.container.removeChild(this.element);

        },220);
        delete this;
    }

}
import {render} from "lit-html";
import {SideViewBaseClass} from "../SideViewBaseClass";
import {TemplateLoginSideView} from "../../../templates/template-items/side-view/TemplateLoginSideView";
import {FormHelpers} from "../../util/FormHelpers";
import {USER} from "../../core/User";
import {SideViewForgotPassword} from "./SideViewForgotPassword";
import {SideViewRegister} from "./SideViewRegister";
import {SideViewRegisterShort} from "./SideViewRegisterShort";
import {Popup} from "../Popup";
import validator from "validator/es";
import {SideViewUser} from "./SideViewUser";
import {SearchView} from "../SearchView";

export class SideViewLogin extends SideViewBaseClass{
    constructor() {
        super("login",SideViewBaseClass.TYPE.NORMAL,null,SideViewLogin.ALIGNMENT);
        this.loading=false;
    }

    renderViewContent() {
        console.log("rendering login");
        render(TemplateLoginSideView(this),this.element,{eventContext:this});
        FormHelpers.initializeFormComponents(this.element);
    }

    onFormSubmit(e){
        //todo validate form
        e.preventDefault();
        if(!this.validateInput()){
            return;
        }else{
            var input = this._getMDCInputFields()
            input.emailAddress.helperText_.foundation_.adapter_.removeClass('mdc-text-field-helper-text--persistent')
            input.password.helperText_.foundation_.adapter_.removeClass('mdc-text-field-helper-text--persistent')
            this._setValidInput(input.emailAddress)
            this._setValidInput(input.password)
        }
        console.log("Form submit event", e)
        let inputData=FormHelpers.getFormDataAsObject(e.target);
        console.log();
        this.loading=true;
        render(TemplateLoginSideView(this),this.element,{eventContext:this});
        USER.login(inputData.username, inputData.password)
            .then(data=>{
                if(data.error){
                    this.loading=false;
                    render(TemplateLoginSideView(this),this.element,{eventContext:this});
                    new Popup(
                        APP_STRINGS.getWPMLString('SideViewLogin-Alert-LoginError-Title'),
                        `${APP_STRINGS.getWPMLString('SideViewLogin-Alert-LoginError-Message')} Alert code  ${data.error.errorID}`,
                        [APP_STRINGS.OK])
                }else{
                    this.close();
                }

            })
    }

    onSignUpClick(e){
        e.preventDefault();
        console.log("Open sign up view");

        if(window.isSimplifiedSignUpForm){
            new SideViewRegisterShort();
        }else{
            new SideViewRegister();
        }
    }

    onForgottenPasswordClick(e){
        e.preventDefault();
        console.log("Open forgotten password view");
        new SideViewForgotPassword();
    }

    _getMDCInputFields(){
        let mdcInput = {
            emailAddress: document.querySelector(".mdc-email").MDCTextField,
            password: document.querySelector(".mdc-password").MDCTextField,
        }
        return mdcInput
    }

    validateInput(){
        var input = this._getMDCInputFields();
        var flag = true;
        if(validator.isEmpty(input.emailAddress.value)){
            this._setErrorMessage(input.emailAddress, APP_STRINGS.getStringById("2220042"));
            input.emailAddress.helperText_.foundation_.adapter_.addClass('mdc-text-field-helper-text--persistent')
            flag = false;
        }

        if(validator.isEmpty(input.password.value)){
            this._setErrorMessage(input.password, APP_STRINGS.getStringById("2220042"));
            input.password.helperText_.foundation_.adapter_.addClass('mdc-text-field-helper-text--persistent')
            flag = false;
        }

        return flag
    }

    _setErrorMessage(input, message){
        input.useNativeValidation = false;
        input.valid = false;
        input.helperTextContent = message;
    }

    onMenuItemClick(e){
        let action=e.currentTarget.dataset.action;
        switch (action) {
            case "user":
                new SideViewUser();
                break;
            case "logout":
                USER.logout();
                break;
            case "search":
                if(this.searchView){
                    return;
                }
                console.log("launch search control");
                this.searchView=new SearchView(()=>{this.searchView=null;});
                this.close();
                break;
            case "help":
                window.location.href = window.helpLink;
                break;
            case "faqs":
                try {
                    window.location.href=document.querySelector("input[name='faqs-link']").value;
                }catch(e){
                    console.log("No FAQs link found on page")
                }
                break;
            default:
                console.log("unknown action");
        }
    }

    _setValidInput(input, message = ''){
        input.useNativeValidation = true;
        input.valid = true;
        input.helperTextContent = message;
    }
}

SideViewLogin.ALIGNMENT=SideViewBaseClass.ALIGNMENT.LEFT;
import {render} from "lit-html";
import {FormHelpers} from "../util/FormHelpers";
import {Helpers} from "@/js/util/Helpers";
import {TemplateStatusView} from "../../templates/template-items/TemplateStatusView";

export class SlideOverViewStatus {
    constructor(container, type,title,content,buttons, callback) {
        this.container=container;
        this.element=document.createElement("div");
        this.element.classList.add("slide-over");
        this.container.appendChild(this.element);
        this.callback=callback;
				
		if(this.container.id=='register'&&type==SlideOverViewStatus.STATUS_VIEW_TYPE.SUCCESS){
            if(Helpers.getCookie('AffExternalId')){

                try {
                    const pixelAffList = ["324"];
                    let affiliateId = decodeURIComponent(Helpers.getCookie('AffExternalId'));
                    affiliateId=atob(affiliateId); // Base64 decode

                    if(pixelAffList.indexOf(affiliateId) != -1){
                        this.customPixel = document.createElement("IMG");
                        this.customPixel.setAttribute("src", "https://conversions.traceinsta.com/ResourceTrack.aspx?offer_id=860&aff_id="+affiliateId+"&adv_sub=SUB_ID");
                        this.customPixel.setAttribute("width", "1");
                        this.customPixel.setAttribute("height", "1");
                        this.container.appendChild(this.customPixel);
                    }
                }
                catch(err) {
                    console.log(err.message, "AffExternalId");
                }
            }
        }

        this.template=TemplateStatusView(this, type,title,content,buttons);
        this.update();
    }

    update(){
        render(this.template,this.element,{eventContext:this});
    }

    close(){
        this.element.classList.add("close");
        setTimeout(()=>{
            this.container.removeChild(this.element);

        },220);
        delete this;
    }

    onButtonClick(e){
        console.log("e",e);
        if(this.callback){
            this.callback(e.currentTarget.dataset.buttonIndex);
        }
        this.close();

    }

}


SlideOverViewStatus.STATUS_VIEW_TYPE={
    SUCCESS:"success",
    FAILED:"failed"
};
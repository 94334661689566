import {EventEmitter} from 'events';
import {Helpers} from "../util/Helpers";
import {CAPP_API} from "./CAPPApi";

export class GameSearch extends EventEmitter{
    constructor() {
        super();
    }

    getGameDetailLite(gameCategory,gameMakers,gameThemes, pokieFilters,lineFilters,featureFilters, sortBy, limit, page){
        return CAPP_API.getGameDetailLite('',gameCategory, gameMakers, gameThemes, pokieFilters, lineFilters, featureFilters, '', '', sortBy, '', limit, page, 'en','desktop_game')
        .then(data=>{
            console.log("getGameDetailLite response",data);
            return data
        })
    }

    searchForGamesByName(searchString){
        return CAPP_API.getGameDetailLite(searchString,"all-casino-games", '', '', '', '', '', '', '', 'MostPopular', '', 60, 0, 'en','desktop_game')
            .then(data=>{
                console.log("game search response",data);
                return data
            })
    }
}


import {Popup} from "./Popup";
import {TemplateExternalPaymentCallbackSuccessContent} from "../../templates/template-items/TemplateExternalPaymentCallbackPopup";
import {APP_STRINGS} from "../core/AppStrings";

export class ExternalPaymentCallbackPopup extends Popup{
    constructor(type) {
        super(null,null,null,null);//APP_STRINGS.OK
        this.type=type;
        this.contentTemplate=TemplateExternalPaymentCallbackSuccessContent;
        this.applyPopupTypeParameter();
    }

    _init(popupDoneCallback) {
        super._init(popupDoneCallback);
        this.element.classList.add("external-deposit-callback-popup");
        this.dontUseCloseButton();

    }

    applyPopupTypeParameter(){
        switch (this.type) {
            case ExternalPaymentCallbackPopup.TYPE.SUCCESS:
                this.title=APP_STRINGS.getWPMLString('ExternalPaymentPopup-Success-Title');
                this.message=APP_STRINGS.getWPMLString('ExternalPaymentPopup-Success-Message');
                this.buttons=[APP_STRINGS.getWPMLString('ExternalPaymentPopup-Success-ButtonLabel')];
                break;
            case ExternalPaymentCallbackPopup.TYPE.PENDING:
                this.title=APP_STRINGS.getWPMLString('ExternalPaymentPopup-Pending-Title');
                this.message=APP_STRINGS.getWPMLString('ExternalPaymentPopup-Pending-Message');
                this.buttons=[APP_STRINGS.getWPMLString('ExternalPaymentPopup-Pending-ButtonLabel')];
                break;
            case ExternalPaymentCallbackPopup.TYPE.FAIL:
                this.title=APP_STRINGS.getWPMLString('ExternalPaymentPopup-Fail-Title');
                this.message=APP_STRINGS.getWPMLString('ExternalPaymentPopup-Fail-Message');
                this.buttons=[APP_STRINGS.getWPMLString('ExternalPaymentPopup-Fail-ButtonLabel')];

                break;

        }
    }
}

ExternalPaymentCallbackPopup.TYPE={
    SUCCESS:"success",
    FAIL:"fail",
    PENDING:"pending"
}
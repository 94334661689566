import {html} from 'lit-html';
import {APP_STRINGS} from "../../../js/core/AppStrings";

export const TemplateFavouriteGameSection = (el) => html`
<a class="game-category-title Heading-4" href="${window.favGameListLink}">${APP_STRINGS.getWPMLString('GameFilters-MyFavourites-Label')} <img src="${CDN_ASSETS_URL}/right-black.svg"></a>
<div class="game-items-container capp-scrollbar capp-scrollbar-dark">
    ${displayFavouriteGame(el)}
</div>
`

function displayFavouriteGame(el){
    const displayList = [];
    if(el.favouriteGameData && el.favouriteGameData.gameCount > 0){
        for (const [key, value] of Object.entries(el.favouriteGameData.games)) {
            displayList.push(html`
                <a href="${value.gameLink}">
                    <div class="game-item">
                        <div class="game-image">
                            <img src="${value.lobbyThumbnailUrl}">
                        </div>
                        <div class="game-title">${value.GameName}</div>
                    </div>
                </a>
            `)
        }
    }
    return displayList
}


import {html} from 'lit-html';

export const Template_CryptoPay_Deposit_AdditionlForm= el=>html`
    
    <form class="payment-method-form ${el._loading? 'loading' : ''} ${el._disabled ? 'disabled' : ''}">
        <div class="title-container">
            <span class="title Heading-4">${getCryptoCurrencyLabel(el.currencyISO)} Withdrawal Address</span>
        </div>
        <div class="additional-form-info">
            <p>Please enter your ${getCryptoCurrencyLabel(el.currencyISO)} address below for us to send you future withdrawals. We require this now for your added securiy.</p>
            <p>Note that you will only be able to withdraw funds to this ${getCryptoCurrencyLabel(el.currencyISO)} address.</p>
            <p>If you wish to change this in future, please contact Customer support for assistance</p>
        </div>
        <div class="form-element-2">
            <label class="mdc-text-field" data-validation-method="${getValidationMethod(el.currencyISO)}" data-field="bitcoinWithdrawalAddress">
                <div class="mdc-text-field__ripple"></div>
                <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" value="" name="bitcoinWithdrawalAddress" spellcheck="false" >
                    <span class="mdc-floating-label" id="my-label-id">
                        <!--${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-IBV4_DirectBanking-Form-FieldLabel-BankCountry')}-->
                        ${getCryptoCurrencyLabel(el.currencyISO)} address
                    </span>

                <div class="mdc-line-ripple"></div>
            </label>
            <div class="mdc-text-field-helper-line">
                <div class="mdc-text-field-helper-text" aria-hidden="true" id="account-helper-text">
                    <!--${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-IBV4_DirectBanking-Form-FieldValidation-NameOnAccount')}-->
                    Please enter a valid ${getCryptoCurrencyLabel(el.currencyISO)} address
                </div>
            </div>
        </div>
        
    </form>
`;

function getCryptoCurrencyLabel(currencyISO){
    switch(currencyISO){
        case 'BTC':
            return html`Bitcoin`
            break;
        case 'ETH':
            return html`Ethereum`
            break;
        case 'LTC':
            return html`Litecoin`
            break;
        case 'BCH':
            return html`Bitcoin Cash`
            break;
        case 'USDT':
            return html`Tether`
            break;
        case 'USDC':
            return html`USD Coin`
            break;
        case 'DAI':
            return html`DAI`
            break;
    }
}

function getValidationMethod(currencyISO){

    if(currencyISO != ''){
        currencyISO = currencyISO.toLowerCase();
    }
    else{
        currencyISO = 'btc';
    }
    return currencyISO + '-address';
}
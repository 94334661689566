import {render} from "lit-html";
import validator from "validator/es";
import {SideViewBaseClass} from "../SideViewBaseClass";
import {TemplateRegisterSideView} from "@/templates/template-items/side-view/TemplateRegisterSideView";
import {FormHelpers} from "../../util/FormHelpers";
import {USER} from "../../core/User";
import {Popup} from "../Popup";
import {Validations} from "@/js/util/Validations";
import {Helpers} from "@/js/util/Helpers";
import {SlideOverViewStatus} from "@/js/ui/SlideOverViewStatus";
import {SIDE_VIEW_MANAGER} from "@/js/ui/SideViewManager";
import {SideViewUser} from "./SideViewUser";
import {SearchView} from "../SearchView";

const LANGUAGE=Helpers.getCookie('_icl_current_language');

export class SideViewRegister extends SideViewBaseClass{
    
    constructor() {
        super("register",SideViewBaseClass.TYPE.WIDE,null, SideViewRegister.ALIGNMENT);
        this.step = '1';
        this.loading = false;
        this.loadingRegisterInfo = false;
        this.registrationDone=false;
        this.registerInfo = {}
        this.recaptchaAPI = null
        this.widgetID = null;
        this.redirectAfterLoginURL=null;
        this.testfunction = function(token){
            console.log('testing')
            console.log(token)
        }
        $('.sign-up-code').hide();
    }

    renderViewContent() {
        $.getScript('https://www.google.com/recaptcha/api.js?onload=onReadyCallback&render=explicit', function(data, textStatus, jqxhr ) { 
            console.log(data);
            console.log(textStatus);
            console.log(jqxhr);
        });
        console.log("rendering register");
        this.step = '1'
        this.registerInfo = {}
        this.loadingRegisterInfo = true;
        this.updateView()
        this.getRegisterData()
        // grecaptcha.reset(1)

    }

    getRegisterData(){
        this.loadingRegisterInfo = true;
        USER.getRegisterDataList().then(data=>{
            console.log(data)
            this.registerInfo = data;
            this.loadingRegisterInfo = false;
            this.updateView()

            FormHelpers.initializeFormComponents(this.element);

            this.initializeDOBComponent();

            this._setupEventListener()
        });
    }

    updateView(){
        render(TemplateRegisterSideView(this),this.element,{eventContext:this});
    }

    toggleSignUpCode(e){
        $('.sign-up-code').toggle();
        if($('.sign-up-code').is(":hidden")){
            $('.arrow-sign-up-code').attr('src', CDN_ASSETS_URL+'/down.svg')
        }else{
            $('.arrow-sign-up-code').attr('src', CDN_ASSETS_URL+'/up.svg')
        }
    }

    _getMDCInputFields(){
        let mdcInput = {
            //step-1
            emailAddress: document.querySelector(".mdc-email-address").MDCTextField,
            password: document.querySelector(".mdc-password").MDCTextField,
            confirmPassword: document.querySelector(".mdc-confirm-password").MDCTextField,
            tncCheckBox: document.querySelector(".mdc-tc-checkbox").MDCCheckbox,
            couponCode: document.querySelector(".sign-up-code-mdc").MDCTextField,
            //step-2
            firstName: document.querySelector(".mdc-first-name").MDCTextField,
            lastName: document.querySelector(".mdc-last-name").MDCTextField,
            gender: document.querySelector(".mdc-gender").MDCSelect,
            dateOfBirth: document.querySelector(".mdc-dob").MDCTextField,
            nationality : document.querySelector(".mdc-nationality") ? document.querySelector(".mdc-nationality").MDCSelect : {},
            currencyISO: document.querySelector(".mdc-currency").MDCSelect,
            //step-3
            address1: document.querySelector(".mdc-address1").MDCTextField,
            address2: document.querySelector(".mdc-address2").MDCTextField,
            city: document.querySelector(".mdc-city").MDCTextField,
            postcode: document.querySelector(".mdc-postcode").MDCTextField,
            country: document.querySelector(".mdc-country").MDCSelect,
            mobilePrefix: document.querySelector(".mdc-mobile-prefix").MDCSelect,
            mobileNumber: document.querySelector(".mdc-mobile-number").MDCTextField,
            securityQuestion: document.querySelector(".mdc-security-question") ? document.querySelector(".mdc-security-question").MDCSelect : {},
            securityAnswer: document.querySelector(".mdc-security-answer") ? document.querySelector(".mdc-security-answer").MDCTextField : {}
        }
        return mdcInput
    }

    _setupEventListener(){
        var input = this._getMDCInputFields()
        input.emailAddress.listen('change', (e) => {
            var emailAddress = e.currentTarget.MDCTextField
            if(validator.isEmpty(emailAddress.value)){
                this._setErrorMessage(emailAddress, APP_STRINGS.getStringById("460"));
            }else if(!validator.isEmail(emailAddress.value)){
                this._setErrorMessage(emailAddress, APP_STRINGS.getStringById("460"));
            }else{
                this._setValidInput(emailAddress);
            }
        });

        if(Helpers.getDeviceType() == 'mobile'){
            input.emailAddress.listen('keyup', (e) => {
                var emailAddress = e.currentTarget.MDCTextField
                emailAddress.value = emailAddress.value.toLowerCase()
            });
        }

        input.emailAddress.listen('change', (e) => {
            var emailAddress = e.currentTarget.MDCTextField
            if(validator.isEmpty(emailAddress.value)){
                this._setErrorMessage(emailAddress, APP_STRINGS.getStringById("460"));
            }else if(!validator.isEmail(emailAddress.value)){
                this._setErrorMessage(emailAddress, APP_STRINGS.getStringById("460"));
            }else{
                this._setValidInput(emailAddress);
            }
        });

        input.password.listen('change', (e) => {
            var password = e.currentTarget.MDCTextField
            if(validator.isEmpty(password.value)){
                this._setErrorMessage(password, 'Empty password');
                return
            }
            var validatePasswordFormat = Validations.validatePassword(password.value)
            if(!validatePasswordFormat.valid){
                this._setErrorMessage(password, validatePasswordFormat.message);
            }else {
                this._setValidInput(password);
            }
        });

        input.confirmPassword.listen('change', (e) => {
            var confirmPassword = e.currentTarget.MDCTextField
            if($('.mdc-password').children('input').val() != confirmPassword.value){
                this._setErrorMessage(confirmPassword, APP_STRINGS.getStringById("2221005"));
            }else{
                this._setValidInput(confirmPassword);
            }
        });

        input.couponCode.listen('change', (e) => {
            var couponCode = e.currentTarget.MDCTextField
            if(couponCode.value != ''){
                var reg = /^([a-z|A-Z|0-9|\.|_|-]{1,80})$/;
                if (!(reg.test(element.val()))) {
                    this._setErrorMessage(couponCode, APP_STRINGS.getStringById("468"));
                }else{
                    this._setValidInput(couponCode);
                }
            }
        });

        input.firstName.listen('change', (e) => {
            var firstName = e.currentTarget.MDCTextField
            var reg = /^[^,./<>?;':"[\]{}\-=_+`~!@#$%^&*() ]*$/;
            if(validator.isEmpty(firstName.value)){
                this._setErrorMessage(firstName, APP_STRINGS.getStringById("450"));
            }else if(!validator.isLength(firstName.value, {min: 2, max: 40})){
                this._setErrorMessage(firstName, APP_STRINGS.getStringById("450"));
            }else if (!(reg.test(firstName.value))) {
                this._setErrorMessage(firstName, APP_STRINGS.getStringById("450"));
            }else{
                this._setValidInput(firstName);
            }
        });

        input.lastName.listen('change', (e) => {
            var lastName = e.currentTarget.MDCTextField
            var reg = /^[^,./<>?;':"[\]{}\-=_+`~!@#$%^&*() ]*$/;
            if(validator.isEmpty(lastName.value)){
                this._setErrorMessage(lastName, APP_STRINGS.getStringById("451"));
            }else if(!validator.isLength(lastName.value, {min: 2, max: 40})){
                this._setErrorMessage(lastName, APP_STRINGS.getStringById("451"));
            }else if (!(reg.test(lastName.value))) {
                this._setErrorMessage(lastName, APP_STRINGS.getStringById("451"));
            }else{
                this._setValidInput(lastName);
            }
        });

        input.gender.listen('MDCSelect:change', (e) => {
            var gender = e.currentTarget.MDCSelect
            if(validator.isEmpty(gender.value)){
                this._setErrorMessage(gender, APP_STRINGS.getStringById("451"));
            }else{
                gender.helperText_.root_.innerText = ''
                this._setValidInput(gender);
            }
        });

        if (window.USE_NATIONALITY) {
            input.nationality.listen('MDCSelect:change', (e) => {
                var nationality = e.currentTarget.MDCSelect
                if(validator.isEmpty(nationality.value)){
                    this._setErrorMessage(nationality, APP_STRINGS.getStringById("2221030"));
                }else{
                    this._setValidInput(nationality);
                    nationality.helperText_.root_.innerText = ''
                }
            });
        }

        input.currencyISO.listen('MDCSelect:change', (e) => {
            var currencyISO = e.currentTarget.MDCSelect
            if(validator.isEmpty(currencyISO.value)){
                this._setErrorMessage(currencyISO, APP_STRINGS.getStringById("458"));
            }else{
                this._setValidInput(currencyISO);
                currencyISO.helperText_.root_.innerText = ''
            }
        });

        input.address1.listen('change', (e) => {
            var address1 = e.currentTarget.MDCTextField
            if(!validator.isLength(address1.value, {min: 2, max: 254})){
                this._setErrorMessage(address1, APP_STRINGS.getStringById("453"));
            }else{
                this._setValidInput(address1);
            }
        });

        input.address2.listen('change', (e) => {
            var address2 = e.currentTarget.MDCTextField
            if(address2.value != ''){
                if(!validator.isLength(address2.value, {min: 2, max: 32})){
                    this._setErrorMessage(address2, APP_STRINGS.getStringById("453"));
                }else{
                    this._setValidInput(address2);
                }
            }
        });

        input.city.listen('change', (e) => {
            var city = e.currentTarget.MDCTextField
            if(!validator.isLength(city.value, {min: 2, max: 80})){
                this._setErrorMessage(city, APP_STRINGS.getStringById("456"));
            }else{
                this._setValidInput(city);
            }
        });

        input.postcode.listen('change', (e) => {
            var postcode = e.currentTarget.MDCTextField
            if(!validator.isLength(postcode.value, {min: 2, max: 30})){
                this._setErrorMessage(postcode, APP_STRINGS.getStringById("455"));
            }else{
                this._setValidInput(postcode);
            }
        });

        input.country.listen('MDCSelect:change', (e) => {
            var country = e.currentTarget.MDCSelect
            if(validator.isEmpty(country.value)){
                this._setErrorMessage(country, APP_STRINGS.getStringById("457"));
            }else{
                this._setValidInput(country);
                country.helperText_.root_.innerText = ''
            }
        });

        input.mobilePrefix.listen('MDCSelect:change', (e) => {
            console.log("Mobile prefix changed");
            var mobilePrefix = e.currentTarget.MDCSelect
            if(validator.isEmpty(mobilePrefix.value)){
                mobilePrefix.useNativeValidation = false;
                mobilePrefix.valid = false;
                this._setMobileHelperText(false,'Please select mobile prefix');
            }else{
                mobilePrefix.useNativeValidation = true;
                mobilePrefix.valid = true;
                this._setMobileHelperText(true,'');
            }
        });

        input.mobileNumber.listen('change', (e) => {
            var mobileNumber = e.currentTarget.MDCTextField;

            if(!validator.isLength(mobileNumber.value, {min: 6, max: 19})){
                mobileNumber.useNativeValidation = false;
                mobileNumber.valid = false;
                this._setMobileHelperText(false,APP_STRINGS.getStringById("462"));
            }else if(!validator.isNumeric(mobileNumber.value)) {
                mobileNumber.useNativeValidation = false;
                mobileNumber.valid = false;
                this._setMobileHelperText(false, APP_STRINGS.getStringById("462"));
            }else if(validator.isEmpty(input.mobilePrefix.value)){
                input.mobilePrefix.useNativeValidation = false;
                input.mobilePrefix.valid = false;
                this._setMobileHelperText(false,'Please select mobile prefix');
            }else{
                mobileNumber.useNativeValidation = true;
                mobileNumber.valid = true;
                this._setMobileHelperText(true,'');
            }
        });

        if (window.USE_SECRET_QUESTION) {
            input.securityQuestion.listen('MDCSelect:change', (e) => {
                var securityQuestion = e.currentTarget.MDCSelect
                if(validator.isEmpty(securityQuestion.value)){
                    this._setErrorMessage(securityQuestion, 'Please select a secret question');
                }else{
                    this._setValidInput(securityQuestion);
                    securityQuestion.helperText_.root_.innerText = ''
                }
            });
        }

        if (window.USE_SECRET_ANSWER) {
            input.securityAnswer.listen('change', (e) => {
                var securityAnswer = e.currentTarget.MDCTextField
                if(validator.isEmpty(securityAnswer.value)){
                    this._setErrorMessage(securityAnswer, APP_STRINGS.getStringById("2221023"));
                }else{
                    this._setValidInput(securityAnswer);
                }
            });
        }
        
    }

    _setMobileHelperText(valid,message){
        //.mobile-number-input-group
        if(valid){
            //$(mobileNumber.root_).parents('.inputs-group').removeClass('mdc-text-field--invalid')
            ///$(mobileNumber.root_).parents('.inputs-group').siblings('.mdc-text-field-helper-line').children('.mdc-text-field-helper-text').html('')
            console.log("setting valid input for mobile",$('.mobile-number-input-group .mdc-text-field-helper-line .mdc-text-field-helper-text'));
            $('.mobile-number-input-group').removeClass('mdc-text-field--invalid');
            $('.mobile-number-helper-text .mdc-text-field-helper-text').html(message);
        }else{
            //$(mobileNumber.root_).parents('.inputs-group').siblings('.mdc-text-field-helper-line').children('.mdc-text-field-helper-text').html(APP_STRINGS.getStringById("462"))
            //$(mobileNumber.root_).parents('.inputs-group').addClass('mdc-text-field--invalid')

            $('.mobile-number-helper-text .mdc-text-field-helper-text').html(message)
            $('.mobile-number-input-group').addClass('mdc-text-field--invalid');

        }
    }

    onNext1(e){
        e.preventDefault();
        this.loading=true;
        this.updateView()

        var inputField = this._getMDCInputFields()

        if(!this._basicValidateStep1(inputField.emailAddress, inputField.password, inputField.confirmPassword)){
            this.loading=false;
            this.updateView()
            return;
        };

        var tnc = inputField.tncCheckBox;
        if(tnc.checked){
            //1) check tnc
            USER.checkEmailExist(inputField.emailAddress.value)
            .then(data=>{
                //2) check email
                if(typeof data.error == 'undefined'){
                    inputField.emailAddress.useNativeValidation = true;
                    inputField.emailAddress.valid = true;
                    inputField.emailAddress.helperTextContent = APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-Email');
                    if(!data.Exists){
                        var couponCode = inputField.couponCode
                        if(couponCode.value != ''){
                            //3) if coupon !empty check coupon
                            USER.checkSignUpCode(couponCode.value)
                            .then(data=>{
                                console.log(data)
                                if(data.error){
                                    //show error message data.error.errorMessage
                                    console.log('error invalid sign up code')
                                    this._setErrorMessage(couponCode, APP_STRINGS.getStringById(data.error.errorID))

                                    this.loading=false;
                                    this.updateView()
                                }else{
                                    this.step = '2';
                                    this.loading=false;
                                    this.updateView();
                                    this.scrollFormToTop();
                                }
                            })
                        }else{
                            this.step = '2';
                            this.loading=false;
                            this.updateView();
                            this.scrollFormToTop();
                        }
                    
                    }
                }else{
                    //show error message data.error.errorMessage
                    this.loading=false;
                    this.updateView()

                    this._setErrorMessage(inputField.emailAddress, APP_STRINGS.getStringById(data.error.errorID))
                    console.log('error email exist')
                }
            });
        }else{
            console.log('tnc is not checked')
            this._setErrorMessage(tnc, APP_STRINGS.getStringById("2221020"));
            this.loading=false;
            this.updateView()
        }  
    }

    _basicValidateStep1(emailAddress, password, confirmPassword){
        if(validator.isEmpty(emailAddress.value)){
            this._setErrorMessage(emailAddress, APP_STRINGS.getStringById("460"));
            return false;
        }

        if(!validator.isEmail(emailAddress.value)){
            this._setErrorMessage(emailAddress, APP_STRINGS.getStringById("460"));
            return false;
        }

        emailAddress.useNativeValidation = true;
        emailAddress.valid = true;
        emailAddress.helperTextContent = ''

        if(validator.isEmpty(password.value)){
            this._setErrorMessage(password, APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-Password-EmptyPassword'));
            return false;
        }

        var validatePasswordFormat = Validations.validatePassword(password.value)
        if(!validatePasswordFormat.valid){
            this._setErrorMessage(password, validatePasswordFormat.message);
            return false;
        }

        password.useNativeValidation = true;
        password.valid = true;
        password.helperTextContent = ''

        if(password.value != confirmPassword.value){
            this._setErrorMessage(confirmPassword, APP_STRINGS.getStringById("2221005"));
            return false;
        }

        confirmPassword.useNativeValidation = true;
        confirmPassword.valid = true;
        confirmPassword.helperTextContent = ''

        return true;
    }

    onNext2(e){
        e.preventDefault();
        
        this.loading=true;
        this.updateView()
        
        var inputField = this._getMDCInputFields()
        if(!this._basicValidateStep2(inputField.firstName, inputField.lastName, inputField.gender, inputField.dateOfBirth, inputField.nationality, inputField.currencyISO)){
            this.loading=false;
            this.updateView()
            return;
        };

        this.step = '3';
        this.loading=false;
        this.updateView();
        this.scrollFormToTop();
    }

    _basicValidateStep2(firstName, lastName, gender, dob, nationality, currencyISO){
        var flag = true;
        if(validator.isEmpty(firstName.value)){
            this._setErrorMessage(firstName, APP_STRINGS.getStringById("450"));
            flag = false;
        }

        if(!validator.isLength(firstName.value, {min: 2, max: 40})){
            this._setErrorMessage(firstName, APP_STRINGS.getStringById("450"));
            flag = false;
        }

        var reg = /^[^,./<>?;':"[\]{}\-=_+`~!@#$%^&*() ]*$/;
        if (!(reg.test(firstName.value))) {
            this._setErrorMessage(firstName, APP_STRINGS.getStringById("450"));
            flag = false;
        }

        if(validator.isEmpty(lastName.value)){
            this._setErrorMessage(lastName, APP_STRINGS.getStringById("451"));
            flag = false;
        }

        if(!validator.isLength(lastName.value, {min: 2, max: 40})){
            this._setErrorMessage(lastName, APP_STRINGS.getStringById("451"));
            flag = false;
        }

        var reg = /^[^,./<>?;':"[\]{}\-=_+`~!@#$%^&*() ]*$/;
        if (!(reg.test(lastName.value))) {
            this._setErrorMessage(lastName, APP_STRINGS.getStringById("451"));
            flag = false;
        }

        if(validator.isEmpty(gender.value)){
            this._setErrorMessage(gender, APP_STRINGS.getStringById("2221022"));
            gender.helperText_.root_.innerText = APP_STRINGS.getStringById("2221022")
            $(gender.root_).addClass('mdc-text-field--invalid')
            flag = false;
        }

        if (window.USE_NATIONALITY) {
            if(validator.isEmpty(nationality.value)){
                this._setErrorMessage(nationality, APP_STRINGS.getStringById("2221030"));
                nationality.helperText_.root_.innerText = APP_STRINGS.getStringById("2221030")
                $(nationality.root_).addClass('mdc-text-field--invalid')
                flag = false;
            }
        }
        
        if(validator.isEmpty(currencyISO.value)){
            this._setErrorMessage(currencyISO, APP_STRINGS.getStringById("458"));
            currencyISO.helperText_.root_.innerText = APP_STRINGS.getStringById("458")
            $(currencyISO.root_).addClass('mdc-text-field--invalid')
            flag = false;
        }

        if(validator.isEmpty(dob.value)){
            this._setErrorMessage(dob, APP_STRINGS.getStringById("459"));
            flag = false;
        }
        
        return flag;
    }

    _basicValidateStep3(address1, city, postcode, country, mobilePrefix, mobileNumber, securityQuestion, securityAnswer){
        var flag = true;
        if(!validator.isLength(address1.value, {min: 2, max: 254})){
            this._setErrorMessage(address1, APP_STRINGS.getStringById("453"));
            flag = false;
        }

        if(!validator.isLength(city.value, {min: 2, max: 80})){
            this._setErrorMessage(city, APP_STRINGS.getStringById("456"));
            flag = false;
        }

        if(!validator.isLength(postcode.value, {min: 2, max: 30})){
            this._setErrorMessage(postcode, APP_STRINGS.getStringById("455"));
            flag =  false;
        }

        console.log("MOBILE PREFIX VALUE",mobilePrefix.value);
        if(validator.isEmpty(mobilePrefix.value)){
            mobilePrefix.useNativeValidation = false;
            mobilePrefix.valid = false;
            this._setMobileHelperText(false, "Please select mobile prefix");
            flag =  false;
        }else{
            //$(mobileNumber.root_).parents('.inputs-group').siblings('.mdc-text-field-helper-line').children('.mdc-text-field-helper-text').html("");

            if(!validator.isNumeric(mobileNumber.value) ||
               !validator.isLength(mobileNumber.value, {min: 6, max: 19})){
                mobileNumber.useNativeValidation = false;
                mobileNumber.valid = false;
                this._setMobileHelperText(false, APP_STRINGS.getStringById("462"));

                flag =  false;
            }else{
                mobileNumber.useNativeValidation = true;
                mobileNumber.valid = true;
                this._setMobileHelperText(true, "");
            }

        }


        


        if(validator.isEmpty(country.value)){
            this._setErrorMessage(country, APP_STRINGS.getStringById("457"));
            country.helperText_.root_.innerText = APP_STRINGS.getStringById("457")

            $(country.root_).addClass('mdc-text-field--invalid')
            flag = false;
        }

        if (window.USE_SECRET_QUESTION) {
            if(validator.isEmpty(securityQuestion.value)){
                this._setErrorMessage(securityQuestion, APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-SecurityQuestion'));
                securityQuestion.helperText_.root_.innerText = APP_STRINGS.getWPMLString('SideViewRegister-RegistrationForm-InputField-Validation-SecurityQuestion')
                $(securityQuestion.root_).addClass('mdc-text-field--invalid')
                flag = false;
            }
        }

        if (window.USE_SECRET_ANSWER) {
            if(validator.isEmpty(securityAnswer.value)){
                this._setErrorMessage(securityAnswer, APP_STRINGS.getStringById("2221023"));
                securityAnswer.helperText_.root_.innerText = APP_STRINGS.getStringById("2221023")
                flag = false;
            }
        }
        
        return flag
    }

    onFormSubmitRegister(e){
        e.preventDefault();
        let inputData=FormHelpers.getFormDataAsObject(e.target);

        var mdcInput = this._getMDCInputFields();

        this.loading=true;
        render(TemplateRegisterSideView(this),this.element,{eventContext:this});

        //validation before send
        if(!this._basicValidateStep3(mdcInput.address1, mdcInput.city, mdcInput.postcode, mdcInput.country, mdcInput.mobilePrefix, mdcInput.mobileNumber, mdcInput.securityQuestion, mdcInput.securityAnswer)){
            this.loading=false;
            this.updateView()
            return;
        };

        var _register = this
        try{
            window.registerWidgetID = grecaptcha.render('g-recapture-register', {
                'sitekey' : SITE_KEY,
                'callback' : function(token){
                    return new Promise(function(resolve, reject) { 
                        console.log('render callback')
                        console.log(token)
                        console.log('response '+grecaptcha.getResponse(window.registerWidgetID))
                        resolve();
                    }).then(callback => {
                        var recaptchaResponse = grecaptcha.getResponse(window.registerWidgetID)
                        _register.submitRegisterRequest(recaptchaResponse)
                    })
                }
            });
        }catch(error){
            // grecaptcha.reset(window.registerWidgetID)
        }

        grecaptcha.execute(window.registerWidgetID )
        
    }

    submitRegisterRequest(recaptchaResponse){
        var mdcInput = this._getMDCInputFields();
        let registerController = this;
        let data = {
            emailAddress: mdcInput.emailAddress.value,
            countryCustom: '',
            countryISO: mdcInput.country.value,
            // userName: '',
            citizenshipCustom: '',
            citizenshipID: mdcInput.nationality.value,
            password: mdcInput.password.value,
            languageISO: LANGUAGE,
            currencyISO: mdcInput.currencyISO.value,
            dateOfBirth: Helpers.formatDate(mdcInput.dateOfBirth.value, '-'),
            activateAccount: true, // true or false where to obtain?
            loginOnSuccess: true, // true or false where to obtain?
            firstName: mdcInput.firstName.value,
            city: mdcInput.city.value,
            lastName: mdcInput.lastName.value,
            postcode: mdcInput.postcode.value,
            gender: mdcInput.gender.value,
            mobilePrefix: mdcInput.mobilePrefix.value,
            mobilePhone: mdcInput.mobileNumber.value,
            address: mdcInput.address1.value+';'+mdcInput.address2.value,
            secretQuestion: mdcInput.securityQuestion.value,
            secretAnswer: mdcInput.securityAnswer.value,
            couponCode: mdcInput.couponCode.value,
            currentUrl: window.location.href,
            'g-recaptcha-response': recaptchaResponse
        }

        //call register API
        return USER.register(data)
        .then(data=>{
            if(data.error){
                new Popup(
                    APP_STRINGS.getWPMLString('SideViewRegister-Alert-FailedRegistration-Title'),
                    `${APP_STRINGS.getWPMLString('SideViewRegister-Alert-FailedRegistration-Message')} Alert code  ${data.error.errorID}`,
                    [APP_STRINGS.OK])
            }else{
                //redirect to register complete page
                console.log('success',data);
                this.registrationDone=true;

                new SlideOverViewStatus(
                    registerController.element,
                    SlideOverViewStatus.STATUS_VIEW_TYPE.SUCCESS,
                    APP_STRINGS.getWPMLString('SideViewRegister-SuccessView-Title'),
                    APP_STRINGS.getWPMLString('SideViewRegister-SuccessView-Message'),
                    [APP_STRINGS.getWPMLString('SideViewRegister-SuccessView-ButtonLabel-Continue')],(btnId)=>{
                    SIDE_VIEW_MANAGER.closeAnySideview();

                    if(data.requireLocationConfirmation){
                        let p=new Popup(
                            APP_STRINGS.getWPMLString('SideViewRegister-Alert-LocationConfirmation-Title'),
                            APP_STRINGS.getWPMLString('SideViewRegister-Alert-LocationConfirmation-Message'),
                            [APP_STRINGS.getWPMLString('SideViewRegister-Alert-LocationConfirmation-ButtonLabel-No'),APP_STRINGS.getWPMLString('SideViewRegister-Alert-LocationConfirmation-ButtonLabel-Yes')],
                            btnId=>{
                                let answer= btnId==0 ? "no" : "yes";
                                USER.registerConfirmLocation(data.ClientID, data.signature, answer)
                                    .then(resp=>{
                                        if(resp.error){
                                            new Popup(resp.error.errorTitle, resp.error.errorMessage, ['OK'])
                                        }
                                    })
                                if(answer=="yes"){
                                    this._login(mdcInput.emailAddress.value, mdcInput.password.value, data);
                                }else{
                                    if(this.redirectAfterLoginURL!=null){
                                        document.location.href=this.redirectAfterLoginURL;
                                    }
                                }
                            });
                        p.dontUseCloseButton();
                    }else{
                        this._login(mdcInput.emailAddress.value, mdcInput.password.value, data);
                    }

                });
                
            }
            registerController.loading=false;
            registerController.updateView();
            grecaptcha.reset(window.registerWidgetID);
        });
    }

    _login(email,password,loginData){
        USER.login(email,password,loginData).then(data=>{
            if(data.error){
                new Popup(
                    APP_STRINGS.getWPMLString('SideViewRegister-Alert-LoginError-Title'),
                    `${APP_STRINGS.getWPMLString('SideViewRegister-Alert-LoginError-Message')} Alert code  ${data.error.errorID}`,
                    [APP_STRINGS.getWPMLString('SideViewRegister-Alert-LoginError-ButtonLabel-Ok')]
                );
            }else if(this.redirectAfterLoginURL!=null){
                document.location.href=this.redirectAfterLoginURL;
            }

        })
    }

    _setErrorMessage(input, message){
        input.useNativeValidation = false;
        input.valid = false;
        input.helperTextContent = message;
    }

    _setValidInput(input, message = ''){
        input.useNativeValidation = true;
        input.valid = true;
        input.helperTextContent = message;
    }

    scrollFormToTop() {
        this.element.querySelector(".scrolling-content").scrollTop=0;
    }

    onChangeStep(e){
        if(this.step != '1'){
            this.step = this.step - 1;
            this.updateView();
        }
    }


    setDOB(choosenDate){
        var dateOfBirth = this.element.querySelector(".mdc-dob").MDCTextField

        var date18YearsAgo = new Date();
        date18YearsAgo.setYear(date18YearsAgo.getFullYear()-18);
        date18YearsAgo.setHours(0);
        date18YearsAgo.setMinutes(0);
        date18YearsAgo.setSeconds(0);

        dateOfBirth.value=`${choosenDate.getDate()}/${choosenDate.getMonth()+1}/${choosenDate.getFullYear()}`;
        //dateOfBirth.foundation_.adapter_.floatLabel(true);
        this.element.querySelector(".mdc-dob-mobile .mdc-floating-label").classList.add("mdc-floating-label--float-above");

        var over = (date18YearsAgo.getTime() > choosenDate.getTime());
        if(over){
            this._setValidInput(dateOfBirth);
        }else{
            console.log("Set error message for dob");
            this._setErrorMessage(dateOfBirth, APP_STRINGS.getStringById("459"));
        }

    }

    initializeDOBComponent() {
        var dpOverlay = this.element.querySelector(".date-picker-overlay");
        var dobInputElement = this.element.querySelector(".dob-input");
        var dateOfBirthMDC = this.element.querySelector(".mdc-dob").MDCTextField;
        var dobLabelElement=this.element.querySelector(".dob-holder .mdc-floating-label");





        var date18YearsAgo = new Date();
        date18YearsAgo.setYear(date18YearsAgo.getFullYear()-18);
        date18YearsAgo.setHours(0);
        date18YearsAgo.setMinutes(0);
        date18YearsAgo.setSeconds(0);

        var dt = new Date();
        var year = dt.getFullYear() - 18; // 18 years old
        var month = dt.getMonth()+1; //Js returning they key eg: JAN will be 0
        var day = dt.getDate();
        var _self=this;

        let config={
            dateFormat: "d/m/Y",
            onChange:function(e){

                var selectedDate = this.latestSelectedDateObj;
                console.log("On datepicker change function", e, this);
                console.log("selectedDate", selectedDate);
                console.log("selectedDate string", dpOverlay.value);

                dateOfBirthMDC.value=dpOverlay.value;

                dobLabelElement.classList.add("mdc-floating-label--float-above");
                dobLabelElement.classList.add("above");


                var over = (date18YearsAgo.getTime() > selectedDate.getTime());
                if(over){
                    _self._setValidInput(dateOfBirthMDC);
                }else{
                    _self._setErrorMessage(dateOfBirthMDC, APP_STRINGS.getStringById("459"));
                }



            }
        }
        if(Helpers.getDeviceType()=="desktop"){
            config.maxDate=day+"-"+month+"-"+year;
        }
        window.flatpickr(dpOverlay,config);

    }

    onMenuItemClick(e){
        let action=e.currentTarget.dataset.action;
        switch (action) {
            case "user":
                new SideViewUser();
                break;
            case "logout":
                USER.logout();
                break;
            case "search":
                if(this.searchView){
                    return;
                }
                console.log("launch search control");
                this.searchView=new SearchView(()=>{this.searchView=null;});
                this.close();
                break;
            case "help":
                window.location.href = window.helpLink;
                break;
            case "faqs":
                try {
                    window.location.href=document.querySelector("input[name='faqs-link']").value;
                }catch(e){
                    console.log("No FAQs link found on page")
                }
                break;
            default:
                console.log("unknown action");
        }
    }
}

SideViewRegister.ALIGNMENT=SideViewBaseClass.ALIGNMENT.LEFT;
import {html} from 'lit-html';
import {ExternalPaymentCallbackPopup} from "../../js/ui/ExternalPaymentCallbackPopup";
import {unsafeHTML} from "lit-html/directives/unsafe-html";
export const TemplateExternalPaymentCallbackSuccessContent = (el) => html`
    ${el.type==ExternalPaymentCallbackPopup.TYPE.SUCCESS || el.type==ExternalPaymentCallbackPopup.TYPE.PENDING ? html`
    <img src="${CDN_ASSETS_URL}/circle.svg">
    `:html`
    <img src="${CDN_ASSETS_URL}/warning.svg">
    `}
    
    <p>${unsafeHTML(el.message)}</p>
`;
import {Helpers} from "@/js/util/Helpers";
import {USER} from "./User";
import {Popup} from "@/js/ui/Popup";
import { Base64 } from "@/js/util/base64";
import {SideViewMenu} from "@/js/ui/SideViewMenu";
import {SideViewUser} from "@/js/ui/sideViews/SideViewUser";
import {SideViewResetPassword} from "@/js/ui/sideViews/SideViewResetPassword";

export const InitResetPasswordController=()=>{
    
var url = window.location.href;

if(url.indexOf("?resetCode=")>=0){
    var resetCode = Helpers.getUrlParameter('resetCode',url)

    var resetData= decodeURIComponent(resetCode.replace(/\+/g, ' '));


    resetData=Base64.decode(resetData);

    window.resetPasswordData  = JSON.parse(resetData);

    USER.playerResetPasswordWithResetCode(window.resetPasswordData.emailAddress, window.resetPasswordData.passwordResetCode)
    .then(data=>{
        console.log('resetpassword return')
        console.log(data)
        if(data.error){
            new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK])
        }else{
            new SideViewResetPassword(data)
        }

    });
}

}
import {render} from "lit-html";
import {TemplatePopup} from "../../templates/template-items/TemplatePopup";
import {POPUP_MANAGER} from "./PopupManager";

export class Popup {
    constructor(title, content, buttons, callback) {
        console.log("hello from popup");
        this.title=title;
        this.content=content;
        this.contentTemplate=null;
        this.buttons=buttons;
        this.callback=callback;
        POPUP_MANAGER.addPopup(this);
    }

    _init(popupDoneCallback){
        this.element=document.createElement("div");
        this.element.classList.add("popup-container");
        document.body.appendChild(this.element);
        render(this.template(this),this.element,{eventContext:this});
        this.popupDoneCallback=popupDoneCallback;
    }

    get template(){
        return TemplatePopup;
    }

    dontUseCloseButton(){
        if(this.element){
            this.element.querySelector(".close-btn").style.display="none";
        }
    }

    onButtonClick(e){
        console.log(e.currentTarget.dataset.buttonId);
        if(this.callback){
            this.callback(e.currentTarget.dataset.buttonId);
        }
        this.destroy();
    }

    onCloseButtonClick(){
        this.destroy();
    }

    destroy(){
        document.body.removeChild(this.element);
        this.popupDoneCallback();
        delete this;
    }


}
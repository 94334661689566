import {html} from 'lit-html';
import {APP_STRINGS} from "../../../js/core/AppStrings";

export const TemplateGameSection = (el) => html`
        <section class="games-section grid-view">
            ${(Object.keys(el.selectedDisplayFilter).length > 0 || el.selectedSorting.value != 'MostPopular') ? html`
                ${(!el.loading) ? html`<a class="game-category-title Heading-4" href="#">${APP_STRINGS.getWPMLString('GameFilters-SearchResult-Label')}</a>` : ``}
                ${(!el.loading) ? html`
                    <div class="game-items-container capp-scrollbar capp-scrollbar-dark">
                        ${displayFilterGameResult(el)}
                    </div>
                ` : ``}
            ` : ``}
        </section>
`;

export const TemplateDisplayFilterSection = (el) => html`
    <div>
        ${displayFilter(el)}
    </div>
    ${(Object.keys(el.selectedDisplayFilter).length > 0) ? html`
    <button class="clear-filter-button clear-all-btn" @click=${el.onClickRemoveFilter} data-filter='all'>${APP_STRINGS.getWPMLString('GameFilters-MobileFilters-ButtonLabel-Clear All')}</button>
    ` : ``}
`

function displayFilter(el){
    const displayList = [];
    if(el.selectedDisplayFilter){
        for (const [key, value] of Object.entries(el.selectedDisplayFilter)) {
            displayList.push(
                html`<button class="clear-filter-button" @click=${el.onClickRemoveFilter} data-filter='${key}'>${value.description}</button>`
            )
            
        }
        return displayList
    }
}

function displayFilterGameResult(el){
    const displayList = [];
    if(el.resultGameList){
        for (const [key, value] of Object.entries(el.resultGameList)) {
            displayList.push(html`
                <a href="${value.gameLink}">
                    <div class="game-item">
                        <div class="game-image">
                            <img class='lazy' data-src="${value.lobbyThumbnailUrl}">
                        </div>
                        <div class="game-title">${value.GameName}</div>
                    </div>
                </a>
            `)
        }
        return displayList
    }
}
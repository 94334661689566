import {Popup} from "./Popup";
import {USER} from "../core/User";
import {TemplatePopup} from "../../templates/template-items/TemplatePopup";
import {TemplateRestrictedCountryPopup} from "../../templates/template-items/TemplateRestrictedCountryPopup";
import {render} from "lit-html";
import {FormHelpers} from "../util/FormHelpers";

export class RestrictedCountryPopup extends Popup{
    constructor() {
        super();
        this.success=false;
        this.error=null;

    }

    _init(popupDoneCallback) {
        super._init(popupDoneCallback);
        FormHelpers.initializeFormComponents(this.element);
    }

    get template(){
        return TemplateRestrictedCountryPopup;
    }


    _updateView(){
        render(this.template(this),this.element,{eventContext:this});
    }

    onButtonClick(e){
        console.log(e.currentTarget.dataset.buttonId);
        console.log('submit email');
        this._submitEmail();
    }

    _submitEmail(){

        let email=this.element.querySelector('input.email-address').value;
        USER.sendCountryRestrictionEmail(email,window.csrfToken).then(data=>{
            console.log('country restricted response',data);
            if(data.error){
                console.log('error sending data',data);
                this.error=data.error.errorMessage;
            }else{
                this.error=null;
                this.success=true;
            }
            this._updateView();

        })
    }
}
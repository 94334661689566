import {ExternalPaymentCallbackPopup} from "../ui/ExternalPaymentCallbackPopup";
import {Popup} from "../ui/Popup";
import { SideViewBaseClass } from "../ui/SideViewBaseClass";
import { SideViewMenu } from "../ui/SideViewMenu";
import { SideViewBank } from "../ui/sideViews/SideViewBank";
import { SideViewLogin } from "../ui/sideViews/SideViewLogin";
import { SideViewRegister } from "../ui/sideViews/SideViewRegister";

export const ProcessQueryStrings=()=>{
    checkForExternalWindowPaymentCallbacks();
    checkForSideViewRegister();
    checkForSideViewLogin();
    checkForSideViewBank();
}

function checkForExternalWindowPaymentCallbacks(){
    var url = window.location.href;

    if(url.indexOf("deposit-success")>0){
        new ExternalPaymentCallbackPopup(ExternalPaymentCallbackPopup.TYPE.SUCCESS);
    }else if(url.indexOf("deposit-failure")>0){
        new ExternalPaymentCallbackPopup(ExternalPaymentCallbackPopup.TYPE.FAIL);
    }else if(url.indexOf("deposit-pending")>0){
        new ExternalPaymentCallbackPopup(ExternalPaymentCallbackPopup.TYPE.PENDING);
    }

}

function checkForSideViewRegister(){
    var url = window.location.href;

    if(url.indexOf("showPopUp=Register")>0){
        SideViewRegister.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
        new SideViewRegister();
    }
}

function checkForSideViewLogin(){
    var url = window.location.href;

    if(url.indexOf("showPopUp=Login")>0){
        SideViewLogin.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
        new SideViewLogin();
    }
}

function checkForSideViewBank(){
    var url = window.location.href;

    if(url.indexOf("showPopUp=Deposit")>0){
        SideViewBank.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
        new SideViewBank();
    }else if(url.indexOf("showPopUp=Withdraw")>0){
        SideViewBank.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
        new SideViewBank().menu.setSelectedItem(SideViewMenu.ITEM_TYPES.WITHDRAW);
    }else if(url.indexOf("showPopUp=History")>0){
        SideViewBank.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
        new SideViewBank().menu.setSelectedItem(SideViewMenu.ITEM_TYPES.HISTORY);
    }else if(url.indexOf("showPopUp=Bonuses")>0){
        SideViewBank.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
        new SideViewBank().menu.setSelectedItem(SideViewMenu.ITEM_TYPES.BONUSES);
    }else if(url.indexOf("showPopUp=Profile")>0){
        SideViewBank.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
        new SideViewBank().menu.setSelectedItem(SideViewMenu.ITEM_TYPES.PROFILE);
    }else if(url.indexOf("showPopUp=Preferences")>0){
        SideViewBank.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
        new SideViewBank().menu.setSelectedItem(SideViewMenu.ITEM_TYPES.PREFERENCES);
    }else if(url.indexOf("showPopUp=Verification")>0){
        SideViewBank.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
        new SideViewBank().menu.setSelectedItem(SideViewMenu.ITEM_TYPES.VERIFICATION);
    }else if(url.indexOf("showPopUp=ResponsibleGambling")>0){
        SideViewBank.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
        new SideViewBank().menu.setSelectedItem(SideViewMenu.ITEM_TYPES.RESPONSIBLE_GAMBLING);
    }
}
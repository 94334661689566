import {render} from "lit-html";
import {
    TemplateVerification,
    TemplateVerificationPaymentMethod,
    TemplateVerificationProofOfAddress,
    TemplateVerificationProofOfIdentification,
    TemplateVerificationTemp
} from "../../../../templates/template-items/side-view/verification/TemplateVerification";
import {SideViewSubBaseClass} from "../../SideViewSubBaseClass";
import {USER} from "../../../core/User";
import {Popup} from "../../Popup";
import {SlideOverView} from "../../SlideOverView";
import {Helpers} from "../../../util/Helpers";
import {SlideOverViewStatus} from "../../SlideOverViewStatus";

export class SideViewSubVerification extends SideViewSubBaseClass{
    constructor(container) {
        super(container);

    }

    _init() {
        //this.mode="temp";

        this.loadingData=true;
        this.verificationData=null;
        this.documentToUpload=null;
        this.documentUploading=false;
        this.selectedAccount=null;
        this.mobileMode=Helpers.getDeviceType()=="desktop" ? false : true;

        this._initializeUploadObject();
        this.getVerificationData();
        super._init();
    }

    renderView(){

        if(this.mode=="temp"){
            render(TemplateVerificationTemp(this),this.element,{eventContext:this});
        }else{
            render(TemplateVerification(this),this.element,{eventContext:this});
        }

    }

    _initializeUploadObject(){
        if(this.fileInput){
            this.fileInput.value="";
        }

        this.documentUploading=false;
        this.documentToUpload={
            file:null,
            fileType:null,
            image:null
        }
    }

    getVerificationData(){
        USER.getVerificationData()
            .then(data=>{
                this.loadingData=false;
                console.log("VERIFICATION DATA Is READY",data);
                this.verificationData=data;
                this.renderView();

            })
            .catch(error=>{
                this.loadingData=false;
                let title;
                let message;
                if(error.title){
                    title=error.errorTitle;
                    message=error.errorMessage;
                }else{
                    title=APP_STRINGS.ERROR;
                    message=error;
                }
                new Popup(title,message,[APP_STRINGS.OK]);
            })
    }

    onProofOfAddressClick(){
        if(this.verificationData.basicDocs.proofOfAddress!="required"){
            return;
        }
        this._initializeUploadObject();
        this.slideOver=new SlideOverView(this.container.parentElement,TemplateVerificationProofOfAddress,this);
        this.collectDocControls();
    }

    onProofOfIdentificationClick(){
        if(this.verificationData.basicDocs.proofOfIdentification!="required"){
            return;
        }
        this._initializeUploadObject();
        this.slideOver=new SlideOverView(this.container.parentElement,TemplateVerificationProofOfIdentification,this);
        this.collectDocControls();
    }

    onPaymentMethodValidationClick(e){
        console.log("PAYMENT METHOD SELECTED",e.currentTarget.dataset.accountIndex,this.verificationData.accountsVerification[e.currentTarget.dataset.accountIndex]);
        this.selectedAccount=this.verificationData.accountsVerification[e.currentTarget.dataset.accountIndex];
        if(this.selectedAccount.status!="required"){
            return;
        }
        this._initializeUploadObject();
        this.slideOver=new SlideOverView(this.container.parentElement,TemplateVerificationPaymentMethod,this);
        this.collectDocControls();
    }

    onSubmitButtonClick(e){
        console.log("Submit",e.currentTarget.dataset.documentId);
        let docId=parseInt(e.currentTarget.dataset.documentId);
        let accountRefference=e.currentTarget.dataset.accountReference;

        this.documentUploading=true;
        this.slideOver.update();
        let p=null;
        if(docId==USER.VERIFICATION_DOCUMENT_TYPE.ID || docId==USER.VERIFICATION_DOCUMENT_TYPE.PROOF_OF_ADDRESS){
            p=USER.uploadBasicDocument(docId,this.documentToUpload.file);
        }else{
            console.log("Upload account doc",e.currentTarget.dataset.accountId,e.currentTarget.dataset.accountReference);
            p=USER.uploadAccountDocument(e.currentTarget.dataset.accountId,e.currentTarget.dataset.accountReference,this.documentToUpload.file);
        }

        p.then(data=>{
            this.documentUploading=false;
            this.slideOver.close();

            if(data.error){
                new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);
            }else{
                switch (docId) {
                    case USER.VERIFICATION_DOCUMENT_TYPE.ID:
                        console.log("ID")
                        this.verificationData.basicDocs.proofOfIdentification="pending";
                        break;
                    case USER.VERIFICATION_DOCUMENT_TYPE.PROOF_OF_ADDRESS:
                        console.log("ADDRESS")
                        this.verificationData.basicDocs.proofOfAddress="pending";
                        break;
                    default:
                        for(let i=0; i<this.verificationData.accountsVerification.length; i++){
                            if(this.verificationData.accountsVerification[i].AccountReference==accountRefference){
                                this.verificationData.accountsVerification[i].status="pending";
                                break;
                            }
                        }
                }

                this.renderView();
                let title=APP_STRINGS.getWPMLString('SideViewUser-Verification-DocumentUploadSuccess-Title');
                let msg=APP_STRINGS.getWPMLString('SideViewUser-Verification-DocumentUploadSuccess-Message');
                let btnLabel=APP_STRINGS.getWPMLString('SideViewUser-Verification-DocumentUploadSuccess-ButtonLabel-Continue');
                new SlideOverViewStatus(this.container.parentElement,SlideOverViewStatus.STATUS_VIEW_TYPE.SUCCESS,title, msg,[btnLabel],(btnId)=>{});

                //#14865 - RR - glitch on Verification page
                USER.updateUserData()
                    .then(() => {
                        console.log(USER.data, 'USER.data after updateUserData');
                    });
            }

        })

    }

    collectDocControls(){
        let dropFileArea=this.slideOver.element.querySelector(".inner-container");
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            dropFileArea.addEventListener(eventName, e=>{
                e.preventDefault()
                e.stopPropagation()
            }, false)
        })


        this.fileInput=this.slideOver.element.querySelector('input[type="file"]');

    }

    onSlideOverBackButtonClick(){
        this.slideOver.close();
        this.slideOver=null;
        this._initializeUploadObject();
        this.renderView();

    }

    onFileDropped(e){

        e.preventDefault();
        e.stopPropagation();
        let dt = e.dataTransfer
        let files = dt.files;
        console.log("File dropped",files);
        this._handleFileOpen(files[0]);
    }

    onSelectFileClick(){
        this.fileInput.click();
    }

    onFileSelected(){
        console.log("file selected", this.fileInput);
        if(this.fileInput.files.length>0){
            this._handleFileOpen(this.fileInput.files[0]);
        }

    }

    onClearSelectedFile(){
        this._initializeUploadObject();
        this.slideOver.update();
    }


    _handleFileOpen(file) {

        if (file.type != 'image/png' && file.type != 'image/gif' && file.type != 'image/jpeg' && file.type != 'image/pjpeg' && file.type != 'application/pdf') {

            //"Sorry, this is an unsupported file type. Needs to be a jpg, png, pff or doc file.
            new Popup(APP_STRINGS.getTitleById("2224019"),APP_STRINGS.getStringById("2224019"),[APP_STRINGS.OK])
            return;
        }

        if (file.size > 3145728) {
            //"The file needs to be smaller than 3 MB.
            new Popup(APP_STRINGS.getTitleById("2224020"),APP_STRINGS.getStringById("2224020"),[APP_STRINGS.OK])
            return;
        }

        this.documentToUpload.file=file;
        this.documentToUpload.fileType=file.type;

        if (file.type == "application/pdf") {
            //show pdf placeholder image
            this.slideOver.update();
        }else{
            //show document image
            let reader = new FileReader();
            reader.onload = e=> {
                this.documentToUpload.imageURL=e.target.result;
                this.slideOver.update();
            };
            reader.readAsDataURL(file);
        }



    }
}
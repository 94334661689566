import {SideViewSubWithdrawal} from "./SideViewSubWithdrawal";
import {BANK} from "../../../core/Bank";
import {Popup} from "../../Popup";
import {SlideOverView} from "../../SlideOverView";
import {TemplateDepositIframePaymentSlideOver} from "../../../../templates/template-items/side-view/TemplateDepositContentView";
import {USER} from "../../../core/User";

export class SideViewSubPIQWithdrawal extends SideViewSubWithdrawal{
    constructor(container) {
        super(container);

    }

    _init() {
        console.log('PIQ withdrawals');
        this.PIQWithdrawals=true;
        super._init();
    }

    _setupEventListeners(){
        super._setupEventListeners();
        USER.addListener("onUserBalanceUpdate",()=>{
            this.updateView();
        });

    }

    getWithdrawalData(){
        BANK.getPIQWithdrawalData().then((withdrawalDataResponse => {
            console.log(withdrawalDataResponse);
            this.withdrawalData = withdrawalDataResponse;
            this.loadingWithdrawalData = false;
            this.presetValues = this.getPresetValues();
        }));
    }

    _startWithdrawal() {
        this.loadingIframeURL=true;
        this.paymentIframeURL=null;
        this.processingWithdrawal = true;
        this.paymentSlideoverTitle=BANK.withdrawalSettings.selectedMethod.CappPaymentDisplayName;

        this.updateView();



        BANK.withdrawPIQ().then(data=>{
            console.log('PIQ withdrawal data',data);
            if(data.error){

                new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK]);

            }else{
                this.loadingIframeURL=false;
                this.paymentIframeURL=data;
                this.iframePaymentSlideOver=new SlideOverView(this.container.parentElement,TemplateDepositIframePaymentSlideOver,this);

                //this.iframePaymentSlideOver.update();
            }
            this.processingWithdrawal = false;
            this.updateView();

        })

    }

    onSlideOverPaymentIframeBackButtonClick(){
        this.iframePaymentSlideOver.close();
        this.iframePaymentSlideOver=null;
        USER.updatePlayerBalance();
    }


}
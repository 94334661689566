import {DepositAdditionalFormBase} from "./DepositAdditionalFormBase";
import {Template_CryptoPay_Deposit_AdditionlForm} from "../../../../../templates/template-items/side-view/bank-paymetns-forms/Template_CryptoPay_Deposit";
import WAValidator from 'multicoin-address-validator';

export class CryptoPayAdditionalForm extends DepositAdditionalFormBase{
    constructor(container, currencyISO, callback) {
        super(container,callback);
        this.currencyISO=currencyISO;
        this._init();
    }

    _getFormTemplate(){
        return Template_CryptoPay_Deposit_AdditionlForm;
    }
    _validateInput(input){
        let method=input.root_.dataset.validationMethod;
        if(method===undefined){
            return true;
        }

        let valid=false;
        switch (method) {
            case "btc-address":
                /*
                    P2PKH which begin with the number 1, eg: 1BvBMSEYstWetqTFn5Au4m4GFg7xJaNVN2.
                    P2SH type starting with the number 3, eg: 3J98t1WpEZ73CNmQviecrnyiWrnqRhWNLy.
                    Bech32 type starting with bc1, eg: bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq
                    WAValidator Source: https://www.npmjs.com/package/multicoin-address-validator
                */
                if(WAValidator.validate(input.value, 'btc')){
                    valid=true;
                }
                break;

            case "eth-address":
                /*
                    A regular expression for matching Ethereum addresses must check for a leading 0x followed by a random string of 40 hexadecimal characters (lowercase a-f, uppercase A-F, and numbers 0-9).
                    These expressions are not case sensitive, although a capitalized checksum version exists that refers to the same account but provides an added layer of security.
                */
                if(WAValidator.validate(input.value, 'eth')){
                    valid=true;
                }
                break;

            case "ltc-address":
                if(WAValidator.validate(input.value, 'ltc')){
                    valid=true;
                }
                break;

            case "bch-address":
                if(WAValidator.validate(input.value, 'bch')){
                    valid=true;
                }
                break;

            case "usdt-address":
                // USDT can be validated either as a BTC or ETH address, depending on the network type.
                // Basically it goes like that: If cryptocurrency is USDT and chain type is ERC20, validate the address against ETH address format.
                // If cryptocurrency is USDT and wallet type is OMNI, validate the address against BTC address format
                if(WAValidator.validate(input.value, 'usdt')){
                    valid=true;
                }
                break;

            case "usdc-address":
                if(WAValidator.validate(input.value, 'usdc')){
                    valid=true;
                }
                break;

            case "dai-address":
                if(WAValidator.validate(input.value, 'dai')){
                    valid=true;
                }
                break;

            default:
                valid=false;
        }

        this._setInputState(input,valid);
        return valid;
    }
}
import {SideViewBaseClass} from "../SideViewBaseClass";
import {render} from "lit-html";
import {TemplateGameInfoSideView} from "../../../templates/template-items/game-templates/TemplateGameInfoSideView";
import {SIDE_VIEW_MANAGER} from "../SideViewManager";


export class SideViewGameInfo extends SideViewBaseClass{
    constructor(game) {
        super("game-info-sidebar",SideViewBaseClass.TYPE.NORMAL);
        this.game=game;
        this.game.on("GameDataChanged",()=>{
            this.renderViewContent();
        })
        SIDE_VIEW_MANAGER.openSideView(this);
    }

    _init() {
        this.element=document.createElement("div");
        this.element.id=this.id;
        this.element.classList.add("side-view");
        if(this.type.length>0){
            this.element.classList.add(this.type);
        }

    }

    renderViewContent() {
        render(TemplateGameInfoSideView(this),this.element,{eventContext:this});
    }

    onFavouriteButtonClick(){
        console.log("favourite button clicked");
        this.game.toggleFavouriteFlag();
        this.renderViewContent();
    }

}

export class PreloaderElement extends HTMLElement{
    constructor() {
        super();
        this.classList.add('preloader');
    }

    connectedCallback() {
        console.log('Custom preloader element added to page.');
        //updateStyle(this);
    }



}

customElements.define('preloader-element',PreloaderElement);


import {html} from 'lit-html';

export const TemplateMobileFilterMenuView = (el) => html`
    <div class="view-heading">
        <div class="item-left">
            <span class="title">${APP_STRINGS.getWPMLString('GameFilters-MobileFilters-Title')}</span>
        </div>
        <div class="item-right">
            <img @click="${el.close}" class="close-img" src="${CDN_ASSETS_URL}/close.svg">
        </div>
    </div>
    <div class="content">
        <ul class="mobile-filter-menu">
            ${displayFilterType(el)}
            <li @click=${el.onClickClearAllFilter} data-filter="all"><span>${APP_STRINGS.getWPMLString('GameFilters-MobileFilters-ButtonLabel-Clear All')}</span> <img src="${CDN_ASSETS_URL}/close.svg"></li>
        </ul>
        <button class="primary-button filter-btn ${el.loading ? 'loading' : ''}" @click=${el.onClickApplyFilter} data-filter="filter"><span>${APP_STRINGS.getWPMLString('GameFilters-MobileFilters-ButtonLabel-ApplyFilters')}</span><preloader-element></preloader-element></button>
    </div>
`

function displayFilterType(el){
    const displayList = [];
    for (const [key, value] of Object.entries(el.filterType)) {
        var totalFilter = (el.selectedFilter[value.filterKey].length > 0) ? '('+el.selectedFilter[value.filterKey].length+')' : '';
        displayList.push(html`
            <li @click=${el.onClickFilterType} data-filter-type-id="${key}"><span>${value.displayName} ${totalFilter}</span> <img src="${CDN_ASSETS_URL}/right-2.svg"></li>
        `)
    }
    return displayList;
}


export const TemplateMobileSortingView = (el) => html`
    <div class="view-heading">
        <div class="item-left">
            <span class="title">Sort</span>
        </div>
        <div class="item-right">
            <img @click="${el.close}" class="close-img" src="${CDN_ASSETS_URL}/close.svg">
        </div>
    </div>
    <div class="content">
        <div class="mdc-form-field full-width">
            <label for="radio-mobile-f1">Most popular
                <div class="mdc-radio mdc-sorting">
                    <input class="mdc-radio__native-control" type="radio" id="radio-mobile-f1" name="radios-filter" value="MostPopular" checked>

                    <div class="mdc-radio__background">
                        <div class="mdc-radio__outer-circle"></div>
                        <div class="mdc-radio__inner-circle"></div>
                    </div>
                    <div class="mdc-radio__ripple"></div>
                </div>
            </label>
        </div>
        <div class="mdc-form-field full-width">
            <label for="radio-mobile-f2">A-Z
                <div class="mdc-radio mdc-sorting">
                    <input class="mdc-radio__native-control" type="radio" id="radio-mobile-f2" name="radios-filter" value="ASC">

                    <div class="mdc-radio__background">
                        <div class="mdc-radio__outer-circle"></div>
                        <div class="mdc-radio__inner-circle"></div>
                    </div>
                    <div class="mdc-radio__ripple"></div>
                </div>
            </label>
        </div>
        <div class="mdc-form-field full-width">
            <label for="radio-mobile-f3">Z-A
                <div class="mdc-radio mdc-sorting">
                    <input class="mdc-radio__native-control" type="radio" id="radio-mobile-f3" name="radios-filter" value="DESC">

                    <div class="mdc-radio__background">
                        <div class="mdc-radio__outer-circle"></div>
                        <div class="mdc-radio__inner-circle"></div>
                    </div>
                    <div class="mdc-radio__ripple"></div>
                </div>
            </label>
        </div>
        <button class="primary-button filter-btn ${el.loading ? 'loading' : ''}" @click=${el.onClickApplyFilter} data-filter="sorting"><span>${APP_STRINGS.getWPMLString('GameFilters-MobileFilters-ButtonLabel-Sort')}</span><preloader-element></preloader-element></button>
    </div>
`
import {html} from 'lit-html';

export const TemplateNotificationSideView = (el) => html`
    <div class="view-heading">
        <div class="item-left">
            <img class="back-img" src="${CDN_ASSETS_URL}/left.svg" @click=${el.close}>
            <span class="title">Notifications</span>
            <div class="notification-number">1</div>
        </div>
    </div>
    <div class="content">
        <div class="notification-container">
            <div class="notification-box unread">
                <div class="header">
                    <span class="Paragraph-Bold">Notification Heading</span>
                    <div class="notification-status"></div>
                </div>
                <p>
                    Description about whatever the notification is. Could be anything but whatever it is it goes here.
                </p>
                <a class="Text-Link">Text link</a>
            </div>
            <div class="notification-box">
                <div class="header">
                    <span class="Paragraph-Bold">Notification Heading</span>
                    <div class="notification-status"></div>
                </div>
                <p>
                    Description about whatever the notification is. Could be anything but whatever it is it goes here.
                </p>
                <a class="Text-Link">Text link</a>
            </div>
            <div class="notification-box">
                <div class="header">
                    <span class="Paragraph-Bold">Notification Heading</span>
                    <div class="notification-status"></div>
                </div>
                <p>
                    Smaller description
                </p>
                <a class="Text-Link">Text link</a>
            </div>
            <div class="notification-box">
                <div class="header">
                    <span class="Paragraph-Bold">Notification Heading</span>
                    <div class="notification-status"></div>
                </div>
                <p>
                    Smaller description
                </p>
                <a class="Text-Link">Text link</a>
            </div>
            <div class="notification-box">
                <div class="header">
                    <span class="Paragraph-Bold">Notification Heading</span>
                    <div class="notification-status"></div>
                </div>
                <p>
                    Smaller description
                </p>
                <a class="Text-Link">Text link</a>
            </div>
        </div>
    </div>
`
import {render} from "lit-html";
import {Helpers} from "../util/Helpers";
import {USER} from "./User";
import {SideViewBaseClass} from "../ui/SideViewBaseClass";
import {SideViewRegister} from "@/js/ui/sideViews/SideViewRegister";
import {SideViewBank} from "@/js/ui/sideViews/SideViewBank";
import {SideViewMenu} from "@/js/ui/SideViewMenu";
import {SideViewLogin} from "@/js/ui/sideViews/SideViewLogin";
import {SideViewUser} from "@/js/ui/sideViews/SideViewUser";
import {Popup} from "@/js/ui/Popup";
import {SideViewRegisterShort} from "../ui/sideViews/SideViewRegisterShort";
import {TemplateForgotPasswordSideView} from "../../templates/template-items/side-view/TemplateForgotPasswordSideView";
import {SlideOverViewStatus} from "@/js/ui/SlideOverViewStatus";
import {SIDE_VIEW_MANAGER} from "@/js/ui/SideViewManager";

export const InitGlobalActionController=()=>{
    var url = document.location.href;

    $('body').on("click", ".global-register", function(e){
        e.preventDefault()
        if(window.isSimplifiedSignUpForm){
            new SideViewRegisterShort();
        }else{
            new SideViewRegister();
        }
    })

    $('body').on("click", ".global-login", function(e){
        e.preventDefault()
        new SideViewLogin();
    })

    $('body').on("click", ".global-deposit", function(e){
        e.preventDefault()
        if(USER.isLoggedIn){
            new SideViewBank(SideViewMenu.ITEM_TYPES.DEPOSIT);
        }else{
            new SideViewLogin();
        }
    })

    $('body').on("click", ".global-withdraw", function(e){
        e.preventDefault()
        if(USER.isLoggedIn){
            new SideViewBank(SideViewMenu.ITEM_TYPES.WITHDRAW);
        }else{
            new SideViewLogin();
        }
    })

    $('body').on("click", ".global-bonuses", function(e){
        e.preventDefault()
        if(USER.isLoggedIn){
            new SideViewBank(SideViewMenu.ITEM_TYPES.BONUSES);
        }
    })

    $('body').on("click", ".global-register-deposit", function(e){
        e.preventDefault()
        if(USER.isLoggedIn){
            new SideViewBank(SideViewMenu.ITEM_TYPES.DEPOSIT);
        }else{
            new SideViewRegister();
        }
    })

    $('body').on("click", ".global-chat", function(e){
        e.preventDefault()
        window.LC_API.open_chat_window();
    })

    $('body').on("click", ".global-reset-password", function(e){
        e.preventDefault()
        var email = $(".global-reset-password-email").val();
        console.log(email);

        USER.resetPassword(email)
        .then(data=>{
            if(data.error){
                // this.loading=false;
                new Popup(data.error.errorTitle,data.error.errorMessage,[APP_STRINGS.OK])
            }else{
                // this.loading=false;
                new Popup(APP_STRINGS.getWPMLString('SideViewForgotPassword-SuccessView-Title'),APP_STRINGS.getWPMLString('SideViewForgotPassword-SuccessView-Message'),[APP_STRINGS.OK])
            }

        });
    })

    USER.on("onLoginStateChange",()=>{
        {
            if(USER.isLoggedIn){
                $('.global-login').hide()
                $('.global-register').hide()
                $('.global-register-deposit').html('DEPOSIT NOW')
                $('.global-bonuses').show()
                $('.global-withdraw').show()
                $('.global-deposit').show()
            }else{
                $('.global-login').show()
                $('.global-register').show()
                $('.global-register-deposit').html('SIGN UP NOW')
                $('.global-bonuses').hide()
                $('.global-withdraw').hide()
                $('.global-deposit').hide()
            }
            routeURLAnchor();
        }
    })

    if(USER.isLoggedIn){
        $('.global-register-deposit').html('DEPOSIT NOW')
        $('.global-bonuses').show()
        $('.global-register').hide()
        $('.global-login').hide()
        $('.global-withdraw').show()
        $('.global-deposit').show()
    }else{
        $('.global-register-deposit').html('SIGN UP NOW')
        $('.global-bonuses').hide()
        $('.global-register').show()
        $('.global-login').show()
        $('.global-withdraw').hide()
        $('.global-deposit').hide()

        //global actions triggered by URLs pattern
        if (url.indexOf('#register') > -1) {
            new SideViewRegister();
        }
        else if(url.indexOf('?sessionKey=') > -1) {
            var sessionKey = Helpers.getUrlParameter('sessionKey', url);
            if(sessionKey != ''){
                USER.loginBySessionKey(sessionKey);
            }
            else{
                console.log('sessionKey is empty');
            }
        }
    }

    routeURLAnchor();

    //listen for global action events - iframe banners
    window.addEventListener('message',function(e){
        var allowedDomains = [window.location.origin, 'https://www.pokiemails.com', 'https://www.parlourmails.com', 'https://media.rr-cdn.com', 'https://media.urbancasino.net', 'https://cdn.urbancasino.net', 'https://cdn.vipwinnersclub.com'];

        if (allowedDomains.indexOf(e.origin) === -1){return;}
        if(e.data.type=='global-action' && e.data.action!='' && (typeof e.data.action) !== 'undefined'){
            console.log(e.data.type, e.data.action);
            $('<span class="'+e.data.type+' '+e.data.action+'"><!--Global Action--></span>').appendTo('body')
            .trigger('click')
            .remove();
        }
    })

    function routeURLAnchor(){
        if(url.indexOf('#deposit') > -1){
            if(USER.isLoggedIn){
                SideViewBank.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
                new SideViewBank(SideViewMenu.ITEM_TYPES.DEPOSIT);
            }else{
                SideViewLogin.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
                new SideViewLogin();
            }
        }
        else if(url.indexOf('#withdraw') > -1){
            if(USER.isLoggedIn){
                SideViewBank.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
                new SideViewBank().menu.setSelectedItem(SideViewMenu.ITEM_TYPES.WITHDRAW);
            }else{
                SideViewLogin.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
                new SideViewLogin();
            }
        }
        else if(url.indexOf('#history') > -1){
            if(USER.isLoggedIn){
                SideViewBank.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
                new SideViewBank().menu.setSelectedItem(SideViewMenu.ITEM_TYPES.HISTORY);
            }else{
                SideViewLogin.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
                new SideViewLogin();
            }
        }
        else if(url.indexOf('#bonuses') > -1){
            if(USER.isLoggedIn){
                SideViewBank.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
                new SideViewBank().menu.setSelectedItem(SideViewMenu.ITEM_TYPES.BONUSES);
            }else{
                SideViewLogin.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
                new SideViewLogin();
            }
        }
        else if(url.indexOf('#profile') > -1){
            if(USER.isLoggedIn){
                SideViewUser.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
                new SideViewUser().menu.setSelectedItem(SideViewMenu.ITEM_TYPES.PROFILE);
            }else{
                SideViewLogin.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
                new SideViewLogin();
            }
        }
        else if(url.indexOf('#preferences') > -1){
            if(USER.isLoggedIn){
                SideViewUser.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
                new SideViewUser().menu.setSelectedItem(SideViewMenu.ITEM_TYPES.PREFERENCES);
            }else{
                SideViewLogin.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
                new SideViewLogin();
            }
        }
        else if(url.indexOf('#verification') > -1){
            if(USER.isLoggedIn){
                SideViewUser.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
                new SideViewUser().menu.setSelectedItem(SideViewMenu.ITEM_TYPES.VERIFICATION);
            }else{
                SideViewLogin.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
                new SideViewLogin();
            }
        }
        else if(url.indexOf('#responsible-gambling') > -1){
            if(USER.isLoggedIn){
                SideViewUser.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
                new SideViewUser().menu.setSelectedItem(SideViewMenu.ITEM_TYPES.RESPONSIBLE_GAMBLING);
            }else{
                SideViewLogin.ALIGNMENT=SideViewBaseClass.ALIGNMENT.RIGHT;
                new SideViewLogin();
            }
        }
    }
}

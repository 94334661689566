import {html} from 'lit-html';
export const Template_BankTransfer_Withdrawal= el =>html`
    <form class="payment-method-form ${el._loading? 'loading' : ''} ${el._disabled ? 'disabled' : ''}">
        ${el.existingAccounts ? html`
             <div class="form-element-2" id="existing accounts">
             
                <div class="mdc-select" data-field="existingAccount">
                    <div class="mdc-select__anchor">
                        <i class="mdc-select__dropdown-icon"></i>
        
                        <div id="demo-selected-text" class="mdc-select__selected-text" role="button" aria-haspopup="listbox2"
                             aria-labelledby="demo-label demo-selected-text"></div>
                        <span id="demo-label"
                              class="mdc-floating-label mdc-floating-label--float-above">
                                ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldLabel-ExistingAccounts')}
                        </span>
        
                        <div class="mdc-line-ripple"></div>
                    </div>
                    <div class="mdc-select__menu mdc-menu mdc-menu-surface" role="listbox2">
                        <ul class="mdc-list">
                            <li class="mdc-list-item" data-value="new-account" role="option">
                                ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-ExistingAccounts-ItemLabel-NewAccount')}
                            </li>
                            ${el.existingAccounts.map(account=>html`
                            <li class="mdc-list-item" data-value="${account.custom_attribute_key}" role="option">${account.bankName} ${account.bankAccountNumber}</li>
                            `)}
                        </ul>
                    </div>
                </div>
            </div>
        ` : ''}
        <div class="form-element-2">
            <label class="mdc-text-field" data-validation-method="required" data-field="accountName">
                <div class="mdc-text-field__ripple"></div>
                <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" aria-controls="account-helper-text"  name="bankAccountHolderName">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldLabel-NameOnAccount')}
                    </span>
                <div class="mdc-line-ripple"></div>
            </label>
            <div class="mdc-text-field-helper-line">
                <div class="mdc-text-field-helper-text" aria-hidden="true" id="account-helper-text">
                    ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldValidation-NameOnAccount')}
                </div>
            </div>
        </div>
        
        <div class="form-element-2">
            <label class="mdc-text-field" data-field="bankCountry">
                <div class="mdc-text-field__ripple"></div>
                <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" value="${el.country}" name="bankCountry" disabled>
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldLabel-BankCountry')}
                    </span>

                <div class="mdc-line-ripple"></div>
            </label>
        </div>
        
        <div class="form-element-2">
            <label class="mdc-text-field" data-validation-method="required" data-field="bankName">
                <div class="mdc-text-field__ripple"></div>
                <input class="mdc-text-field__input"  aria-labelledby="my-label-id" name="bankName">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldLabel-BankName')}
                    </span>
                <div class="mdc-line-ripple"></div>
            </label>
            <div class="mdc-text-field-helper-line">
                <div class="mdc-text-field-helper-text" aria-hidden="true">
                    ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldValidation-BankName')}
                </div>
            </div>
        </div>
        <div class="form-element-2">
            <label class="mdc-text-field" data-validation-method="required" data-field="bankIBAN">
                <div class="mdc-text-field__ripple"></div>
                <input class="mdc-text-field__input" type="number" aria-labelledby="my-label-id" name="bankAccountNumber">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldLabel-AccountNumberOrIBAN')}
                    </span>
                <div class="mdc-line-ripple"></div>
            </label>
            <div class="mdc-text-field-helper-line">
                <div class="mdc-text-field-helper-text" aria-hidden="true">
                    ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldValidation-AccountNumberOrIBAN')}
                </div>
            </div>
        </div>
        <div class="form-element-2">
            <label class="mdc-text-field" data-validation-method="required" data-field="bankSortCode">
                <div class="mdc-text-field__ripple"></div>
                <input class="mdc-text-field__input" type="number" aria-labelledby="my-label-id" name="bankBSBCode">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldLabel-BSDOrSortCode')}
                    </span>
                <div class="mdc-line-ripple"></div>
            </label>
            <div class="mdc-text-field-helper-line">
                <div class="mdc-text-field-helper-text" aria-hidden="true">
                    ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldValidation-BSDOrSortCode')}
                </div>
            </div>
        </div>
        <div class="form-element-2">
            <label class="mdc-text-field" data-field="bankSwiftCode">
                <div class="mdc-text-field__ripple"></div>
                <input class="mdc-text-field__input" type="number" aria-labelledby="my-label-id" name="swift">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldLabel-BICorSWIFT')}
                    </span>
                <div class="mdc-line-ripple"></div>
            </label>
            <div class="mdc-text-field-helper-line">
                <div class="mdc-text-field-helper-text" aria-hidden="true">
                    ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldValidation-BICorSWIFT')}
                </div>
            </div>
        </div>
        <div class="separator-text"><span>or</span></div>
        <div class="form-element-2">
            <label class="mdc-text-field"  data-field="bankAddress">
                <div class="mdc-text-field__ripple"></div>
                <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="bankAddress">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldLabel-BankAddress')}
                    </span>
                <div class="mdc-line-ripple"></div>
            </label>
            <div class="mdc-text-field-helper-line">
                <div class="mdc-text-field-helper-text" aria-hidden="true">
                    ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldValidation-BankAddress')}
                </div>
            </div>
        </div>
        <div class="form-element-2">
            <label class="mdc-text-field"  data-field="bankAddress2">
                <div class="mdc-text-field__ripple"></div>
                <input class="mdc-text-field__input" type="text" aria-labelledby="my-label-id" name="bankAddress2">
                    <span class="mdc-floating-label" id="my-label-id">
                        ${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-BankTransfer-Form-FieldLabel-BankAddress2')}
                    </span>
                <div class="mdc-line-ripple"></div>
            </label>
        </div>
        
        <div class="form-element-2">
            <button type="button" class="primary-button ${el._loading ? 'loading' : ''}" @click=${el._onWithdrawButtonClick} >
                <span>${APP_STRINGS.getWPMLString('SideViewBank-Withdrawal-PaymentMethodSection-ButtonLabel-Withdraw')}</span>
                <preloader-element></preloader-element>
            </button>
        </div>
    </form>
    
        
    
`;
import {USER} from "../core/User";
import {TemplateGameSidebar, TemplateSidebar} from "../../templates/template-items/TemplateSidebar";
import {render} from "lit-html";
import {SideViewLogin} from "./sideViews/SideViewLogin";
import {SideViewUser} from "./sideViews/SideViewUser";
import {SideViewBank} from "./sideViews/SideViewBank";
import {SideViewNotifications} from "./sideViews/SideViewNotifications";
import {SideViewRegister} from "./sideViews/SideViewRegister";
import {SideViewMainMenu} from "./sideViews/SideViewMainMenu";
import {SIDE_VIEW_MANAGER} from "./SideViewManager";
import {SideViewGameInfo} from "./sideViews/SideViewGameInfo";
import {EventEmitter} from 'events';
import {Helpers} from "../util/Helpers";
import {SearchView} from "./SearchView";

const BASE_URL=window.location.origin;
const LANGUAGE=Helpers.getCookie('_icl_current_language');

export class Sidebar extends EventEmitter{



    constructor(type, additionalData) {
        super();
        this.element=document.getElementById("sidebar");
        this.gameInfoContent=null;
        this.type=type ? type : Sidebar.TYPE.NORMAL;
        this.mode=Sidebar.MODE.LOGGED_OUT;
        this.additionalData=additionalData;
        this.userData=null;
        this.linkItems=[];
        this.gameCategoryLinkItems=[];
        this.collapseOverrideFunction=null;
        if(this.element){
            this._init();
        }
    }

    _init() {
        console.log("Initializing sidebar");

        if(USER.isLoggedIn){
            this.mode=Sidebar.MODE.LOGGED_IN;
            this.userData=USER.data;
            this.isShownDepositAmount = false;
        }

        if(Helpers.getDeviceType()=="desktop"){
            this.element.classList.add("open");
        }


        if(this.type==Sidebar.TYPE.GAME){
            console.log("getting game info");
            this.gameInfo=this.additionalData;
            this.gameInfo.on("GameDataChanged",()=>{
                this._updateSidebar();
            })
        }

        if(this.type==Sidebar.TYPE.GAME && this.mode == Sidebar.MODE.LOGGED_IN){
            this.element.classList.add("in-game-sidebar");
        }
        this.collectLinkItems();
        this._setupEventListeners();
        this._renderSidebar();

        this.logo=this.element.querySelector(".logo-expanded");

        if(this.logo){
            let loadedHandler=()=>{
                this.logo.removeEventListener("load",loadedHandler);
                //if this is a gif logo, restart every 8 seconds
                if(this.logo.src.indexOf(".gif")>0){
                    setInterval(()=>{
                        this.logo.src=this.logo.src;
                    },8000);
                }
            }
            this.logo.addEventListener("load",loadedHandler);
        }
    }

    _setupEventListeners(){

        USER.addListener("onLoginStateChange",e=>{
            console.log(e);
            this.userData=USER.data;
            if(e.loggedIn){
                this.mode=Sidebar.MODE.LOGGED_IN;
            }else{
                this.mode=Sidebar.MODE.LOGGED_OUT;
                SIDE_VIEW_MANAGER.closeAnySideview();
            }

            if(this.type==Sidebar.TYPE.GAME && this.mode == Sidebar.MODE.LOGGED_IN){
                this.element.classList.add("in-game-sidebar");
            }else{
                this.element.classList.remove("in-game-sidebar");
            }
            this._renderSidebar();
        })

        USER.addListener("onUserBalanceUpdate",()=>{
            let bDisplay=this.element.querySelector(".current-balance-display");
            if(bDisplay){
                bDisplay.innerHTML=this.userData.CurrentBalanceDisaply;
            }
        })

        if(Helpers.getDeviceType()=='mobile'){
            this.initCloseOnOutsideClick();
        }

    }

    collectLinkItems(){
        let elements=document.querySelectorAll(".additional-links")
        this.linkItems=[];
        this.gameCategoryLinkItems=[];
        elements.forEach(item=>{
            let linkObj={
                type:item.name,
                url:item.value,
                icon:item.dataset.icon,
                title:item.dataset.sidebarTitle,
                requiresLoggedIn:item.dataset.requireLogin=="yes" ? true :false
            };
            if(linkObj.type.indexOf("game-category-link")>=0){
                this.gameCategoryLinkItems.push(linkObj);
            }else{
                this.linkItems.push(linkObj);
            }

        })
        console.log("this.linkItems",this.linkItems)
        console.log("this.gameCategoryLinkItems",this.gameCategoryLinkItems)
    }

    initCloseOnOutsideClick(){
        let contentElement=document.getElementById("content-container");
        contentElement.addEventListener("click",(e)=>{
            if(this.element.classList.contains("open")){
                e.stopPropagation();
                e.preventDefault();
                this.element.classList.remove("open");
            }
        })
        contentElement.addEventListener("touchstart",(e)=>{
            if(this.element.classList.contains("open")){
                this.element.classList.remove("open");
            }
        })
    }

    _renderSidebar(){

        switch (this.type) {
            case Sidebar.TYPE.NORMAL:
                render(TemplateSidebar(this),this.element,{eventContext:this});
                break;
            case Sidebar.TYPE.GAME:
                render(TemplateGameSidebar(this),this.element,{eventContext:this});
                break;
            default:
                render(TemplateSidebar(this),this.element,{eventContext:this});
        }


        if(this.element.classList.contains("open")){
            this.animateInExpanded();
        }else{
            this.animateInCollapsed();
        }


        $('.toggle-deposit-amount').on('click', function(e){
            e.stopImmediatePropagation();
            if($('.current-balance-display').is(":visible")){
                $('.current-balance-display').hide()
            }else{
                $('.current-balance-display').show()
            }
        })

        var controller = this;
        $(document).on('keydown', function(e) {
            if (e.key == "Escape") {
                SIDE_VIEW_MANAGER.closeAnySideview();
                controller.collapse()
            }
        });
    }

    _updateSidebar(){
        switch (this.type) {
            case Sidebar.TYPE.NORMAL:
                render(TemplateSidebar(this),this.element,{eventContext:this});
                break;
            case Sidebar.TYPE.GAME:
                render(TemplateGameSidebar(this),this.element,{eventContext:this});
                break;
            default:
                render(TemplateSidebar(this),this.element,{eventContext:this});
        }
    }

    onSidebarItemClick(e){
        let action=e.currentTarget.dataset.action;
        switch (action) {
            case "home":
                window.location.href=BASE_URL+'/'+LANGUAGE+'/';
                this.emit("close");
                break;
            case "expand":
                console.log("Expand button click");
                this.expand();
                break;
            case "collapse":
                console.log("Collapse button click");
                this.collapse();
                break;
            case "login":
                new SideViewLogin();
                break;
            case "user":
                new SideViewUser();
                break;
            case "bank":
                new SideViewBank();
                break;
            case "notifications":
                new SideViewNotifications();
                break;
            case "signUp":
                new SideViewRegister();
                break;
            case "menu":
                new SideViewMainMenu();
                break;
            case "openGameInfoSlideOver":
                new SideViewGameInfo(this.gameInfo);
                break;
            case "logout":
                USER.logout();
                break;
            case "promotions":
                try {
                    window.location.href=document.querySelector("input[name='promotions-link']").value;
                }catch(e){
                    console.log("No promotions link found on page")
                }
                break;
            case "vip":
                try {
                    window.location.href=document.querySelector("input[name='vip-program-link']").value;
                }catch(e){
                    console.log("No promotions link found on page")
                }
                break;
            case "help":
                window.location.href = window.helpLink;
                break;
            case "search":
                this.collapse();
                if(Sidebar.searchView==null){
                    Sidebar.searchView=new SearchView(()=>{
                        console.log('search view close callback');
                        Sidebar.searchView=null;
                    });
                }

                break;
            case "link":
                window.location.href=e.currentTarget.dataset.url;
                break;
            case "toggleFavouriteGame":
                this.gameInfo.toggleFavouriteFlag();
                this._updateSidebar();
                break;
            default:
                console.log(`Action not defined: ${action}`)

        }
    }

    expand(){
        this.element.classList.add("open");
        this.animateInExpanded();
    }

    collapse(){
        if(this.collapseOverrideFunction){
            this.collapseOverrideFunction();
        }else{
            this.element.classList.remove("open");
            this.animateInCollapsed();
        }

    }

    animateInExpanded(onlyEnabled) {
        //var skipString = sidebar.classList.contains("logged-in") ? "logged-out" : "logged-in";
        var skipString = "something-temporary";

        let items = this.element.querySelectorAll(".expanded-items li, .expanded-items a");
        for (var i = 0; i < items.length; i++) {

            if (items[i].dataset.enable == skipString) {
                continue;
            }
            if (onlyEnabled && items[i].dataset.enable == null) {
                continue;
            }

            items[i].style.opacity = 0;

            let icon;
            let span;
            if(items[i].classList.contains("heading-item") || items[i].classList.contains("product-nav") || items[i].classList.contains("sidebar-dashboard")){
                icon=span=null;
            }else{
                icon= items[i].querySelector("img");
                span= items[i].querySelector("span");
            }

            items[i].style.animation = null;
            let item = items[i];
            let delay = i * .03;
            setTimeout(function () {
                item.style.animation = "fadeIn .5s ease-out forwards";
                item.style.animationDelay = `${delay}s`;
                if(icon && span){
                    icon.style.animation = "scaleIn .3s ease-out forwards";
                    icon.style.animationDelay = `${delay}s`;
                    span.style.animation = "slideIn .3s ease-out forwards";
                    span.style.animationDelay = `${delay}s`;
                }

            });

            if(items[i].classList.contains("sidebar-dashboard")){
                this.animateInDashboard(item,delay);
            }

        }
    }

    animateInDashboard(dashboard,delay) {
        var items= dashboard.querySelectorAll(".item");
        for(var i=0; i<items.length; i++){
            let item = items[i];
            let itemDelay = i * .02;
            item.style.animation = null;
            item.style.transform = "translateY(.5em)";
            setTimeout(function () {
                item.style.animation = "slideUp .3s ease-out forwards";
                item.style.animationDelay = `${delay+itemDelay}s`;
            });
        }
    }

    animateInCollapsed(onlyEnabled) {
        console.log("animate collapsed");
        //var skipString = sidebar.classList.contains("logged-in") ? "logged-out" : "logged-in";
        var skipString = "something-temporary";

        let items = this.element.querySelectorAll(".collapsed-items li, .collapsed-items a");
        for (var i = 0; i < items.length; i++) {

            if (items[i].dataset.enable == skipString) {
                continue;
            }

            if(onlyEnabled && items[i].dataset.enable==null){
                continue;
            }

            items[i].style.opacity = 0;
            let icon= items[i].querySelector("img");

            items[i].style.animation = null;
            let item = items[i];
            let delay = i * .02;
            setTimeout(function () {
                item.style.animation = "fadeIn .3s ease-out forwards";
                item.style.animationDelay = `${delay}s`;

                if(icon){
                    icon.style.animation = "scaleIn .3s ease-out forwards";
                    icon.style.animationDelay = `${delay}s`;
                }

            })

        }
    }
}

Sidebar.MODE={
    LOGGED_IN:"logged-in",
    LOGGED_OUT:"logged-out"
}

Sidebar.TYPE={
    NORMAL:"normal",
    GAME:"game"
}

Sidebar.searchView=null;

import {html} from 'lit-html';
import {unsafeHTML} from "lit-html/directives/unsafe-html";
import {SlideOverViewStatus} from "../../js/ui/SlideOverViewStatus";

export const TemplateStatusView=(el,type,title,content,buttons)=>{
    return html`
    <div class="status-view">
        <div class="content">
            <ol class="status-message-container">
                <li>
                    ${type==SlideOverViewStatus.STATUS_VIEW_TYPE.SUCCESS ? 
                        html`<img src="${CDN_ASSETS_URL}/circle.svg">`:
                        html`<img src="${CDN_ASSETS_URL}/warning.svg">`
                    }
                    
                </li>
                <li class="status-title">
                    <span>${title}</span>
                </li>
                <li class="status-desc">
                    ${unsafeHTML(content)}
                </li>
                <li>
                    ${buttons.map((item, index)=>{
                        return html`<button class="primary-button" data-button-index="${index}" @click=${el.onButtonClick}>${item}</button>`
                    })}
                </li>
            </ol>
        </div>
    </div>
    `;
}


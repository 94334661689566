import {html} from 'lit-html';
import {APP_STRINGS} from "../../../../js/core/AppStrings";

export const TemplateRGSelfExclude = (el) => html`
<div class="view-with-heading">
<div class="view-heading">
        <div class="item-left">
        <img class="back-img" src="${CDN_ASSETS_URL}/left.svg" @click=${el.onSlideOverBackButtonClick}>
        <span class="title">${APP_STRINGS.getWPMLString('SideViewUser-Limits-SelfExclude-Title')}</span>
        </div>
    </div>
    <div class="scrolling-content">
        <div class="content">
        <form class="limits-form ${el.updateLimitLoading ? 'disabled' : ''}" data-limit-type="${el.selectedLimitTypeID}" @submit=${el.onSubmitLimitForm}>
            <p class="Paragraph-Small">${APP_STRINGS.getWPMLString('SideViewUser-Limits-SelfExclude-DescriptionText')}</p>
            <h4>${APP_STRINGS.getWPMLString('SideViewUser-Limits-SelfExclude-Form-Title')}</h4>
            <div class="form-element-2">
                <div class="mdc-select mdc-exclusion-day">
                    <div class="mdc-select__anchor">
                        <i class="mdc-select__dropdown-icon"></i>

                        <div id="demo-selected-text" class="mdc-select__selected-text" role="button" aria-haspopup="listbox"
                            aria-labelledby="demo-label demo-selected-text"></div>
                        <span id="demo-label"
                            class="mdc-floating-label mdc-floating-label--float-above">${APP_STRINGS.getWPMLString('SideViewUser-Limits-SelfExclude-Form-InputPlaceholder-Label')}</span>

                        <div class="mdc-line-ripple"></div>
                    </div>

                    <div class="mdc-select__menu mdc-menu mdc-menu-surface" role="listbox">
                        <ul class="mdc-list">
                        <li class="mdc-list-item mdc-list-item--selected" aria-selected="true" data-value=""
                            role="option"></li>
                            ${getExclusionDay()}
                        </ul>
                    </div>
                </div>
            </div>
            <div class="form-element-2 confirm-self-exclusion-element">
                <div class="mdc-form-field">
                <div class="mdc-checkbox mdc-lock-checkbox">
                    <input type="checkbox"
                        class="mdc-checkbox__native-control"
                        id="checkbox-1"/>

                    <div class="mdc-checkbox__background">
                    <img class="mdc-checkbox__checkmark" src="${CDN_ASSETS_URL}/single.svg">
                    <!--<svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                        <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                    </svg>-->
                    <div class="mdc-checkbox__mixedmark"></div>
                    </div>
                    <div class="mdc-checkbox__ripple"></div>
                </div>
                <label for="checkbox-1">${APP_STRINGS.getWPMLString('SideViewUser-Limits-SelfExclude-Form-ConfirmationInput-Text')}</label>
                </div>
            </div>
            <div class="form-element-2">
                <button class="primary-button ${el.updateLimitLoading ? 'loading' : ''}">
                    <span>${APP_STRINGS.getWPMLString('SideViewUser-Limits-SelfExclude-Form-ButtonLabel-Submit')}</span><preloader-element></preloader-element>
                </button>
            </div>
        </form>
    </div>
    </div>
    
</div>
    
`

function getExclusionDay(){
    const displayList = [];
    for (var i = 1; i < 42; i++) {
        displayList.push(html`
            <li class="mdc-list-item" data-value="${i}" role="option">
                ${i} days
            </li>
        `)
    }
    return displayList
}
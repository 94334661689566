import {Helpers} from "../util/Helpers";
import {USER} from "./User";
import {Popup} from "@/js/ui/Popup";
import {AccountActivationProcessorPopup} from "@/js/ui/AccountActivationProcessorPopup";

export const InitActivationController=()=>{
    var url = window.location.pathname;
    
    var param = url.split("/");

    if(param[2] == 'activate-account'){
        if(param[3] && param[4]){
            var activateCode = param[3];
            var ea = param[4];
            history.replaceState({}, "", window.location.origin);
            new AccountActivationProcessorPopup(activateCode,ea);
        }
    }

    //will need to remove soon 
    var url = window.location.href
    if(url.indexOf("?activateCode=")>=0){
        var activateCode = Helpers.getUrlParameter('activateCode', url);
        var ea = Helpers.getUrlParameter('ea', url);
        history.replaceState({}, "", window.location.origin);
        new AccountActivationProcessorPopup(activateCode,ea);
    }
}
import {html} from 'lit-html';
import {APP_STRINGS} from "../../../../js/core/AppStrings";

export const TemplateResponsibleGambling = (el) => html`
<div id="responsible-gambling">
    <div class="content">
        <preloader-element class="primary" style="width: 100%; height: 5em; display: ${el.loadingRgLimitData ? 'flex' : 'none'};" ></preloader-element>
        <div class="responsible-gambling-container" style="display: ${el.loadingRgLimitData ? 'none' : 'grid'  };">
            <div class="rg-item deposit-limits" @click=${el.onRGItemClick} data-action="deposit-limit">
                <div class="item-heading">
                    <img src="${CDN_ASSETS_URL}/deposit-limit.svg">
                    <span>${APP_STRINGS.getWPMLString('SideViewUser-Limits-ItemsList-DepositLimits-Title')}</span>
                </div>
                <div class="item-info">
                    ${displayDepositLimitData(el)}
                </div>
            </div>
            <div class="rg-item loss-limits" @click=${el.onRGItemClick} data-action="loss-limit">
                <div class="item-heading">
                    <img src="${CDN_ASSETS_URL}/loss-limit.svg">
                    <span>${APP_STRINGS.getWPMLString('SideViewUser-Limits-ItemsList-LossLimits-Title')}</span>
                </div>
                <div class="item-info">
                    ${displayGameLimitData(el, 'loss')}
                </div>
            </div>
            <div class="rg-item wager-limits" @click=${el.onRGItemClick} data-action="wager-limit">
                <div class="item-heading">
                    <img src="${CDN_ASSETS_URL}/wager-limit.svg">
                    <span>${APP_STRINGS.getWPMLString('SideViewUser-Limits-ItemsList-WagerLimits-Title')}</span>
                </div>
                <div class="item-info">
                    ${displayGameLimitData(el, 'bet')}
                </div>
            </div>
            <div class="rg-item session-limits" @click=${el.onRGItemClick} data-action="session-limit">
                <div class="item-heading">
                    <img src="${CDN_ASSETS_URL}/session-limit.svg">
                    <span>${APP_STRINGS.getWPMLString('SideViewUser-Limits-ItemsList-SessionLimits-Title')}</span>
                </div>
                <p>${displayGameLimitData(el,'time')}</p>
            </div>
            <div class="rg-item self-exclusion" @click=${el.onRGItemClick} data-action="self-exclude">
                <div class="item-heading">
                    <img src="${CDN_ASSETS_URL}/self-exclude.svg">
                    <span>${APP_STRINGS.getWPMLString('SideViewUser-Limits-ItemsList-SelfExclude-Title')}</span>
                </div>
            </div>
        </div>
    </div>
</div>

`

function displayDepositLimitData(el){
    if(typeof el.RgLimitData != 'undefined'){
        console.log(el.depositLimitList)
        if(Object.keys(el.depositLimitList).length > 0){
            var depositLimitList = el.depositLimitList
            const displayList = [];
            for (const [key, value] of Object.entries(depositLimitList)) {
                displayList.push(
                    html`
                        <div class="option">
                            <div class="desc">
                                <span class="Paragraph-Small">${value.intervalType}</span>
                                <!-- <span class="Paragraph-Small">${value.currencySymbol}30 of ${value.currencySymbol}${value.amount}</span> -->
                                <span class="Paragraph-Small">${value.currencySymbol}${value.amount}</span>
                            </div>
                            <!-- <div class="ui-progress-bar"></div> -->
                        </div>
                    `
                )
            }
            return displayList
        }
    }
}

function displayGameLimitData(el, type){
    var gameType = null
    if(type == 'loss'){
        gameType = 'LOSS_AMOUNT_LIMIT'
    }else if(type == 'bet'){
        gameType = 'BET_AMOUNT_LIMIT'
    }else if(type == 'time'){
        gameType = 'TIME_LIMIT'
    }
    if(typeof el.gameLimitList != 'undefined'){
        console.log(el.gameLimitList)
        if(Object.keys(el.gameLimitList).length > 0){
            if(typeof el.gameLimitList[gameType] == 'undefined'){
                el.gameLimitList[gameType] = {};
            }
            if(Object.keys(el.gameLimitList[gameType]).length > 0){
                var gameLimitList = el.gameLimitList[gameType]
                const displayList = [];
                for (const [key, value] of Object.entries(gameLimitList)) {
                    if(Object.keys(value).length > 0){
                        if(type == 'time'){
                            return value.amount + ' min'
                        }
                        displayList.push(
                            html`
                                <div class="option">
                                    <div class="desc">
                                        <span class="Paragraph-Small">${value.intervalType}</span>
                                        <!-- <span class="Paragraph-Small">${value.currencySymbol}30 of ${value.currencySymbol}${value.amount}</span> -->
                                        <span class="Paragraph-Small">${value.currencySymbol}${value.amount}</span>
                                    </div>
                                    <!-- <div class="ui-progress-bar"></div> -->
                                </div>
                            `
                        )
                    }
                }
                return displayList
            }
        }
    }
}